import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs"
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  BarChart, Bar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
  Radar, ResponsiveContainer
} from 'recharts';

// 定義詳細的介面
interface StudentScore {
  studentId: string;
  studentName: string;
  className: string;
  score: number;
}

interface ClassStats {
  className: string;
  highestScore: StudentScore;
  lowestScore: StudentScore;
  averageScore: number;
  submissions: StudentScore[];
  scoreDistribution: {
    range: string;
    count: number;
  }[];
  radarData: {
    criteria: string;
    score: number;
  }[];
  historicalRadarData: {
    username: string;
    data: {
      criteria: string;
      score: number;
    }[];
  }[];

  vocabulary: {
    basic: {
      word: string;
      count: number;
      translation?: string;
    }[];
    advanced: {
      word: string;
      count: number;
      translation?: string;
    }[];
    repeated: {
      word: string;
      count: number;
      type: 'basic' | 'advanced';
    }[];
    stats: {
      totalWords: number;
      basicCount: number;
      advancedCount: number;
      repeatedCount: number;
    };
  };
}

interface OverallStats {
    highestScore: {
      score: number;
      student: string;
      class: string;  // 更改這裡，使用 class 而不是 className
    };
    lowestScore: {
      score: number;
      student: string;
      class: string;  // 更改這裡，使用 class 而不是 className
    };
    averageScore: number;
    totalSubmissions: number;
  }
  
  interface ThemeAnalysisData {
    overallStats: OverallStats;
    classesStats: {
      [className: string]: ClassStats;
    };
    vocabularyRanking: {
      className: string;
      totalWords: number;
      uniqueWords: number;
      advancedWords: number;
    }[];
  }

interface ThemeAnalysisProps {
  topics: string[];
}

const ThemeAnalysis: React.FC<ThemeAnalysisProps> = ({ topics }) => {
  const chartColors = [
    '#2563eb', // blue-600
    '#dc2626', // red-600
    '#16a34a', // green-600
    '#9333ea', // purple-600
    '#ea580c', // orange-600
    '#0891b2', // cyan-600
    '#4f46e5', // indigo-600
    '#c026d3'  // fuchsia-600
  ];

  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const [selectedClass, setSelectedClass] = useState<string>('all');
  const [analysisData, setAnalysisData] = useState<ThemeAnalysisData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (selectedTopic) {
      // console.log('Selected topic changed:', selectedTopic);
      fetchAnalysisData();
    }
  }, [selectedTopic]);

  const fetchAnalysisData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // console.log('Fetching analysis data for topic:', selectedTopic);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('/api/analytics/theme-analysis', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          topic: selectedTopic,
        })
      });

      // console.log('Response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '獲取主題分析失敗');
      }

      const { data } = await response.json();
      setAnalysisData(data);
    } catch (error) {
      console.error('Theme analysis error:', error);
      setError(error instanceof Error ? error.message : '獲取主題分析失敗');
    } finally {
      setLoading(false);
    }
  };

  const getChartColor = (index: number) => chartColors[index % chartColors.length];

  if (loading) return <div>載入中...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="space-y-6">
      {/* 主題選擇 */}
      <Card>
        <CardContent className="pt-6">
          <Select value={selectedTopic} onValueChange={setSelectedTopic}>
            <SelectTrigger>
              <SelectValue placeholder="選擇主題" />
            </SelectTrigger>
            <SelectContent>
              {topics.map((topic) => (
                <SelectItem key={topic} value={topic}>{topic}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </CardContent>
      </Card>

      {analysisData && (
        <>
          {/* 整體統計 */}
          <Card>
            <CardHeader>
              <CardTitle>整體統計</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div>
                  <h4 className="font-semibold">最高分</h4>
                  <p>{analysisData.overallStats.highestScore.score} 分</p>
                  <p className="text-sm text-gray-500">
                  {analysisData.overallStats.highestScore.student}
                  {analysisData.overallStats.highestScore.class && 
                    `(${analysisData.overallStats.highestScore.class})`}
                </p>
                </div>
                <div>
                  <h4 className="font-semibold">最低分</h4>
                  <p>{analysisData.overallStats.lowestScore.score} 分</p>
                  <p className="text-sm text-gray-500">
                  {analysisData.overallStats.lowestScore.student}
                  {analysisData.overallStats.lowestScore.class && 
                    `(${analysisData.overallStats.lowestScore.class})`}
                </p>
                </div>
                <div>
                  <h4 className="font-semibold">平均分</h4>
                  <p>{analysisData.overallStats.averageScore.toFixed(1)} 分</p>
                </div>
                <div>
                  <h4 className="font-semibold">提交總數</h4>
                  <p>{analysisData.overallStats.totalSubmissions} 份</p>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* 班級分析 */}
          <Tabs defaultValue="all">
            <TabsList>
              <TabsTrigger value="all">全部班級</TabsTrigger>
              {Object.keys(analysisData.classesStats).map(className => (
                <TabsTrigger key={className} value={className}>
                  {className}
                </TabsTrigger>
              ))}
            </TabsList>

            <TabsContent value="all">
              {/* 全班級比較視圖 */}
              <div className="space-y-6">
                {/* 分數分布直方圖 */}
                <Card>
                  <CardHeader>
                    <CardTitle>分數分布</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart 
                        data={Object.values(analysisData.classesStats)[0].scoreDistribution.map(item => {
                          const distribution: any = { range: item.range };
                          Object.entries(analysisData.classesStats).forEach(([className, stats]) => {
                            const matchingItem = stats.scoreDistribution.find(d => d.range === item.range);
                            distribution[className] = matchingItem?.count || 0;
                          });
                          return distribution;
                        })}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="range" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {Object.keys(analysisData.classesStats).map((className, index) => (
                          <Bar
                            key={className}
                            dataKey={className}
                            name={className}
                            fill={getChartColor(index)}
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>

                {/* 雷達圖 */}
                <Card>
                  <CardHeader>
                    <CardTitle>各班級能力分析比較</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ResponsiveContainer width="100%" height={400}>
                      <RadarChart data={Object.values(analysisData.classesStats)[0]?.radarData || []}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="criteria" />
                        <PolarRadiusAxis angle={30} domain={[0, 10]} />
                        {Object.entries(analysisData.classesStats).map(([className, classStats], index) => (
                          <Radar
                            key={className}
                            name={className}
                            dataKey="score"
                            fill={getChartColor(index)}
                            fillOpacity={0.6}
                          />
                        ))}
                        <Legend />
                        <Tooltip />
                      </RadarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>

                {/* 詞彙量排行榜 */}
                <Card>
                  <CardHeader>
                    <CardTitle>詞彙量排行榜</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      {analysisData.vocabularyRanking.map((ranking, index) => (
                        <div key={ranking.className} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                          <div className="flex items-center space-x-4">
                            <span className="text-2xl font-bold">{index + 1}</span>
                            <div>
                              <h4 className="font-semibold">{ranking.className}</h4>
                              <p className="text-sm text-gray-500">
                                總詞彙: {ranking.totalWords} | 
                                獨特詞彙: {ranking.uniqueWords} |
                                進階詞彙: {ranking.advancedWords}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </TabsContent>

            {/* 單一班級分析 */}
            {Object.entries(analysisData.classesStats).map(([className, classStats]) => (
              <TabsContent key={className} value={className}>
                <div className="space-y-6">
                  {/* 班級統計 */}
                  <Card>
                    <CardHeader>
                      <CardTitle>{className} 統計</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <div>
                          <h4 className="font-semibold">最高分</h4>
                          <p>{classStats.highestScore.score} 分</p>
                          <p className="text-sm text-gray-500">{classStats.highestScore.studentName}</p>
                        </div>
                        <div>
                          <h4 className="font-semibold">最低分</h4>
                          <p>{classStats.lowestScore.score} 分</p>
                          <p className="text-sm text-gray-500">{classStats.lowestScore.studentName}</p>
                        </div>
                        <div>
                          <h4 className="font-semibold">平均分</h4>
                          <p>{classStats.averageScore.toFixed(1)} 分</p>
                        </div>
                        <div>
                          <h4 className="font-semibold">提交人數</h4>
                          <p>{classStats.submissions.length} 人</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  {/* 分數分布 */}
                  <Card>
                    <CardHeader>
                      <CardTitle>分數分布</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={classStats.scoreDistribution}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="range" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="count" name="學生人數" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>

                  {/* 歷史雷達圖比較 */}
                  <Card>
                    <CardHeader>
                        <CardTitle>能力發展趨勢</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <ResponsiveContainer width="100%" height={400}>
                        <RadarChart 
                            data={classStats.radarData.map(item => {
                            const dataPoint: any = { criteria: item.criteria };
                            classStats.historicalRadarData.forEach((historyItem) => {
                                const matchingData = historyItem.data.find(d => d.criteria === item.criteria);
                                dataPoint[historyItem.username] = matchingData?.score || 0;
                            });
                            return dataPoint;
                            })}
                        >
                            <PolarGrid />
                            <PolarAngleAxis dataKey="criteria" />
                            <PolarRadiusAxis angle={30} domain={[0, 10]} />
                            {classStats.historicalRadarData.map((historyItem, index) => (
                            <Radar
                                key={historyItem.username}
                                name={historyItem.username}
                                dataKey={historyItem.username}
                                stroke={getChartColor(index)}
                                fill={getChartColor(index)}
                                fillOpacity={0.6}
                            />
                            ))}
                            <Legend />
                            <Tooltip />
                        </RadarChart>
                        </ResponsiveContainer>
                    </CardContent>
                    </Card>

                  {/* 詞彙分析 */}
                  <Card>
                    <CardHeader>
                      <CardTitle>詞彙分析</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-6">
                        {/* 詞彙統計 */}
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                          <div>
                            <p className="text-sm text-gray-500">總詞彙量</p>
                            <p className="text-xl font-semibold">{classStats.vocabulary.stats.totalWords}</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">基礎詞彙</p>
                            <p className="text-xl font-semibold">{classStats.vocabulary.stats.basicCount}</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">進階詞彙</p>
                            <p className="text-xl font-semibold">{classStats.vocabulary.stats.advancedCount}</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">重複詞彙</p>
                            <p className="text-xl font-semibold">{classStats.vocabulary.stats.repeatedCount}</p>
                          </div>
                        </div>

                        {/* 詞彙列表 */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          {/* 基礎詞彙 */}
                          <div>
                            <h5 className="font-semibold mb-2">基礎詞彙</h5>
                            <div className="space-y-2 max-h-[300px] overflow-y-auto">
                              {classStats.vocabulary.basic.map((word, index) => (
                                <div key={index} className="p-2 bg-blue-50 rounded flex justify-between">
                                  <span>{word.word}</span>
                                  <span className="text-gray-500">{word.count}次</span>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* 進階詞彙 */}
                          <div>
                            <h5 className="font-semibold mb-2">進階詞彙</h5>
                            <div className="space-y-2 max-h-[300px] overflow-y-auto">
                              {classStats.vocabulary.advanced.map((word, index) => (
                                <div key={index} className="p-2 bg-green-50 rounded flex justify-between">
                                  <span>{word.word}</span>
                                  <span className="text-gray-500">{word.count}次</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* 重複詞彙 */}
                        <div>
                          <h5 className="font-semibold mb-2">重複詞彙</h5>
                          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                            {classStats.vocabulary.repeated.map((word, index) => (
                              <div 
                                key={index} 
                                className={`p-2 rounded flex justify-between ${
                                  word.type === 'basic' ? 'bg-blue-50' : 'bg-green-50'
                                }`}
                              >
                                <span>{word.word}</span>
                                <span className="text-gray-500">{word.count}次</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  {/* 學生成績列表 */}
                  <Card>
                    <CardHeader>
                    <CardTitle>學生成績列表</CardTitle>
                    </CardHeader>
                    <CardContent>
                    <div className="space-y-2">
                        {classStats.submissions
                        .sort((a, b) => b.score - a.score)
                        .map((submission, index) => (
                            <div 
                            key={submission.studentId} 
                            className="flex items-center justify-between p-3 bg-gray-50 rounded hover:bg-gray-100 transition-colors duration-200"
                            >
                            <div className="flex items-center space-x-4">
                                <span className="text-lg font-medium text-gray-600 w-8">
                                {index + 1}
                                </span>
                                <div>
                                <span className="font-medium">
                                    {submission.studentName}
                                </span>
                                <span className="text-sm text-gray-500 ml-2">
                                    {submission.studentId}
                                </span>
                                </div>
                            </div>
                            <span className="font-semibold text-blue-600">
                                {submission.score.toFixed(1)} 分
                            </span>
                            </div>
                        ))}
                    </div>
                    </CardContent>
                </Card>
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </>
      )}
    </div>
  );
};

export default ThemeAnalysis; 