import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { Card, CardContent } from '../../ui/card';
import VocabularyTab from './VocabularyTab';
import { 
  translationResults, 
  getDefaultCorrectionData, 
  CorrectionData, 
  TranslationResult,
  TranslateModification 
} from '../../types/correction';
import OCRComparisonTab from './OCRComparisonTab';

interface TranslationResultTabsProps {
  data: translationResults;
}

const TranslationResultTabs: React.FC<TranslationResultTabsProps> = ({ data }) => {
  // console.log('Translation data received:', data);

  if (!Array.isArray(data)) {
    console.error('Expected array in translation data, got:', data);
    return (
      <div className="p-4 text-center text-gray-500">
        無法顯示翻譯結果：數據格式錯誤
      </div>
    );
  }

  // 添加一個輔助函數來處理詞彙數據
  const processVocabulary = (vocab: any) => {
    // 新版 API 格式
    if (vocab?.BetterVocabulary && Array.isArray(vocab.BetterVocabulary)) {
      return [
        ...(vocab.BetterVocabulary || []),
        ...(vocab.ImproveVocabulary || []),
        ...(vocab.IncorrectVocabulary || [])
      ].map(v => ({
        CEFR: v.CEFRLevel || '',
        kk_phonetic: v.KKPhonetic || '',
        part_of_speech: v.PartOfSpeech || '',
        translation: v.WordTranslation || '',
        word: v.Word || ''
      }));
    }

    // 舊版 API 格式
    if (Array.isArray(vocab)) {
      return vocab.map(v => ({
        CEFR: v.CEFR || '',
        kk_phonetic: v.kk_phonetic || '',
        part_of_speech: v.part_of_speech || '',
        translation: v.translation || '',
        word: v.word || ''
      }));
    }

    return [];
  };

  // 修改詞彙數據處理部分
  const vocabularyData: CorrectionData = {
    ...getDefaultCorrectionData(),
    Basic_voc: data.flatMap(item => {
      const vocab = item.result?.basic_voc;
      if (!vocab) return [];
      return processVocabulary(vocab);
    }),
    article_cerf_lang1: data[0]?.result?.lang1 || {
      CEFRLevel: "",
      Conclusion: "",
      ContentTheme: "",
      IntentandExpression: "",
      SyntaxStructure: "",
      VocabularyRange: ""
    },
    Vocabulary_Upgrade_lang1: {
      IncorrectVocabulary: [],
      ImproveVocabulary: [],
      BetterVocabulary: [],
      AdvancedPhrases: []
    }
  };

  const getIntScore = (score: number | string | undefined): number => {
    if (typeof score === 'number') return score;
    if (!score) return 0;
    return parseFloat(score) || 0;
  };

  // 添加一個輔助函數來檢查數組是否有內容
  const hasItems = (arr?: any[]): boolean => {
    return Array.isArray(arr) && arr.length > 0;
  };

  // 添加一個輔助函數來獲取分數
  const getScore = (result: any): { faithfulness: number, expressiveness: number } => {
    // 新版 API 格式
    if (result.faithfulness_score !== undefined && result.expressiveness_score !== undefined) {
      return {
        faithfulness: result.faithfulness_score,
        expressiveness: result.expressiveness_score
      };
    }
    
    // 舊版 API 格式
    return {
      faithfulness: getIntScore(result.output?.Faithfulness?.Score),
      expressiveness: getIntScore(result.output?.Expressiveness?.Score)
    };
  };

  // 修改錯誤信息獲取函數
  const getErrors = (analysis: any) => {
    // 新版 API 格式
    if (analysis?.Errors) {
      // 確保每個錯誤類型都是數組
      return {
        Additions: Array.isArray(analysis.Errors.Additions) ? analysis.Errors.Additions : [],
        Mistranslations: Array.isArray(analysis.Errors.Mistranslations) ? analysis.Errors.Mistranslations : [],
        Omissions: Array.isArray(analysis.Errors.Omissions) ? analysis.Errors.Omissions : []
      };
    }
    
    // 舊版 API 格式：從 Examples 中提取錯誤信息
    const errors = {
      Additions: [] as string[],
      Mistranslations: [] as string[],
      Omissions: [] as string[]
    };

    analysis?.Examples?.forEach((example: any) => {
      if (example.Deduction && example.DeductionReason) {
        const reason = example.DeductionReason.toLowerCase();
        
        // 更精確的錯誤分類
        if (reason.includes('additional') || reason.includes('unnecessary')) {
          errors.Additions.push(example.ExampleText);
        } else if (reason.includes('missing') || reason.includes('omits') || reason.includes('lacks')) {
          errors.Omissions.push(example.ExampleText);
        } else if (reason.includes('incorrect') || reason.includes('wrong') || 
                  reason.includes('inaccurate') || reason.includes('mistranslated')) {
          errors.Mistranslations.push(example.ExampleText);
        }
        // 不將評價性的描述（如 "good"/"better" 等）歸類為錯誤
      }
    });

    return errors;
  };

  // 添加一個輔助函數來獲取整體評價
  const getOverallRating = (result: any) => {
    // 舊版 API 格式
    if (result.output?.OverallEvaluation?.Rating) {
      return {
        rating: result.output.OverallEvaluation.Rating,
        explanation: result.output.OverallEvaluation.Explanation,
        explanTranslation: result.output.OverallEvaluation.ExplanTranslation,
        suggestions: result.output.OverallEvaluation.Suggestions || []
      };
    }

    // 新版 API 格式：根據分數計算評價
    const scores = getScore(result);
    const averageScore = (scores.faithfulness + scores.expressiveness) / 2;
    
    let rating;
    if (averageScore >= 3.5) {
      rating = 'Excellent';
    } else if (averageScore >= 3.0) {
      rating = 'Good';
    } else if (averageScore >= 2.0) {
      rating = 'Fair';
    } else {
      rating = 'Needs Improvement';
    }

    return {
      rating,
      explanation: result.output?.Faithfulness?.Feedback || '',
      explanTranslation: result.output?.Faithfulness?.FeedbackTranslation || '',
      suggestions: []
    };
  };

  // 添加一個輔助函數來處理文本標記
  const markErrors = (text: string, modifications: TranslateModification[]) => {
    let markedText = text;
    // 按照修改前文本長度排序，避免替換時的位置偏移
    const sortedMods = [...modifications].sort((a, b) => 
      b.BeforeModification.length - a.BeforeModification.length
    );
    
    sortedMods.forEach(mod => {
      markedText = markedText.replace(
        mod.BeforeModification,
        `<span class="text-red-500">${mod.BeforeModification}</span>`
      );
    });
    
    return markedText;
  };

  // 生成修改後的文本
  const getCorrectedText = (text: string, modifications: TranslateModification[]) => {
    let correctedText = text;
    modifications.forEach(mod => {
      correctedText = correctedText.replace(
        mod.BeforeModification,
        `<span class="text-green-500">${mod.AfterModification}</span>`
      );
    });
    return correctedText;
  };

  return (
    <div className="space-y-6">
      <Card className="bg-white">
        <CardContent className="p-6">
          <h3 className="text-lg font-semibold mb-4">學測翻譯評分標準</h3>
          <div className="bg-yellow-50 p-4 rounded-lg mb-4">
            <ul className="text-sm text-gray-700 space-y-2">
              <li>1. 本大題總分8分，每小題滿分4分。</li>
              <li>2. 每個錯誤扣0.5分，各部分獨立，扣完為止。</li>
              <li>3. 相同拼字錯誤或文法錯誤，只扣一次。</li>
              <li>4. 句首未大寫或標點符號不妥，各扣0.5分，只扣一次。</li>
            </ul>
          </div>
          
          <p className="text-gray-700 mb-4">翻譯的原則</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium text-blue-700 mb-2">信（Faithfulness）</h4>
                  <ul className="text-sm text-gray-600 space-y-2">
                    <li>• 忠實呈現原文內容和意圖，確保準確無誤。</li>
                    <li>• 適合法律、技術等需高精確度文本。</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-blue-700 mb-2">達（Expressiveness）</h4>
                  <ul className="text-sm text-gray-600 space-y-2">
                    <li>• 譯文通順流暢，符合目標語表達習慣，便於讀者理解。</li>
                    <li>• 常見於日常應用或實用文本。</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-purple-50 p-4 rounded-lg">
              <h4 className="font-medium text-purple-700 mb-2">雅（Elegance）- 進階翻譯方法</h4>
              <ul className="text-sm text-gray-600 space-y-2">
                <li>• 確保譯文具備文學美感和藝術性，重現原作風格與情感。</li>
                <li>• 文學作品翻譯特別注重此項。</li>
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>

      <Tabs defaultValue="result" className="space-y-6">
        <TabsList>
          <TabsTrigger value="result">翻譯結果</TabsTrigger>
          <TabsTrigger value="vocabulary">詞彙分析</TabsTrigger>
          <TabsTrigger value="ocr">OCR 比對</TabsTrigger>
        </TabsList>

        <TabsContent value="result">
          {data.map((result, index) => (
            <Card key={index} className="overflow-hidden">
              <CardContent className="p-6">
                <h3 className="text-lg font-semibold mb-4">翻譯 {index + 1}</h3>
                
                <div className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="font-medium text-gray-900 mb-2">題目</h4>
                        <p className="text-gray-700">{result.originalQuestion}</p>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="font-medium text-gray-900 mb-2">您的翻譯</h4>
                        <div 
                          className="text-gray-700 mb-2"
                          dangerouslySetInnerHTML={{ 
                            __html: result.result.output.TranslateModification ? 
                              markErrors(result.studentAnswer, result.result.output.TranslateModification) : 
                              result.studentAnswer 
                          }}
                        />
                        {result.result.output.TranslateModification && 
                         result.result.output.TranslateModification.length > 0 && (
                          <div className="mt-2 border-t pt-2">
                            <p className="text-sm text-gray-600 mb-1">批改後的翻譯：</p>
                            <div
                              className="text-gray-700"
                              dangerouslySetInnerHTML={{ 
                                __html: getCorrectedText(
                                  result.studentAnswer, 
                                  result.result.output.TranslateModification
                                )
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="font-medium text-gray-900 mb-2">參考答案</h4>
                        <p className="text-gray-700">{result.standardAnswer}</p>
                      </div>
                    </div>
                  </div>

                  {result.result?.output && (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <h4 className="font-medium text-lg">評分詳情</h4>
                        <span className="text-xl font-semibold text-blue-600">
                          {(() => {
                            const scores = getScore(result.result);
                            return ((scores.faithfulness + scores.expressiveness) / 2).toFixed(1);
                          })()}/4
                        </span>
                      </div>

                      {/* 信度分析 */}
                      {result.result.output.Faithfulness && (
                        <div className="bg-blue-50 p-4 rounded-lg">
                          <div className="flex items-center justify-between mb-2">
                            <h5 className="font-medium">信度分析</h5>
                            <span className="text-blue-600 font-semibold">
                              {getScore(result.result).faithfulness}/4
                            </span>
                          </div>
                          
                          {/* 錯誤分析 */}
                          {(() => {
                            const errors = getErrors(result.result.output.Faithfulness);
                            return (
                              <>
                                {hasItems(errors.Additions) && (
                                  <div className="mb-4">
                                    <h6 className="text-sm font-medium text-blue-700 mb-2">錯誤分析：</h6>
                                    <ul className="list-disc list-inside text-sm space-y-1">
                                      <li className="text-red-600">
                                        添加不必要的內容：
                                        <span className="text-gray-600">
                                          {errors.Additions.join(', ')}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {hasItems(errors.Mistranslations) && (
                                  <div className="mb-4">
                                    <h6 className="text-sm font-medium text-blue-700 mb-2">錯誤分析：</h6>
                                    <ul className="list-disc list-inside text-sm space-y-1">
                                      <li className="text-red-600">
                                        翻譯錯誤：
                                        <span className="text-gray-600">
                                          {errors.Mistranslations.join(', ')}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {hasItems(errors.Omissions) && (
                                  <div className="mb-4">
                                    <h6 className="text-sm font-medium text-blue-700 mb-2">錯誤分析：</h6>
                                    <ul className="list-disc list-inside text-sm space-y-1">
                                      <li className="text-red-600">
                                        遺漏內容：
                                        <span className="text-gray-600">
                                          {errors.Omissions.join(', ')}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </>
                            );
                          })()}

                          {/* 扣分說明 */}
                          {result.result.output.Faithfulness.Examples?.map((example, i) => (
                            <div key={i} className="mb-3 p-3 bg-white rounded border border-blue-100">
                              <div className="text-red-500 text-sm mb-2">
                                扣分：{example.Deduction}
                              </div>
                              <div className="text-sm text-gray-600">
                                原因：{example.DeductionReason}
                              </div>
                              <div className="mt-2 text-sm">
                                <div>原文：{example.ExampleText}</div>
                                <div className="text-gray-500">翻譯：{example.Translation}</div>
                              </div>
                            </div>
                          ))}

                          <div className="mt-4">
                            <p className="text-sm text-gray-700">{result.result.output.Faithfulness.Feedback}</p>
                            <p className="text-sm text-gray-600 mt-1">{result.result.output.Faithfulness.FeedbackTranslation}</p>
                          </div>
                        </div>
                      )}

                      {/* 效度分析 */}
                      {result.result.output.Expressiveness && (
                        <div className="bg-green-50 p-4 rounded-lg">
                          <div className="flex items-center justify-between mb-2">
                            <h5 className="font-medium">效度分析</h5>
                            <span className="text-green-600 font-semibold">
                              {getScore(result.result).expressiveness}/4
                            </span>
                          </div>

                          {/* 錯誤分析 */}
                          {hasItems(result.result.output.Expressiveness.Errors?.FluencyIssues) && (
                            <div className="mb-4">
                              <h6 className="text-sm font-medium text-green-700 mb-2">錯誤分析：</h6>
                              <ul className="list-disc list-inside text-sm space-y-1">
                                <li className="text-red-600">
                                  流暢度問題：
                                  <span className="text-gray-600">
                                    {result.result.output.Expressiveness.Errors?.FluencyIssues?.join(', ')}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          )}

                          {hasItems(result.result.output.Expressiveness.Errors?.GrammaticalMistakes) && (
                            <div className="mb-4">
                              <h6 className="text-sm font-medium text-green-700 mb-2">錯誤分析：</h6>
                              <ul className="list-disc list-inside text-sm space-y-1">
                                <li className="text-red-600">
                                  語法錯誤：
                                  <span className="text-gray-600">
                                    {result.result.output.Expressiveness.Errors?.GrammaticalMistakes?.join(', ')}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          )}

                          {/* 扣分說明 */}
                          {result.result.output.Expressiveness.Examples?.map((example, i) => (
                            <div key={i} className="mb-3 p-3 bg-white rounded border border-green-100">
                              <div className="text-red-500 text-sm mb-2">
                                扣分：{example.Deduction}
                              </div>
                              <div className="text-sm text-gray-600">
                                原因：{example.DeductionReason}
                              </div>
                              <div className="mt-2 text-sm">
                                <div>原文 : {example.ExampleText}</div>
                                <div className="text-gray-500">翻譯：{example.Translation}</div>
                              </div>
                            </div>
                          ))}

                          <div className="mt-4">
                            <p className="text-sm text-gray-700">{result.result.output.Expressiveness.Feedback}</p>
                            <p className="text-sm text-gray-600 mt-1">{result.result.output.Expressiveness.FeedbackTranslation}</p>
                          </div>
                        </div>
                      )}

                      {/* 優雅度分析 */}
                      {result.result.output.Elegance && (
                        <div className="bg-purple-50 p-4 rounded-lg">
                          <h5 className="font-medium mb-2">優雅度分析</h5>
                          {result.result.output.Elegance.Examples?.map((example, i) => (
                            <div key={i} className="mb-3 p-3 bg-white rounded border border-purple-100">
                              <div className="text-sm">
                                <div>建議用法：{example.ExampleText}</div>
                                <div className="text-gray-500">中文翻譯：{example.Translation}</div>
                              </div>
                            </div>
                          ))}
                          <div className="mt-4">
                            <p className="text-sm text-gray-700">{result.result.output.Elegance.Feedback}</p>
                            <p className="text-sm text-gray-600 mt-1">{result.result.output.Elegance.FeedbackTranslation}</p>
                          </div>
                        </div>
                      )}

                      {/* 整體評價 */}
                      {(() => {
                        const overallRating = getOverallRating(result.result);
                        return (
                          <div className="bg-yellow-50 p-4 rounded-lg">
                            <div className="flex items-center gap-2 mb-2">
                              <h5 className="font-medium">整體評價</h5>
                              <span className="text-yellow-600 font-semibold">
                                {overallRating.rating}
                              </span>
                            </div>
                            <p className="text-sm text-gray-600 mb-2">
                              {overallRating.explanation}
                              <br />
                              {overallRating.explanTranslation}
                            </p>
                            {overallRating.suggestions.length > 0 && (
                              <div className="mt-2">
                                <p className="font-medium text-sm mb-1">改進建議：</p>
                                <ul className="list-disc list-inside text-sm text-gray-600">
                                  {overallRating.suggestions.map((suggestion: any, i: number) => (
                                    <li key={i}>
                                      {suggestion.Suggestions}
                                      <br />
                                      <span className="ml-4 text-gray-500">{suggestion.SuggestTranslation}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        );
                      })()}

                      {/* 翻譯修改建議 */}
                      {result.result.output.TranslateModification && 
                        result.result.output.TranslateModification.length > 0 && (
                        <div className="bg-orange-50 p-4 rounded-lg mt-4">
                          <h5 className="font-medium mb-2">修改建議</h5>
                          <div className="space-y-2">
                            {result.result.output.TranslateModification.map((mod, i) => (
                              <div key={i} className="bg-white p-3 rounded border border-orange-100">
                                <div className="text-sm">
                                  <span className="font-medium">{mod.TypeOfModification}：</span>
                                  <span className="text-red-500 line-through mx-2">{mod.BeforeModification}</span>
                                  <span className="text-green-500">→ {mod.AfterModification}</span>
                                </div>
                                {mod.ReasonForModificatio && (
                                  <div className="text-sm text-gray-600 mt-1">
                                    原因：{mod.ReasonForModificatio}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* 翻譯評論 */}
                      {result.result.output.TranslateComment && (
                        <div className="bg-gray-50 p-4 rounded-lg mt-4">
                          <h5 className="font-medium mb-2">整體評論</h5>
                          <p className="text-sm text-gray-700">{result.result.output.TranslateComment.English}</p>
                          <p className="text-sm text-gray-600 mt-1">{result.result.output.TranslateComment.Chinese}</p>
                        </div>
                      )}
                    </div>
                  )}

                  {result.result?.lang1 && result.result?.lang2 && (
                    <div className="space-y-4">
                      <div className="bg-blue-50 p-4 rounded-lg">
                        <h4 className="font-medium mb-2">CEFR 評估</h4>
                        <p className="text-sm text-gray-600">等級：{result.result.lang1.CEFRLevel}</p>
                        <p className="text-sm text-gray-600 mt-2">{result.result.lang1.Conclusion}</p>
                        <p className="text-sm text-gray-600 mt-2">{result.result.lang2.Conclusion}</p>
                        <div className="mt-4 space-y-2">
                          <p className="text-sm"><strong>Subject： </strong> {result.result.lang1.ContentTheme}</p>
                          <p className="text-sm"><strong>主題內容： </strong> {result.result.lang2.ContentTheme}</p>
                          <p className="text-sm"><strong>Expression of intent： </strong> {result.result.lang1.IntentandExpression}</p>
                          <p className="text-sm"><strong>表達意圖：</strong> {result.result.lang2.IntentandExpression}</p>
                          <p className="text-sm"><strong>Syntactic structure： </strong> {result.result.lang1.SyntaxStructure}</p>
                          <p className="text-sm"><strong>句法結構：</strong> {result.result.lang2.SyntaxStructure}</p>
                          <p className="text-sm"><strong>Vocabulary range： </strong> {result.result.lang1.VocabularyRange}</p>
                          <p className="text-sm"><strong>詞彙範圍： </strong> {result.result.lang2.VocabularyRange}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          ))}
        </TabsContent>

        <TabsContent value="vocabulary">
          <VocabularyTab data={vocabularyData} />
        </TabsContent>

        <TabsContent value="ocr">
          <OCRComparisonTab translationData={data} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default TranslationResultTabs;