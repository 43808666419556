// src/services/api.ts

import { EssayHistory, CorrectionData } from "../components/types";

const API_BASE_URL = "/api";

async function fetchWithAuth(url: string, options: RequestInit = {}) {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("未登入");
  }

  const response = await fetch(`${API_BASE_URL}${url}`, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`API 請求失敗: ${response.statusText}`);
  }

  return response.json();
}

export const fetchEssayHistories = async () => {
  try {
    const response = await fetch("/api/essay/history", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // console.log("API 回應狀態:", response.status);
    const result = await response.json();
    // console.log("API 回應內容:", result);

    if (!result.success) {
      throw new Error(result.message || "獲取歷史記錄失敗");
    }

    return result.data;
  } catch (error) {
    console.error("獲取歷史記錄錯誤:", error);
    throw error;
  }
};

export async function deleteEssay(id: string): Promise<void> {
  const data = await fetchWithAuth(`/essay/${id}`, { method: "DELETE" });
  if (!data.success) {
    throw new Error(data.message || "刪除失敗");
  }
}

export async function fetchEssayDetail(id: string): Promise<CorrectionData> {
  const data = await fetchWithAuth(`/essay/${id}`);
  if (data.success) {
    return data.essay;
  }
  throw new Error(data.message || "獲取作文數據失敗");
}
