import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, Routes, Route } from 'react-router-dom';
import Header from '../components/Header';

interface PrivateRouteProps {
  children: React.ReactNode;
  allowedRoles?: string[];
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ 
  children, 
  allowedRoles = [], 
  isAuthenticated 
}) => {
  const userRole = localStorage.getItem('userRole');
  
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole || '')) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedUsername = localStorage.getItem('username');
    
    if (token && savedUsername) {
      setIsAuthenticated(true);
      setUsername(savedUsername);
    } else {
      setIsAuthenticated(false);
      setUsername('');
      if (window.location.pathname !== '/') {
        navigate('/', { replace: true });
      }
    }
  }, [navigate]);

  return (
    <div>
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">銷售管理後台</h1>
        {/* 添加銷售管理相關的內容 */}
      </div>
    </div>
  );
}

export default App;