import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../ui/dialog';
import { Alert, AlertDescription } from '../ui/alert';
import { Search } from 'lucide-react';

interface School {
  _id: string;
  name: string;
  logo: {
    data: Buffer;
    contentType: string;
  } | null;
  createdAt: string;
  updatedAt: string;
}

const SchoolManagement: React.FC = () => {
  const [schools, setSchools] = useState<School[]>([]);
  const [registeredSchools, setRegisteredSchools] = useState<string[]>([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newSchoolName, setNewSchoolName] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/schools', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || '獲取學校列表失敗');
      }
      
      const data = await response.json();
    //   console.log('Received schools data:', data);
      
      if (data.success) {
        setSchools(data.schools);
        setRegisteredSchools(data.registeredSchools);
        // console.log('Schools set:', data.schools.length);
        // console.log('Registered schools:', data.registeredSchools);
      } else {
        throw new Error(data.message || '獲取學校列表失敗');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '獲取學校列表失敗');
      console.error('Fetch schools error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSchool = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('/api/schools', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ name: newSchoolName }),
      });

      if (!response.ok) throw new Error('新增學校失敗');

      await fetchSchools();
      setShowAddDialog(false);
      setNewSchoolName('');
    } catch (error) {
      setError(error instanceof Error ? error.message : '新增學校失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoUpload = async (schoolId: string, file: File) => {
    const formData = new FormData();
    formData.append('logo', file);

    try {
      const response = await fetch(`/api/schools/${schoolId}/logo`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || '上傳 Logo 失敗');
      }

      await fetchSchools();
    } catch (error) {
      console.error('Upload logo error:', error);
      setError(error instanceof Error ? error.message : '上傳 Logo 失敗');
    }
  };

  const handleDeleteSchool = async (schoolId: string) => {
    if (!window.confirm('確定要刪除此學校嗎？')) return;

    try {
      const response = await fetch(`/api/schools/${schoolId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) throw new Error('刪除學校失敗');

      await fetchSchools();
    } catch (error) {
      setError(error instanceof Error ? error.message : '刪除學校失敗');
    }
  };

  const filteredSchools = schools.filter(school =>
    school.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>學校管理</CardTitle>
          <Button onClick={() => setShowAddDialog(true)}>
            新增學校
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="flex justify-end mb-4">
          <div className="relative w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
            <Input
              placeholder="搜索學校..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8"
            />
          </div>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>學校名稱</TableHead>
              <TableHead>Logo</TableHead>
              <TableHead>狀態</TableHead>
              <TableHead>操作</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center py-8">
                  <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                  </div>
                </TableCell>
              </TableRow>
            ) : filteredSchools.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center py-8">
                  沒有找到符合條件的學校
                </TableCell>
              </TableRow>
            ) : (
              filteredSchools.map((school) => (
                <TableRow key={school._id}>
                  <TableCell>{school.name}</TableCell>
                  <TableCell>
                    {school.logo ? (
                      <img
                        src={`/api/schools/logo/${encodeURIComponent(school.name)}`}
                        alt={`${school.name} logo`}
                        className="h-8 w-auto"
                        onError={(e) => {
                          console.error('Failed to load school logo');
                          const target = e.target as HTMLImageElement;
                          target.style.display = 'none';
                        }}
                      />
                    ) : (
                      '未設置'
                    )}
                  </TableCell>
                  <TableCell>
                    {registeredSchools.includes(school.name) ? '已有用戶' : '未使用'}
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        id={`logo-${school._id}`}
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) handleLogoUpload(school._id, file);
                        }}
                      />
                      <Button
                        variant="outline"
                        onClick={() => {
                          document.getElementById(`logo-${school._id}`)?.click();
                        }}
                      >
                        {school.logo ? '更新 Logo' : '上傳 Logo'}
                      </Button>
                      <Button
                        variant="destructive"
                        onClick={() => handleDeleteSchool(school._id)}
                        disabled={registeredSchools.includes(school.name)}
                      >
                        刪除
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>新增學校</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleAddSchool}>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="schoolName">學校名稱</Label>
                  <Input
                    id="schoolName"
                    value={newSchoolName}
                    onChange={(e) => setNewSchoolName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <DialogFooter className="mt-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowAddDialog(false)}
                >
                  取消
                </Button>
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? '處理中...' : '確認新增'}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default SchoolManagement; 