import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from '../ui/table';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { useToast } from '../ui/use-toast';
import type { FC } from 'react';

interface Contact {
  _id: string;
  schoolName: string;
  contactName: string;
  phone: string;
  email: string;
  lineId: string;
  preferredTime: string;
  message: string;
  status: 'pending' | 'contacted' | 'completed';
  createdAt: string;
}

const ContactRecords: FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const { toast } = useToast();

  const fetchContacts = async () => {
    try {
      const response = await fetch('/api/contact', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setContacts(data);
      }
    } catch (error) {
      toast({
        title: "獲取記錄失敗",
        variant: "destructive"
      });
    }
  };

  const updateStatus = async (id: string, newStatus: 'pending' | 'contacted' | 'completed') => {
    try {
      const response = await fetch(`/api/contact/${id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ status: newStatus })
      });

      if (response.ok) {
        setContacts(prevContacts => 
          prevContacts.map(contact => 
            contact._id === id 
              ? { ...contact, status: newStatus } 
              : contact
          )
        );
        
        toast({
          title: "狀態更新成功"
        });
      } else {
        throw new Error('更新失敗');
      }
    } catch (error) {
      toast({
        title: "更新失敗",
        variant: "destructive"
      });
    }
  };

  const deleteContact = async (id: string) => {
    try {
      const response = await fetch(`/api/contact/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        setContacts(prevContacts => 
          prevContacts.filter(contact => contact._id !== id)
        );
        
        toast({
          title: "刪除成功"
        });
      } else {
        throw new Error('刪除失敗');
      }
    } catch (error) {
      toast({
        title: "刪除失敗",
        variant: "destructive"
      });
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const getStatusBadge = (status: Contact['status']) => {
    const variants: Record<Contact['status'], "warning" | "info" | "success"> = {
      pending: 'warning',
      contacted: 'info',
      completed: 'success'
    };
    const labels: Record<Contact['status'], string> = {
      pending: '待處理',
      contacted: '已聯繫',
      completed: '已完成'
    };
    return <Badge variant={variants[status]}>{labels[status]}</Badge>;
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">聯繫記錄管理</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>學校名稱</TableHead>
            <TableHead>聯絡人</TableHead>
            <TableHead>聯絡方式</TableHead>
            <TableHead>方便聯繫時間</TableHead>
            <TableHead>聯繫內容</TableHead>
            <TableHead>狀態</TableHead>
            <TableHead>提交時間</TableHead>
            <TableHead>操作</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact._id}>
              <TableCell>{contact.schoolName}</TableCell>
              <TableCell>{contact.contactName}</TableCell>
              <TableCell>
                <div>電話: {contact.phone}</div>
                <div>Email: {contact.email}</div>
                {contact.lineId && <div>Line: {contact.lineId}</div>}
              </TableCell>
              <TableCell>{contact.preferredTime}</TableCell>
              <TableCell className="max-w-xs truncate">{contact.message}</TableCell>
              <TableCell>{getStatusBadge(contact.status)}</TableCell>
              <TableCell>{new Date(contact.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <div className="space-x-2">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => updateStatus(contact._id, 'contacted')}
                    disabled={contact.status !== 'pending'}
                  >
                    標記已聯繫
                  </Button>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => updateStatus(contact._id, 'completed')}
                    disabled={contact.status === 'completed'}
                  >
                    標記完成
                  </Button>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => {
                      if (window.confirm('確定要刪除這條記錄嗎？')) {
                        deleteContact(contact._id);
                      }
                    }}
                  >
                    刪除
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ContactRecords; 