import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Alert, AlertDescription } from '../ui/alert';
import { Loader2, BookOpen, Clock, Award, Calendar } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Checkbox } from '../ui/checkbox';
import { Label } from '../ui/label';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ArcElement,
  DoughnutController
} from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { CombinedSubmissionData, BasicVocabulary, VocabularyItem } from '../types/correction';
import { fetchEssayHistories } from '../../services/api';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend
);

interface VocabularyStats {
  mastered: {
    totalWords: number;
    cefrDistribution: {
      A1: number;
      A2: number;
      B1: number;
      B2: number;
      C1: number;
      C2: number;
    };
    wordsByLevel: {
      [key: string]: BasicVocabulary[];
    };
  };
  toLearn: {
    totalWords: number;
    cefrDistribution: {
      A1: number;
      A2: number;
      B1: number;
      B2: number;
      C1: number;
      C2: number;
    };
    wordsByLevel: {
      [key: string]: VocabularyItem[];
    };
  };
}

interface ScoreData {
  date: string;
  writingScore: number;
  translationScore: number;
  cefrLevel: string;
  translationDetails?: {
    faithfulness: number;
    expressiveness: number;
  };
}

interface AnalyticsData {
  totalEssays: number;
  monthlyEssays: number;
  averageScore: number;
  lastSubmission: {
    title: string;
    createdAt: string;
    score: number;
  };
  monthlyStats: Array<{
    month: string;
    count: number;
  }>;
  scoreHistory: Array<{
    date: string;
    score: number;
  }>;
  vocabularyData: {
    masteredWords: BasicVocabulary[];
    toLearnWords: VocabularyItem[];
  };
}

const AnalyticsView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>('');
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null);
  const [vocabularyStats, setVocabularyStats] = useState<VocabularyStats>({
    mastered: {
      totalWords: 0,
      cefrDistribution: { A1: 0, A2: 0, B1: 0, B2: 0, C1: 0, C2: 0 },
      wordsByLevel: {}
    },
    toLearn: {
      totalWords: 0,
      cefrDistribution: { A1: 0, A2: 0, B1: 0, B2: 0, C1: 0, C2: 0 },
      wordsByLevel: {}
    }
  });

  const [masteredLevelFilters, setMasteredLevelFilters] = useState<{ [key: string]: boolean }>({
    'A1': true, 'A2': true, 'B1': true, 'B2': true, 'C1': true, 'C2': true
  });

  const [toLearnLevelFilters, setToLearnLevelFilters] = useState<{ [key: string]: boolean }>({
    'A1': true, 'A2': true, 'B1': true, 'B2': true, 'C1': true, 'C2': true
  });

  const [essays, setEssays] = useState<CombinedSubmissionData[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // console.log('開始獲取歷史記錄...');
      
      const response = await fetchEssayHistories();
      // console.log('API 回應原始數據:', response);
  
      if (!response) {
        throw new Error('未收到數據');
      }
  
      const formattedData = response.map((record: any) => {
        // console.log('處理單筆記錄:', record);
        return {
          id: record.id,
          title: record.title || '未命名作業',
          createdAt: record.createdAt,
          type: record.type,
          lang: record.lang,
          result: {
            writing: record.result?.writing || null,
            translation: record.result?.translation || null
          },
        };
      });
  
      // console.log('最終格式化數據:', formattedData);
      setEssays(formattedData);
      
      const processedData = processData(formattedData);
      setAnalyticsData(processedData);
      processVocabularyStats(processedData.vocabularyData);
      setError(null);
    } catch (err) {
      console.error('載入歷史記錄失敗:', err);
      setError(err instanceof Error ? err.message : '獲取資料失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 添加排序函數
  const sortVocabularyByWord = (a: BasicVocabulary, b: BasicVocabulary) => {
    return a.word.toLowerCase().localeCompare(b.word.toLowerCase());
  };

  const sortVocabularyToLearn = (a: VocabularyItem, b: VocabularyItem) => {
    return a.Word.toLowerCase().localeCompare(b.Word.toLowerCase());
  };

  const calculateScore = (essay: CombinedSubmissionData): number => {
    if (essay.result.writing) {
      return parseFloat(essay.result.writing.result.Suggestion_lang1['Total Score']) / 10;
    }
    if (essay.result.translation && Array.isArray(essay.result.translation)) {
      const scores = essay.result.translation.map(item => {
        const expressiveness = parseFloat(item.result?.output?.Expressiveness?.Score || '0');
        const faithfulness = parseFloat(item.result?.output?.Faithfulness?.Score || '0');
        return (expressiveness + faithfulness) / 2;
      });
      return scores.reduce((a, b) => a + b, 0) / scores.length;
    }
    return 0;
  };

  const processScoreData = (essays: CombinedSubmissionData[]) => {
    const scoreData: ScoreData[] = essays.map(essay => {
      let writingScore = 0;
      let translationScore = 0;
      let cefrLevel = '';
      let translationDetails = {
        faithfulness: 0,
        expressiveness: 0
      };

      // 處理作文分數
      if (essay.result.writing) {
        writingScore = parseFloat(essay.result.writing.result.Suggestion_lang1['Total Score']) / 10;
        cefrLevel = essay.result.writing.result.article_cerf_lang1?.CEFRLevel || '';
      }

      // 處理翻譯分數
      if (essay.result.translation && Array.isArray(essay.result.translation)) {
        const translationScores = essay.result.translation.map(item => {
          const expressiveness = parseFloat(item.result?.output?.Expressiveness?.Score || '0');
          const faithfulness = parseFloat(item.result?.output?.Faithfulness?.Score || '0');
          translationDetails = {
            faithfulness: faithfulness,
            expressiveness: expressiveness
          };
          return (expressiveness + faithfulness) / 2;
        });

        if (translationScores.length > 0) {
          translationScore = translationScores.reduce((a, b) => a + b, 0) / translationScores.length;
        }
      }

      return {
        date: essay.createdAt,
        writingScore,
        translationScore,
        cefrLevel,
        translationDetails
      };
    }).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return scoreData;
  };

  const processData = (essays: CombinedSubmissionData[]) => {
    const totalEssays = essays.length;
    const currentMonth = new Date().getMonth();
    const monthlyEssays = essays.filter(essay => {
      const essayMonth = new Date(essay.createdAt).getMonth();
      return essayMonth === currentMonth;
    }).length;

    // 計算平均分數
    const scoreCounts = essays.reduce(
      (acc, essay) => {
        const score = calculateScore(essay);
        if (score > 0) {
          acc.totalScore += score;
          acc.count += 1;
        }
        return acc;
      },
      { totalScore: 0, count: 0 }
    );

    const averageScore = scoreCounts.count > 0 ? scoreCounts.totalScore / scoreCounts.count : 0;

    // 最後提交的作文/翻譯
    const lastEssay = essays[essays.length - 1];
    const lastSubmission = lastEssay ? {
      title: lastEssay.title,
      createdAt: lastEssay.createdAt,
      score: calculateScore(lastEssay)
    } : null;

    // 統計數據
    const monthlyStats = processMonthlyStats(essays);
    const scoreHistory = essays
      .map(essay => ({
        date: essay.createdAt,
        score: calculateScore(essay)
      }))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    // 詞彙數據
    const vocabularyData = processVocabularyData(essays);

    return {
      totalEssays,
      monthlyEssays,
      averageScore,
      lastSubmission: lastSubmission!,
      monthlyStats,
      scoreHistory,
      vocabularyData
    };
  };

  const processMonthlyStats = (essays: CombinedSubmissionData[]) => {
    const monthlyData = essays.reduce((acc: { [key: string]: number }, essay) => {
      const monthYear = new Date(essay.createdAt).toISOString().slice(0, 7);
      acc[monthYear] = (acc[monthYear] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(monthlyData)
      .map(([month, count]) => ({ month, count }))
      .sort((a, b) => a.month.localeCompare(b.month));
  };

  const processVocabularyData = (essays: CombinedSubmissionData[]) => {
    const masteredWordsMap = new Map<string, BasicVocabulary>();
    const toLearnWordsMap = new Map<string, VocabularyItem>();
  
    essays.forEach(essay => {
      // 處理寫作詞彙
      if (essay.result.writing) {
        // 處理已掌握的詞彙
        essay.result.writing.result.Basic_voc?.forEach(word => {
          if (!masteredWordsMap.has(word.word)) {
            masteredWordsMap.set(word.word, word);
          }
        });
  
        // 處理需要學習的詞彙
        const upgradeVocab = essay.result.writing.result.Vocabulary_Upgrade_lang1;
        if (upgradeVocab) {
          const allToLearnWords = [
            ...(upgradeVocab.BetterVocabulary || []),
            ...(upgradeVocab.ImproveVocabulary || []),
            ...(upgradeVocab.IncorrectVocabulary || [])
          ];
  
          allToLearnWords.forEach(word => {
            if (!toLearnWordsMap.has(word.Word)) {
              toLearnWordsMap.set(word.Word, word);
            }
          });
        }
      }
  
      // 處理翻譯詞彙
      if (essay.result.translation && Array.isArray(essay.result.translation)) {
        essay.result.translation.forEach(item => {
          const basicVoc = item?.result?.basic_voc;
          if (Array.isArray(basicVoc)) {
            basicVoc.forEach(word => {
              const vocabWord: BasicVocabulary = {
                word: word.word,
                CEFR: word.CEFR,
                kk_phonetic: word.kk_phonetic,
                part_of_speech: word.part_of_speech,
                translation: word.translation
              };

              if (!masteredWordsMap.has(vocabWord.word)) {
                masteredWordsMap.set(vocabWord.word, vocabWord);
              }
            });
          }
        });
      }
    });
  
    return {
      masteredWords: Array.from(masteredWordsMap.values()),
      toLearnWords: Array.from(toLearnWordsMap.values())
    };
  };

  const processVocabularyStats = (vocabData: AnalyticsData['vocabularyData']) => {
    // Process mastered words
    const masteredByLevel: { [key: string]: BasicVocabulary[] } = {
      A1: [], A2: [], B1: [], B2: [], C1: [], C2: []
    };
    const masteredDistribution = { A1: 0, A2: 0, B1: 0, B2: 0, C1: 0, C2: 0 };

    vocabData.masteredWords.forEach(word => {
      const level = word.CEFR;
      if (masteredByLevel[level]) {
        masteredByLevel[level].push(word);
        masteredDistribution[level as keyof typeof masteredDistribution]++;
      }
    });

    // 對每個等級內的單詞進行排序
    Object.keys(masteredByLevel).forEach(level => {
      masteredByLevel[level].sort(sortVocabularyByWord);
    });

    // Process words to learn
    const toLearnByLevel: { [key: string]: VocabularyItem[] } = {
      A1: [], A2: [], B1: [], B2: [], C1: [], C2: []
    };
    const toLearnDistribution = { A1: 0, A2: 0, B1: 0, B2: 0, C1: 0, C2: 0 };

    vocabData.toLearnWords.forEach(word => {
      const level = word.CEFRLevel;
      if (toLearnByLevel[level]) {
        toLearnByLevel[level].push(word);
        toLearnDistribution[level as keyof typeof toLearnDistribution]++;
      }
    });

    // 對每個等級內的單詞進行排序
    Object.keys(toLearnByLevel).forEach(level => {
      toLearnByLevel[level].sort(sortVocabularyToLearn);
    });

    setVocabularyStats({
      mastered: {
        totalWords: vocabData.masteredWords.length,
        cefrDistribution: masteredDistribution,
        wordsByLevel: masteredByLevel
      },
      toLearn: {
        totalWords: vocabData.toLearnWords.length,
        cefrDistribution: toLearnDistribution,
        wordsByLevel: toLearnByLevel
      }
    });
  };

  const renderLevelFilters = (
    filters: { [key: string]: boolean },
    setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
  ) => (
    <div className="mb-4 bg-white p-4 rounded-lg shadow">
      <h4 className="text-sm font-medium mb-3">選擇顯示等級</h4>
      <div className="grid grid-cols-3 gap-4">
        {Object.entries(filters).map(([level, checked]) => (
          <div key={level} className="flex items-center">
            <Checkbox
              id={`level-${level}`}
              checked={checked}
              onChange={(e) => 
                setFilters(prev => ({ ...prev, [level]: e.target.checked }))
              }
              className="mr-2"
            />
            <Label
              htmlFor={`level-${level}`}
              className="text-sm cursor-pointer select-none"
            >
              {level}
            </Label>
          </div>
        ))}
      </div>
      <div className="mt-3 flex justify-end space-x-4">
        <button
          onClick={() => 
            setFilters(prev => Object.fromEntries(Object.keys(prev).map(key => [key, true])))
          }
          className="text-xs text-blue-600 hover:text-blue-800 font-medium"
        >
          全選
        </button>
        <span className="text-gray-300">|</span>
        <button
          onClick={() => 
            setFilters(prev => Object.fromEntries(Object.keys(prev).map(key => [key, false])))
          }
          className="text-xs text-blue-600 hover:text-blue-800 font-medium"
        >
          取消全選
        </button>
      </div>
    </div>
  );

  // 渲染分數趨勢標籤頁內容
  const renderScoreTrendContent = () => {
    const scoreData = processScoreData(essays);

    // 組合趨勢圖數據
    const trendChartData = {
      labels: scoreData.map(item => 
        new Date(item.date).toLocaleDateString('zh-TW', { month: 'short', day: 'numeric' })
      ),
      datasets: [
        {
          label: '作文分數',
          data: scoreData.map(item => item.writingScore),
          borderColor: '#2563eb',
          backgroundColor: 'rgba(37, 99, 235, 0.1)',
          tension: 0.4,
          fill: false,
        },
      ]
    };

    const writingTrendChartData = {
      labels: scoreData.map(item => 
        new Date(item.date).toLocaleDateString('zh-TW', { month: 'short', day: 'numeric' })
      ),
      datasets: [
        {
          label: '作文分數',
          data: scoreData.map(item => item.writingScore),
          borderColor: '#2563eb',
          backgroundColor: 'rgba(37, 99, 235, 0.1)',
          tension: 0.4,
          fill: true,
        }
      ]
    };

    // 翻譯趨勢圖數據
    const translationTrendChartData = {
      labels: scoreData.map(item => 
        new Date(item.date).toLocaleDateString('zh-TW', { month: 'short', day: 'numeric' })
      ),
      datasets: [
        {
          label: '翻譯分數',
          data: scoreData.map(item => item.translationScore),
          borderColor: '#16a34a',
          backgroundColor: 'rgba(22, 163, 74, 0.1)',
          tension: 0.4,
          fill: true,
        }
      ]
    };

    // 翻譯分項得分數據
    const translationDetailChartData = {
      labels: scoreData.map(item => 
        new Date(item.date).toLocaleDateString('zh-TW', { month: 'short', day: 'numeric' })
      ),
      datasets: [
        {
          label: '忠實度',
          data: scoreData.map(item => item.translationDetails?.faithfulness || 0),
          borderColor: '#9333ea',
          backgroundColor: 'rgba(147, 51, 234, 0.1)',
          tension: 0.4,
        },
        {
          label: '表達力',
          data: scoreData.map(item => item.translationDetails?.expressiveness || 0),
          borderColor: '#db2777',
          backgroundColor: 'rgba(219, 39, 119, 0.1)',
          tension: 0.4,
        }
      ]
    };

    // CEFR 等級分布數據
    const cefrData = {
      labels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      datasets: [{
        data: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map(level => 
          scoreData.filter(item => item.cefrLevel === level).length
        ),
        backgroundColor: [
          '#4ade80', '#22c55e', '#16a34a',
          '#15803d', '#166534', '#14532d'
        ],
      }]
    };

    // 分數分布數據
    const scoreDistributionData = {
      labels: ['0-5', '6-10', '11-15', '16-20'],
      datasets: [
        {
          label: '作文分數分布',
          data: [
            scoreData.filter(item => item.writingScore <= 5).length,
            scoreData.filter(item => item.writingScore > 5 && item.writingScore <= 10).length,
            scoreData.filter(item => item.writingScore > 10 && item.writingScore <= 15).length,
            scoreData.filter(item => item.writingScore > 15).length,
          ],
          backgroundColor: 'rgba(37, 99, 235, 0.6)',
        },
        {
          label: '翻譯分數分布',
          data: [
            scoreData.filter(item => item.translationScore <= 5).length,
            scoreData.filter(item => item.translationScore > 5 && item.translationScore <= 10).length,
            scoreData.filter(item => item.translationScore > 10 && item.translationScore <= 15).length,
            scoreData.filter(item => item.translationScore > 15).length,
          ],
          backgroundColor: 'rgba(22, 163, 74, 0.6)',
        }
      ]
    };

    return (
      <div className="space-y-6">
         {/* 分數統計摘要 */}
         <Card>
          <CardHeader>
            <CardTitle>數據摘要</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="p-4 bg-blue-50 rounded-lg">
                <h4 className="text-sm font-medium text-blue-900">作文平均分</h4>
                <p className="text-2xl font-bold text-blue-700">
                  {(scoreData.reduce((acc, curr) => acc + curr.writingScore, 0) / 
                    scoreData.filter(item => item.writingScore > 0).length || 0).toFixed(1)}
                </p>
              </div>
              <div className="p-4 bg-green-50 rounded-lg">
                <h4 className="text-sm font-medium text-green-900">翻譯平均分</h4>
                <p className="text-2xl font-bold text-green-700">
                  {(scoreData.reduce((acc, curr) => acc + curr.translationScore, 0) / 
                    scoreData.filter(item => item.translationScore > 0).length || 0).toFixed(1)}
                </p>
              </div>
              <div className="p-4 bg-purple-50 rounded-lg">
                <h4 className="text-sm font-medium text-purple-900">最高分</h4>
                <p className="text-2xl font-bold text-purple-700">
                  {Math.max(
                    ...scoreData.map(item => Math.max(item.writingScore || 0, item.translationScore || 0)),
                    0
                  ).toFixed(1)}
                </p>
              </div>
              <div className="p-4 bg-rose-50 rounded-lg">
                <h4 className="text-sm font-medium text-rose-900">進步幅度</h4>
                <p className="text-2xl font-bold text-rose-700">
                  {((scoreData[scoreData.length - 1]?.writingScore || 0) - 
                    (scoreData[0]?.writingScore || 0)).toFixed(1)}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* 分數分布統計 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card>
            <CardHeader>
              <CardTitle>CEFR 等級分布</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[300px]">
                <Doughnut 
                  data={cefrData} 
                  options={getCefrChartOptions('CEFR 等級分布')}
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>分數分布統計</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[300px]">
                <Bar 
                  data={scoreDistributionData} 
                  options={{
                    ...barChartOptions,
                    scales: {
                      ...barChartOptions.scales,
                      y: {
                        ...barChartOptions.scales?.y,
                        title: {
                          display: true,
                          text: '次數'
                        }
                      }
                    }
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </div>
        {/* 分數趨勢圖 */}
        <Card>
          <CardHeader>
            <CardTitle>作文分數趨勢</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[300px] w-full">
              <Line data={trendChartData} options={lineChartOptions} />
            </div>
          </CardContent>
        </Card>
        {/* 翻譯相關圖表並排顯示 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* 翻譯分數趨勢圖 */}
            <Card>
            <CardHeader>
              <CardTitle>翻譯分數趨勢</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[300px] w-full">
                <Line data={translationTrendChartData} options={lineChartOptions} />
              </div>
            </CardContent>
          </Card>

          {/* 翻譯分項得分趨勢 */}
          <Card>
            <CardHeader>
              <CardTitle>翻譯分項得分趨勢</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[300px] w-full">
                <Line data={translationDetailChartData} options={lineChartOptions} />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  const lineChartData = {
    labels: analyticsData?.scoreHistory.map(item => 
      new Date(item.date).toLocaleDateString('zh-TW', { month: 'short', day: 'numeric' })
    ) || [],
    datasets: [{
      label: '分數',
      data: analyticsData?.scoreHistory.map(item => item.score) || [],
      borderColor: '#2563eb',
      backgroundColor: 'rgba(37, 99, 235, 0.1)',
      tension: 0.4,
      fill: true,
    }]
  };

  const barChartData = {
    labels: analyticsData?.monthlyStats.map(item =>
      new Date(item.month).toLocaleDateString('zh-TW', { year: 'numeric', month: 'short' })
    ) || [],
    datasets: [{
      label: '批改數量',
      data: analyticsData?.monthlyStats.map(item => item.count) || [],
      backgroundColor: '#4f46e5',
      borderRadius: 4,
    }]
  };

  const lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            return `分數: ${context.parsed.y.toFixed(1)}分`;
          }
        }
      }
    },
    scales: {
      y: {
        min: 0,
        max: 20,
        ticks: {
          callback: function(value) {
            return value + '分';
          }
        }
      }
    }
  };

  const barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `批改數量: ${context.parsed.y}篇`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function(tickValue: number | string, index: number, ticks) {
            return `${tickValue}篇`;
          }
        }
      }
    }
  };

  const getCefrChartOptions = (title: string): ChartOptions<'doughnut'> => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 14
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.parsed;
            const total = context.dataset.data.reduce((acc: number, curr: number) => acc + curr, 0);
            const percentage = total > 0 ? ((value / total) * 100).toFixed(1) : '0';
            return `${label}: ${value} words (${percentage}%)`;
          }
        }
      }
    }
    });

    const renderVocabularyContent = () => (
      <div className="space-y-6">
        {/* 已掌握單字統計 */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center justify-between">
              <span>已掌握單字</span>
              <span className="text-sm font-normal text-gray-500">
                總計: {vocabularyStats.mastered.totalWords} 字
                <span className="ml-2 text-xs text-gray-400">
                  (包含寫作及翻譯練習)
                </span>
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent>
            {renderLevelFilters(masteredLevelFilters, setMasteredLevelFilters)}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <div className="bg-white p-4 rounded-lg shadow">
                  <div className="text-center mb-4">
                    <p className="text-2xl font-bold text-green-600">
                      {Object.entries(vocabularyStats.mastered.wordsByLevel)
                        .filter(([level]) => masteredLevelFilters[level])
                        .reduce((acc, [_, words]) => acc + words.length, 0)}
                    </p>
                    <p className="text-sm text-gray-600">已掌握單字數量</p>
                  </div>
                  <div className="h-[300px]">
                    <Doughnut
                      data={{
                        labels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].filter(
                          level => masteredLevelFilters[level]
                        ),
                        datasets: [{
                          data: Object.entries(vocabularyStats.mastered.cefrDistribution)
                            .filter(([level]) => masteredLevelFilters[level])
                            .map(([_, count]) => count),
                          backgroundColor: [
                            '#4ade80', '#22c55e', '#16a34a',
                            '#15803d', '#166534', '#14532d'
                          ]
                        }]
                      }}
                      options={getCefrChartOptions('已掌握單字 CEFR 分布')}
                    />
                  </div>
                </div>
              </div>
  
              <div className="space-y-4 max-h-[500px] overflow-y-auto">
                {Object.entries(vocabularyStats.mastered.wordsByLevel)
                  .filter(([level]) => masteredLevelFilters[level])
                  .map(([level, words]) => (
                    words.length > 0 && (
                      <div key={level} className="bg-white p-4 rounded-lg shadow">
                        <h4 className="font-medium mb-2 flex items-center justify-between">
                          <span>{level} Level</span>
                          <span className="text-sm text-gray-500">
                            ({words.length} words)
                          </span>
                        </h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                          {words.map((word, index) => (
                            <div key={index} className="text-sm p-2 bg-green-50 rounded">
                              <div className="flex items-center justify-between">
                                <span className="font-medium">{word.word}</span>
                                <span className="text-xs text-gray-500">
                                  {word.part_of_speech}
                                </span>
                              </div>
                              <div className="text-gray-500 text-xs">
                                {word.kk_phonetic}
                              </div>
                              <div className="text-gray-600 text-sm mt-1">
                                {word.translation}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  ))}
              </div>
            </div>
          </CardContent>
        </Card>
  
        {/* 需要學習的單字統計 */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center justify-between">
              <span>需背誦單字</span>
              <span className="text-sm font-normal text-gray-500">
                總計: {vocabularyStats.toLearn.totalWords} 字
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent>
            {renderLevelFilters(toLearnLevelFilters, setToLearnLevelFilters)}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <div className="bg-white p-4 rounded-lg shadow">
                  <div className="text-center mb-4">
                    <p className="text-2xl font-bold text-blue-600">
                      {Object.entries(vocabularyStats.toLearn.wordsByLevel)
                        .filter(([level]) => toLearnLevelFilters[level])
                        .reduce((acc, [_, words]) => acc + words.length, 0)}
                    </p>
                    <p className="text-sm text-gray-600">需背誦單字數量</p>
                  </div>
                  <div className="h-[300px]">
                    <Doughnut
                      data={{
                        labels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].filter(
                          level => toLearnLevelFilters[level]
                        ),
                        datasets: [{
                          data: Object.entries(vocabularyStats.toLearn.cefrDistribution)
                            .filter(([level]) => toLearnLevelFilters[level])
                            .map(([_, count]) => count),
                          backgroundColor: [
                            '#60a5fa', '#3b82f6', '#2563eb',
                            '#1d4ed8', '#1e40af', '#1e3a8a'
                          ]
                        }]
                      }}
                      options={getCefrChartOptions('需背誦單字 CEFR 分布')}
                    />
                  </div>
                </div>
              </div>
  
              <div className="space-y-4 max-h-[500px] overflow-y-auto">
                {Object.entries(vocabularyStats.toLearn.wordsByLevel)
                  .filter(([level]) => toLearnLevelFilters[level])
                  .map(([level, words]) => (
                    words.length > 0 && (
                      <div key={level} className="bg-white p-4 rounded-lg shadow">
                        <h4 className="font-medium mb-2 flex items-center justify-between">
                          <span>{level} Level</span>
                          <span className="text-sm text-gray-500">
                            ({words.length} words)
                          </span>
                        </h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                          {words.map((word, index) => (
                            <div key={index} className="text-sm p-2 bg-blue-50 rounded">
                              <div className="flex items-center justify-between">
                                <span className="font-medium">{word.Suggestion}</span>
                                <span className="text-xs text-gray-500">
                                  {word.PartofSpeech}
                                </span>
                              </div>
                              <div className="text-gray-600 text-sm mt-1">
                                {word.SuggestionTranslation}
                              </div>
                              <div className="text-blue-600 text-sm mt-2 pt-2 border-t border-blue-100">
                                原單字：{word.Word}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  ))}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  
    return (
      <div className="space-y-6">
        {/* 統計卡片 */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card>
            <CardContent className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center space-x-2">
                    <BookOpen className="h-4 w-4 text-blue-600" />
                    <h3 className="text-sm font-medium">總批改數</h3>
                  </div>
                  <p className="text-2xl font-bold mt-2">{analyticsData?.totalEssays || 0}</p>
                </div>
                <div className="text-blue-600 opacity-20">
                  <BookOpen className="h-12 w-12" />
                </div>
              </div>
            </CardContent>
          </Card>
  
          <Card>
            <CardContent className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center space-x-2">
                    <Calendar className="h-4 w-4 text-green-600" />
                    <h3 className="text-sm font-medium">本月批改</h3>
                  </div>
                  <p className="text-2xl font-bold mt-2">{analyticsData?.monthlyEssays || 0}</p>
                </div>
                <div className="text-green-600 opacity-20">
                  <Calendar className="h-12 w-12" />
                </div>
              </div>
            </CardContent>
          </Card>
  
          <Card>
            <CardContent className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center space-x-2">
                    <Award className="h-4 w-4 text-yellow-600" />
                    <h3 className="text-sm font-medium">平均分數</h3>
                  </div>
                  <p className="text-2xl font-bold mt-2">
                    {analyticsData?.averageScore.toFixed(1) || '0.0'}
                  </p>
                </div>
                <div className="text-yellow-600 opacity-20">
                  <Award className="h-12 w-12" />
                </div>
              </div>
            </CardContent>
          </Card>
  
          <Card>
            <CardContent className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center space-x-2">
                    <Clock className="h-4 w-4 text-purple-600" />
                    <h3 className="text-sm font-medium">最近批改</h3>
                  </div>
                  {analyticsData?.lastSubmission ? (
                    <div className="mt-2">
                      <p className="text-sm font-medium truncate max-w-[150px]">
                        {analyticsData.lastSubmission.title}
                      </p>
                      <p className="text-xs text-gray-500">
                        {new Date(analyticsData.lastSubmission.createdAt).toLocaleDateString()}
                      </p>
                      <p className="text-xs font-medium text-purple-600">
                        得分：{analyticsData.lastSubmission.score.toFixed(1)}
                      </p>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500 mt-2">暫無批改記錄</p>
                  )}
                </div>
                <div className="text-purple-600 opacity-20">
                  <Clock className="h-12 w-12" />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
  
        {/* 圖表區域 */}
        <Tabs defaultValue="score-trend" className="w-full">
          <TabsList className="grid w-full grid-cols-3 lg:w-[600px]">
            <TabsTrigger value="score-trend">分數趨勢</TabsTrigger>
            <TabsTrigger value="monthly-analysis">月度分析</TabsTrigger>
            <TabsTrigger value="vocabulary">詞彙分析</TabsTrigger>
          </TabsList>
  
          <TabsContent value="score-trend" className="mt-4">
            {renderScoreTrendContent()}
          </TabsContent>
  
          <TabsContent value="monthly-analysis" className="mt-4">
            <Card>
              <CardHeader>
                <CardTitle>月度批改分析</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-[300px] w-full">
                  <Bar data={barChartData} options={barChartOptions} />
                </div>
              </CardContent>
            </Card>
          </TabsContent>
  
          <TabsContent value="vocabulary" className="mt-4">
            {renderVocabularyContent()}
          </TabsContent>
        </Tabs>
      </div>
    );
  };
  
  export default AnalyticsView;