import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Alert, AlertDescription } from '../ui/alert';
import Steps from './Steps';
import SelectTestType from './SelectTestType';
import SelectSourceType from './SelectSourceType';
import SelectExamType from './SelectExamType';
import SelectYear from './SelectYear';
import SelectRound from './SelectRound';
import TranslationForm from './TranslationForm';
import WritingForm from './WritingForm';
import DeadlineForm from './DeadlineForm';
import TestHistory from './TestHistory';
import SelectMockType from './SelectMockType';

interface WritingData {
  title: string;
  description: string;
  imageDescription: string;
  image: File | null;
  imageBase64: string | null;
  existingImage: string | null;
  imageType: string;
  originalData: any;
}

interface TranslationData {
  chinese: string;
  english: string;
}

interface FormData {
  writing: WritingData | null;
  translations: TranslationData[] | null;
}

interface TestCreationState {
  step: string;
  testType: string;
  sourceType: string;
  examType: string;
  mockType?: string;
  year: number | null;
  round: number | null;
  translations: TranslationData[];
  writing: WritingData | null;
  deadline: Date | null;
  targetClass: string;
}

const TestCreationFlow: React.FC = () => {
  const [state, setState] = useState<TestCreationState>({
    step: '選擇類型',
    testType: '',
    sourceType: '',
    examType: '',
    year: null,
    round: null,
    translations: [],
    writing: null,
    deadline: null,
    targetClass: '',
  });

  const [formData, setFormData] = useState<FormData>({
    writing: null,
    translations: null
  });

  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [availableRounds, setAvailableRounds] = useState<number[]>([]);
  const [completedSteps, setCompletedSteps] = useState<Record<string, string>>({});
  const [error, setError] = useState<string>('');

  const getSteps = () => {
    const steps = [
      { title: '選擇類型', description: '選擇出題類型' },
      { title: '選擇來源', description: '選擇題目來源' }
    ];

    if (state.sourceType === 'past-exam') {
      steps.push({ title: '考試類型', description: '選擇考試類型' });
      if (state.examType === 'mock') {
        steps.push({ title: '模考類型', description: '選擇模考區域' });
      }
      steps.push(
        { title: '選擇年份', description: '選擇考試年份' },
        { title: '選擇卷數', description: '選擇考試卷數' }
      );
    }

    if (state.testType === 'translation-writing') {
      steps.push({ title: '翻譯題目', description: '輸入翻譯題目' });
    }

    steps.push(
      { title: '作文題目', description: '輸入作文題目' },
      { title: '截止設定', description: '設定繳交期限' }
    );

    return steps;
  };

  const handleStepComplete = (stepTitle: string, value: string, nextStep: string) => {
    setCompletedSteps(prev => ({
      ...prev,
      [stepTitle]: value
    }));
    setState(prev => ({
      ...prev,
      step: nextStep
    }));
  };

  const clearStepsAfter = (stepTitle: string) => {
    const steps = getSteps();
    const stepIndex = steps.findIndex(s => s.title === stepTitle);
    
    setCompletedSteps(prev => {
      const newSteps = { ...prev };
      steps.forEach((step, index) => {
        if (index > stepIndex) {
          delete newSteps[step.title];
        }
      });
      return newSteps;
    });
  };

  useEffect(() => {
    if (state.examType && state.sourceType === 'past-exam') {
      fetchYears();
    }
  }, [state.examType]);

  useEffect(() => {
    if (state.year && state.sourceType === 'past-exam') {
      fetchRounds();
    }
  }, [state.year]);

  const fetchYears = async () => {
    try {
      let examTypeParam = state.examType;
      
      // 只有在是模考時才特別處理 examType
      if (state.examType === 'mock' && state.mockType) {
        examTypeParam = state.mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考';
      }

      // console.log('Fetching years for exam type:', examTypeParam);
      
      const response = await fetch(`/api/test/years/${encodeURIComponent(examTypeParam)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch years');
      
      const data = await response.json();
      if (data.success) {
        setAvailableYears(data.years);
      }
    } catch (error) {
      console.error('Error fetching years:', error);
      setError('獲取年份失敗');
    }
  };

  const fetchRounds = async () => {
    try {
      let examTypeParam = state.examType;
      
      // 只有在是模考時才特別處理 examType
      if (state.examType === 'mock' && state.mockType) {
        examTypeParam = state.mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考';
      }

      // console.log('Fetching rounds for:', {
      //   examType: examTypeParam,
      //   year: state.year
      // });

      const response = await fetch(
        `/api/test/rounds/${encodeURIComponent(examTypeParam)}/${state.year}`, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      
      if (!response.ok) throw new Error('Failed to fetch rounds');
      
      const data = await response.json();
      if (data.success) {
        setAvailableRounds(data.rounds);
      }
    } catch (error) {
      console.error('Error fetching rounds:', error);
      setError('獲取卷數失敗');
    }
  };

  const renderStep = () => {
    switch (state.step) {
      case '選擇類型':
        return (
          <SelectTestType
            onSelect={(type: string) => {
              if (type === 'history') {
                setState(prev => ({ ...prev, testType: type }));
                return;
              }
              handleStepComplete('選擇類型', 
                type === 'translation-writing' ? '翻譯+寫作' : '純英文寫作',
                '選擇來源'
              );
              setState(prev => ({ ...prev, testType: type }));
            }}
          />
        );

        case '選擇來源':
  return (
    <SelectSourceType
      onSelect={async (source: string) => {
        if (source === 'random') {
          try {
            // console.log('Fetching random question for type:', state.testType);
            const response = await fetch('/api/test/random', {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            });
            
            if (!response.ok) {
              throw new Error('Failed to fetch random question');
            }
            
            const data = await response.json() as { 
              success: boolean; 
              writing: {
                title: string;
                description: string;
                imageDescription?: string;
                image?: {
                  data: string;
                  contentType: string;
                };
              };
              translations: Array<{
                chinese: string;
                english: string;
              }>;
            };

            // console.log('Received random data:', {
            //   hasWriting: !!data.writing,
            //   translationsCount: data.translations?.length,
            //   testType: state.testType
            // });

            if (data.success && data.writing) {
              let imageUrl: string | null = null;
              if (data.writing.image?.data) {
                imageUrl = `data:image/${data.writing.image.contentType};base64,${data.writing.image.data}`;
              }

              const writingData: WritingData = {
                title: data.writing.title || '',
                description: data.writing.description || '',
                imageDescription: data.writing.imageDescription || '',
                image: null,
                imageBase64: null,
                existingImage: imageUrl,
                imageType: data.writing.image?.contentType || '',
                originalData: data.writing
              };

              // 更新 formData，不再做條件檢查
              setFormData({
                writing: writingData,
                translations: data.translations  // 直接設置翻譯資料
              });

              // 更新狀態，根據測驗類型決定下步
              handleStepComplete('選擇來源', '隨機出題',
                state.testType === 'translation-writing' ? '翻譯題目' : '作文題目'
              );
              
              setState(prev => ({ 
                ...prev, 
                sourceType: source,
                step: state.testType === 'translation-writing' ? '翻譯題目' : '作文題目',
                writing: writingData,
                translations: data.translations || []  // 確保有預設值
              }));

              // console.log('State updated:', {
              //   nextStep: state.testType === 'translation-writing' ? '翻譯題目' : '作文題目',
              //   translationsCount: data.translations?.length || 0
              // });
            }
          } catch (error) {
            console.error('Error fetching random question:', error);
            setError('獲取隨機題目失敗');
            return;
          }
        } else {
          const getNextStep = (source: string) => {
            if (source === 'past-exam') {
              return '考試類型';
            }
            return state.testType === 'translation-writing' ? '翻譯題目' : '作文題目';
          };

          handleStepComplete('選擇來源', 
            source === 'past-exam' ? '考古題' : '自行出題',
            getNextStep(source)
          );
          setState(prev => ({ ...prev, sourceType: source }));
        }
      }}
      onBack={() => {
        setState(prev => ({ 
          ...prev, 
          step: '選擇類型',
          sourceType: '' 
        }));
        clearStepsAfter('選擇類型');
      }}
    />
  );
      case '考試類型':
        return (
          <SelectExamType
            onSelect={(examType: string) => {
              if (examType === 'mock') {
                setState(prev => ({
                  ...prev,
                  examType,
                  step: '模考類型'
                }));
                handleStepComplete('考試類型', '模考', '模考類型');
              } else {
                setState(prev => ({
                  ...prev,
                  examType,
                  step: '選擇年份'
                }));
                handleStepComplete('考試類型', 
                  examType === 'gsat' ? '學測' : '指考',
                  '選擇年份'
                );
              }
            }}
            onBack={() => {
              setState(prev => ({ 
                ...prev, 
                step: '選擇來源',
                examType: '' 
              }));
              clearStepsAfter('選擇來源');
            }}
          />
        );

      case '模考類型':
        return (
          <SelectMockType
            onSelect={(mockType: string) => {
              const mockExamType = mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考';
              setState(prev => ({
                ...prev,
                mockType,
                examType: mockExamType,
                step: '選擇年份'
              }));
              handleStepComplete('模考類型', 
                mockType === 'north' ? '北區模考' : '中區模考',
                '選擇年份'
              );
            }}
            onBack={() => {
              setState(prev => ({
                ...prev,
                step: '考試類型',
                mockType: '',
                examType: ''
              }));
              clearStepsAfter('考試類型');
            }}
          />
        );

      case '選擇年份':
        return (
          <SelectYear
            availableYears={availableYears}
            onSelect={(year: number) => {
              handleStepComplete('選擇年份', `${year}年`, '選擇卷數');
              setState(prev => ({ ...prev, year }));
            }}
            onBack={() => {
              setState(prev => ({ 
                ...prev, 
                step: state.examType === 'mock' ? '模考類型' : '考試類型',
                year: null 
              }));
              clearStepsAfter(state.examType === 'mock' ? '模考類型' : '考試類型');
            }}
          />
        );

      case '選擇卷數':
        return (
          <SelectRound
            availableRounds={availableRounds}
            onSelect={(round: number) => {
              handleStepComplete('選擇卷數', `第${round}卷`, 
                state.testType === 'translation-writing' ? '翻譯題目' : '作文題目'
              );
              setState(prev => ({ ...prev, round }));
            }}
            onBack={() => {
              setState(prev => ({ 
                ...prev, 
                step: '選擇年份',
                round: null 
              }));
              clearStepsAfter('選擇年份');
            }}
          />
        );

        case '翻譯題目':
          return (
            <TranslationForm
              examInfo={{
                examType: state.examType,
                year: state.year,
                round: state.round,
                mockType: state.mockType
              }}
              isPastExam={state.sourceType === 'past-exam'}
              savedData={formData.translations}
              onNext={(translations: TranslationData[]) => {
                // console.log('Translation form data:', {
                //   examType: state.examType,
                //   mockType: state.mockType,
                //   year: state.year,
                //   round: state.round,
                //   translationsCount: translations.length
                // });
                handleStepComplete('翻譯題目', `${translations.length}題翻譯`, '作文題目');
                setFormData(prev => ({ ...prev, translations }));
                setState(prev => ({ ...prev, translations }));
              }}
              onBack={() => {
                setState(prev => ({
                  ...prev,
                  step: state.sourceType === 'past-exam' ? '選擇卷數' : '選擇來源',
                  translations: []
                }));
                clearStepsAfter(state.sourceType === 'past-exam' ? '選擇卷數' : '選擇來源');
              }}
            />
          );

      case '作文題目':
        return (
          <WritingForm
            examInfo={{
              examType: state.examType,
              year: state.year,
              round: state.round,
              mockType: state.mockType
            }}
            isPastExam={state.sourceType === 'past-exam'}
            savedData={formData.writing}
            onNext={(writing: WritingData) => {
              // console.log('Writing form data:', {
              //   examType: state.examType,
              //   mockType: state.mockType,
              //   year: state.year,
              //   round: state.round,
              //   writingTitle: writing.title,
              //   hasDescription: !!writing.description
              // });
              handleStepComplete('作文題目', writing.description.substring(0, 20), '截止設定');
              setFormData(prev => ({ ...prev, writing }));
              setState(prev => ({ ...prev, writing }));
            }}
            onBack={() => {
              setState(prev => ({ 
                ...prev, 
                step: state.testType === 'translation-writing' ? '翻譯題目' : '選擇來源',
                writing: null 
              }));
              clearStepsAfter(state.testType === 'translation-writing' ? '翻譯題目' : '選擇來源');
            }}
          />
        );

      case '截止設定':
        return (
          <DeadlineForm
            onSubmit={async (deadline: Date, targetClass: string) => {
              handleStepComplete('截止設定', targetClass, '完成');
              
              if (!formData.writing) {
                setError('作文資料不完整');
                return;
              }

              // Convert image to Base64 before sending
              let imageData = null;
              if ((formData.writing as WritingData).image instanceof File) {
                const reader = new FileReader();
                imageData = await new Promise((resolve) => {
                  reader.onloadend = () => {
                    const base64Data = reader.result as string;
                    resolve(base64Data.split(',')[1]);
                  };
                  reader.readAsDataURL((formData.writing as WritingData).image as File);
                });
              }

              const requestBody = {
                testType: state.testType,
                sourceType: state.sourceType,
                examType: state.sourceType === 'past-exam' 
                  ? (state.mockType 
                    ? (state.mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考')
                    : state.examType)
                  : '',
                mockType: state.mockType,
                year: state.sourceType === 'past-exam' ? state.year : null,
                round: state.sourceType === 'past-exam' ? state.round : null,
                translations: formData.translations || [],
                writing: {
                  ...formData.writing,
                  question: formData.writing.description,
                  title: state.sourceType === 'past-exam' 
                    ? `${state.examType} ${state.year} 年第 ${state.round} 回` 
                    : formData.writing.description.substring(0, 20) || '自訂題目',
                  image: imageData ? {
                    data: imageData,
                    contentType: (formData.writing as WritingData).image?.type.split('/')[1] || 'jpeg'
                  } : null
                },
                deadline: deadline.toISOString(),
                targetClass: targetClass
              };
      
              fetch('/api/test/create', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(requestBody)
              })
              .then(response => response.json())
              .then(data => {
                if (data.success) {
                  setState({
                    step: '選擇類型',
                    testType: '',
                    sourceType: '',
                    examType: '',
                    year: null,
                    round: null,
                    translations: [],
                    writing: null,
                    deadline: null,
                    targetClass: '',
                  });
                  setFormData({
                    writing: null,
                    translations: null
                  });
                  setCompletedSteps({});
                } else {
                  throw new Error(data.message || '出題失敗');
                }
              })
              .catch(error => {
                console.error('Submit error:', error);
                setError(error instanceof Error ? error.message : '出題失敗');
              });
            }}
            onBack={() => {
              setState(prev => ({ 
                ...prev, 
                step: '作文題目',
                deadline: null,
                targetClass: '' 
              }));
              clearStepsAfter('作文題目');
            }}
          />
        );

      default:
        return null;
    }
  };

  if (state.testType === 'history') {
    return <TestHistory />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>開始出題</CardTitle>
      </CardHeader>
      <CardContent>
        <Steps
          steps={getSteps()}
          currentStep={getSteps().findIndex(step => step.title === state.step)}
          completedSteps={completedSteps}
        />
        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {renderStep()}
      </CardContent>
    </Card>
  );
};

export default TestCreationFlow;