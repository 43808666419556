import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent } from '../components/ui/card';
import { Badge } from '../components/ui/badge';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Textarea } from '../components/ui/textarea';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { useToast } from '../components/ui/use-toast';

const LandingPage = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [contactOpen, setContactOpen] = useState(false);
  const [formData, setFormData] = useState({
    schoolName: '',
    contactName: '',
    phone: '',
    email: '',
    lineId: '',
    preferredTime: '',
    message: ''
  });

  const handleLogin = (type: 'student_b' | 'student_c' |  'school' | 'admin') => {
    navigate(`/login/${type}`);
  };

  const validatePhone = (phone: string) => {
    const pattern = /^09\d{8}$/;
    return pattern.test(phone);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validatePhone(formData.phone)) {
      toast({
        title: "電話格式錯誤",
        description: "請輸入正確的手機號碼格式（例如：0912345678）",
        variant: "destructive"
      });
      return;
    }

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        toast({
          title: "提交成功",
          description: "我們會盡快與您聯繫",
        });
        setContactOpen(false);
        setFormData({
          schoolName: '',
          contactName: '',
          phone: '',
          email: '',
          lineId: '',
          preferredTime: '',
          message: ''
        });
      }
    } catch (error) {
      toast({
        title: "提交失敗",
        description: "請稍後再試",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      <header className="sticky top-0 z-50 w-full bg-white border-b shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <img src="/logo112.svg" alt="KC Online Logo" className="h-7 w-auto" />
            </div>
            <div className="flex space-x-4">
              <Button 
                variant="ghost" 
                onClick={() => handleLogin('student_c')}
              >
                個人登入
              </Button>
              <Button 
                variant="outline"
                onClick={() => handleLogin('school')}
              >
                校園端登入
              </Button>
              <Button 
                variant="default"
                onClick={() => handleLogin('admin')}
              >
                管理者登入
              </Button>
              <Button 
                variant="ghost" 
                onClick={() => setContactOpen(true)}
              >
                聯繫我們
              </Button>
            </div>
          </div>
        </div>
      </header>

      <section className="pt-20 pb-32 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-8">
              <div className="space-y-4">
                <Badge variant="info" className="mb-4">全新升級</Badge>
                <h1 className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
                 建立個人語言模型
                  <br />
                  助益英文寫作
                </h1>
                <p className="text-xl text-gray-600">
                  透過觀察.分析.預測
                  <br />
                  達成有信效度的英文寫作
                </p>
              </div>
              <div className="flex gap-4">
                <Button 
                  onClick={() => handleLogin('student_c')}
                  className="text-lg px-8 h-12"
                >
                  開始使用
                </Button>
                <Button 
                  variant="outline"
                  onClick={() => handleLogin('school')}
                  className="text-lg px-8 h-12"
                >
                  學校合作
                </Button>
              </div>
            </div>
            <div className="lg:block hidden">
              <img 
                src="/write.jpeg"
                alt="/write.jpeg"
                className="w-full rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">系統特色</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <Card className="relative overflow-hidden border-t-4 border-t-blue-500">
              <CardContent className="pt-6">
                <div className="absolute top-2 right-2 opacity-10">
                  <div className="text-6xl font-bold text-blue-500">01</div>
                </div>
                <h3 className="text-xl font-semibold mb-4">AI 語言建模</h3>
                <p className="text-gray-600">建立模型是唯一對英文寫作有幫助的</p>
                <p className="text-gray-600">我們研究你想寫的，幫你建模並按部就班調整你</p>
              </CardContent>
            </Card>
            <Card className="relative overflow-hidden border-t-4 border-t-green-500">
              <CardContent className="pt-6">
                <div className="absolute top-2 right-2 opacity-10">
                  <div className="text-6xl font-bold text-green-500">02</div>
                </div>
                <h3 className="text-xl font-semibold mb-4">信度與效度兼具的評閱</h3>
                <p className="text-gray-600">詳細的評分解析和建議，幫助學生快速理解並改進寫作技巧。</p>
              </CardContent>
            </Card>
            <Card className="relative overflow-hidden border-t-4 border-t-purple-500">
              <CardContent className="pt-6">
                <div className="absolute top-2 right-2 opacity-10">
                  <div className="text-6xl font-bold text-purple-500">03</div>
                </div>
                <h3 className="text-xl font-semibold mb-4">預測與建立個人語料庫</h3>
                <p className="text-gray-600">完整的學習歷程記錄，清晰掌握進步軌跡和學習成效，並建立你個人專屬的語料庫</p>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="lg:order-2">
              <h2 className="text-3xl font-bold mb-6">學校合作方案</h2>
              <div className="space-y-4 mb-8">
                <p className="text-lg text-gray-600">
                  提供完整的教學管理系統，協助老師更有效率地進行寫作教學。
                </p>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-blue-500 mr-2" />
                    班級作業管理
                  </li>
                  <li className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-blue-500 mr-2" />
                    學生進度追蹤
                  </li>
                  <li className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-blue-500 mr-2" />
                    數據分析報告
                  </li>
                </ul>
              </div>
              <Button 
                onClick={() => handleLogin('school')}
                className="h-12"
              >
                了解更多
              </Button>
            </div>
            <div className="lg:order-1">
              <img 
                src="/School.jpg"
                className="w-full rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>

      <Dialog open={contactOpen} onOpenChange={setContactOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>聯繫我們</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="schoolName">學校/教育機構名稱</Label>
              <Input
                id="schoolName"
                value={formData.schoolName}
                onChange={(e) => setFormData(prev => ({...prev, schoolName: e.target.value}))}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="contactName">聯絡人姓名</Label>
              <Input
                id="contactName"
                value={formData.contactName}
                onChange={(e) => setFormData(prev => ({...prev, contactName: e.target.value}))}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="phone">聯絡人電話</Label>
              <Input
                id="phone"
                value={formData.phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setFormData(prev => ({...prev, phone: value}));
                  }
                }}
                pattern="^09\d{8}$"
                title="請輸入正確的手機號碼格式（例如：0912345678）"
                placeholder="0912345678"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">聯絡人信箱</Label>
              <Input
                id="email"
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({...prev, email: e.target.value}))}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="lineId">聯絡人Line ID</Label>
              <Input
                id="lineId"
                value={formData.lineId}
                onChange={(e) => setFormData(prev => ({...prev, lineId: e.target.value}))}
              />
            </div>
            <div className="space-y-2">
              <Label>方便聯繫時間</Label>
              <RadioGroup
                value={formData.preferredTime}
                onValueChange={(value) => setFormData(prev => ({...prev, preferredTime: value}))}
                required
              >
                <div className="flex space-x-4">
                  <RadioGroupItem value="morning" id="morning">
                    <Label htmlFor="morning">上午</Label>
                  </RadioGroupItem>
                  <RadioGroupItem value="afternoon" id="afternoon">
                    <Label htmlFor="afternoon">下午</Label>
                  </RadioGroupItem>
                  <RadioGroupItem value="evening" id="evening">
                    <Label htmlFor="evening">晚上</Label>
                  </RadioGroupItem>
                </div>
              </RadioGroup>
            </div>
            <div className="space-y-2">
              <Label htmlFor="message">聯繫內容</Label>
              <Textarea
                id="message"
                value={formData.message}
                onChange={(e) => setFormData(prev => ({...prev, message: e.target.value}))}
                required
              />
            </div>
            <Button type="submit" className="w-full">送出</Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LandingPage;