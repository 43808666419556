// src/components/correction/HistoryList.tsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import ErrorDisplay from '../common/ErrorDisplay';
import LoadingSpinner from '../common/LoadingSpinner';
import { EssayHistory } from '../types';
import { fetchEssayHistories, deleteEssay, fetchEssayDetail } from '../../services/api';

interface CorrectionRecord {
  id: string;
  title: string;
  createdAt: string;
  type: 'combined' | 'writing' | 'translation';
  lang: string;
  result: {
    writing?: {
      result: {
        article_cerf_lang1: {
          CEFRLevel: string;
        };
        total_score?: number | string;
      };
    };
    translation?: Array<{
      result: {
        lang1: {
          CEFRLevel: string;
        };
        output?: {
          Expressiveness?: {
            Score: number;
          };
          Faithfulness?: {
            Score: number;
          };
        };
      };
    }>;
  };
}

const HistoryList: React.FC = () => {
  const [histories, setHistories] = useState<CorrectionRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadHistories = async () => {
      try {
        setIsLoading(true);
        // console.log('開始獲取歷史記錄...');
        
        const response = await fetchEssayHistories();
        // console.log('API 回應原始數據:', response);

        if (!response) {
          throw new Error('未收到數據');
        }

        const formattedData: CorrectionRecord[] = response.map((record: any) => {
          // console.log('處理單筆記錄:', record);
          return {
            id: record.id,
            title: record.title || '未命名作業',
            createdAt: record.createdAt,
            type: record.type,
            lang: record.lang,
            result: {
              writing: record.result?.writing || null,
              translation: record.result?.translation || null
            },
          };
        });

        // console.log('最終格式化數據:', formattedData);
        setHistories(formattedData);
        setError(null);
      } catch (err) {
        console.error('載入歷史記錄失敗:', err);
        setError(err instanceof Error ? err.message : '獲取歷史記錄失敗');
      } finally {
        setIsLoading(false);
      }
    };

    loadHistories();
  }, []);

  const formatScore = (score: string | number) => {
    const numScore = typeof score === 'string' ? parseFloat(score) : score;
    return numScore.toFixed(1);
  };

  const getWritingScore = (record: CorrectionRecord) => {
    if (!record.result?.writing?.result?.total_score) {
      return '-';
    }
    
    const score = Number(record.result.writing.result.total_score) / 10;
    return score.toFixed(1);
  };

  const getTranslationScore = (record: CorrectionRecord) => {
    if (!record.result?.translation || !Array.isArray(record.result.translation)) {
      return '-';
    }

    const scores = record.result.translation.map(item => {
      const expressiveness = Number(item.result?.output?.Expressiveness?.Score) || 0;
      const faithfulness = Number(item.result?.output?.Faithfulness?.Score) || 0;
      return (expressiveness + faithfulness) / 2;
    });

    if (scores.length === 0) {
      return '-';
    }

    const averageScore = scores.reduce((acc, curr) => acc + curr, 0) / scores.length;
    return averageScore.toFixed(1);
  };

  const formatScores = (record: CorrectionRecord) => {
    return {
      writing: getWritingScore(record),
      translation: getTranslationScore(record)
    };
  };

  const getLanguage = (record: CorrectionRecord) => {
    // 從作文部分獲取語言資訊
    if (record.lang) {
      return record.lang;  // 例如："繁體中文"
    }
    
    // 從翻譯部分獲取語言資訊
    if (record.lang) {
      return record.lang;  // 例如："A2"
    }
    
    return '-';
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>標題</TableHead>
          <TableHead>日期</TableHead>
          <TableHead>語言</TableHead>
          <TableHead className="text-center">作文分數</TableHead>
          <TableHead className="text-center">翻譯分數</TableHead>
          <TableHead className="text-right">操作</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {histories.map((record) => {
          const scores = formatScores(record);

          return (
            <TableRow key={record.id}>
              <TableCell className="font-medium">
                {record.title}
              </TableCell>
              <TableCell>
                {new Date(record.createdAt).toLocaleDateString('zh-TW')}
              </TableCell>
              <TableCell>
                {getLanguage(record)}
              </TableCell>
              <TableCell className="text-center">
                <Badge variant="success">
                  {scores.writing}
                </Badge>
              </TableCell>
              <TableCell className="text-center">
                <Badge variant="success">
                  {scores.translation}
                </Badge>
              </TableCell>
              <TableCell className="text-right">
                <Link to={`/correction-result/${record.id}`}>
                  <Button variant="outline" size="sm">
                    查看結果
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default HistoryList;