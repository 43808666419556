import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';
import { Alert } from '../ui/alert';

interface WritingData {
  title: string;
  description: string;
  imageDescription: string;
  image: File | null;
  imageBase64: string | null;
  existingImage: string | null;
  imageType: string;
  originalData: any;
}

interface WritingFormProps {
  examInfo: {
    examType: string;
    year: number | null;
    round: number | null;
    mockType?: string;
  };
  isPastExam: boolean;
  onNext: (writingData: WritingData) => void;
  onBack: () => void;
  savedData?: WritingData | null;
}

const WritingForm: React.FC<WritingFormProps> = ({
  examInfo,
  isPastExam,
  onNext,
  onBack,
  savedData
}) => {
  const [writingData, setWritingData] = useState<WritingData>(() => {
    if (savedData) {
      return savedData;
    }
    return {
      title: '',
      description: '',
      imageDescription: '',
      image: null,
      imageBase64: null,
      existingImage: null,
      imageType: '',
      originalData: null
    };
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isPastExam && examInfo.examType && examInfo.year && examInfo.round) {
      fetchWriting();
    }
  }, [isPastExam, examInfo.examType, examInfo.year, examInfo.round]);

  // 清理 URL
  useEffect(() => {
    return () => {
      if (writingData.existingImage && writingData.existingImage.startsWith('blob:')) {
        URL.revokeObjectURL(writingData.existingImage);
      }
    };
  }, [writingData.existingImage]);

  const cleanImageDescription = (content: string): string => {
    if (content.includes("圖片描述：")) {
      content = content.split("圖片描述：")[1];
    }
    content = content.replace(/\n/g, "").replace(/=/g, "");
    return content.replace(/[^\u4e00-\u9fa5，。]/g, "");
  };

  const fetchWriting = async () => {
    setIsLoading(true);
    setError('');
    try {
      let examTypeParam = examInfo.examType;
      
      // 只有在是模考時才特別處理 examType
      if (examInfo.examType === 'mock' && examInfo.mockType) {
        examTypeParam = examInfo.mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考';
      }

      // console.log('Fetching writing with params:', {
      //   examType: examTypeParam,
      //   year: examInfo.year,
      //   round: examInfo.round
      // });

      const response = await fetch(
        `/api/test/writing/${encodeURIComponent(examTypeParam)}/${examInfo.year}/${examInfo.round}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch writing');

      const data = await response.json();
      if (data.success && data.writing) {
        // 如果有舊的 blob URL，先清理掉
        if (writingData.existingImage?.startsWith('blob:')) {
          URL.revokeObjectURL(writingData.existingImage);
        }

        // 處理圖片顯示
        let imageUrl = null;
        if (data.writing.image?.data) {
          const imageData = `data:image/${data.writing.image.contentType};base64,${data.writing.image.data}`;
          imageUrl = imageData;
        }

        setWritingData({
          title: '',
          description: data.writing.description || '',
          imageDescription: data.writing.imageDescription || '',
          image: null,
          imageBase64: null,
          existingImage: imageUrl,
          imageType: data.writing.image?.contentType || '',
          originalData: data.writing
        });
      }
    } catch (error) {
      console.error('Failed to fetch writing:', error);
      setError('獲取作文題目失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!writingData.description.trim()) {
      setError('請先填寫題目描述再上傳圖片');
      e.target.value = '';
      return;
    }

    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('圖片大小不能超過 5MB');
        e.target.value = '';
        return;
      }

      if (file.type === 'image/webp') {
        setError('不支援 WebP 格式圖片');
        e.target.value = '';
        return;
      }

      try {
        setIsLoading(true);
        
        const formData = new FormData();
        formData.append('image', file);
        formData.append('description', writingData.description);
        
        const response = await fetch('/api/image2txt/process-image', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formData
        });

        const data = await response.json();

        if (!response.ok || !data.success) {
          throw new Error(data.message || '處理圖片失敗');
        }

        const cleanedDescription = cleanImageDescription(data.imageDescription);

        // 如果有舊的 blob URL，先清理掉
        if (writingData.existingImage?.startsWith('blob:')) {
          URL.revokeObjectURL(writingData.existingImage);
        }

        // 從 data.image.data 中提取數據
        const imageDataArray = data.image.data.data; // 這是一個數字數組
        const uint8Array = new Uint8Array(imageDataArray);

        // 創建 Blob 並生成 URL
        const blob = new Blob([uint8Array], { type: `image/${data.image.contentType}` });
        const imageUrl = URL.createObjectURL(blob);

        setWritingData(prev => ({
          ...prev,
          image: file,
          existingImage: imageUrl,
          imageType: data.image.contentType,
          imageDescription: cleanedDescription
        }));

      } catch (error) {
        console.error('Image upload error:', error);
        setError(error instanceof Error ? error.message : '圖片上傳失敗');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNext = () => {
    // 不修改 writingData，直接傳遞
    onNext(writingData);
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-40">載入中... 圖片描述自動生成中請等待！</div>;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">作文題目</h2>
      {error && (
        <Alert variant="destructive">
          {error}
        </Alert>
      )}

      <Card>
        <CardContent className="space-y-6 pt-6">
          <div className="space-y-2">
            <Label htmlFor="title">作文標題</Label>
            <Input
              id="title"
              value={writingData.title}
              onChange={(e) => setWritingData(prev => ({ ...prev, title: e.target.value }))}
              placeholder="請輸入作文標題"
              className="text-lg"
            />
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="description">題目描述</Label>
            <Textarea
              id="description"
              value={writingData.description}
              onChange={(e) => setWritingData(prev => ({ ...prev, description: e.target.value }))}
              placeholder="請輸入題目描述"
              className="min-h-[200px] text-base"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="image">題目圖片</Label>
            <Input
              id="image"
              type="file"
              onChange={handleImageUpload}
              accept="image/*"
            />
            {(writingData.existingImage || writingData.image) && (
              <div className="mt-4 space-y-4">
                <img 
                  src={writingData.existingImage || undefined}
                  alt="題目圖片"
                  className="max-w-2xl h-auto rounded-md"
                />
                <div className="space-y-2">
                  <Label htmlFor="imageDescription">圖片描述</Label>
                  <Textarea
                    id="imageDescription"
                    value={writingData.imageDescription}
                    onChange={(e) => setWritingData(prev => ({
                      ...prev,
                      imageDescription: e.target.value
                    }))}
                    placeholder="系統生成的圖片描述"
                    className="min-h-[150px]"
                  />
                </div>
              </div>
            )}
          </div>

          {isPastExam && writingData.originalData && (
            <div className="mt-4">
              <Button
                variant="outline"
                onClick={() => setWritingData(prev => ({
                  ...prev,
                  description: prev.originalData.question || '',
                  imageDescription: prev.originalData.description || ''
                }))}
              >
                恢復原題內容
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>返回</Button>
        <Button onClick={handleNext}>下一步</Button>
      </div>
    </div>
  );
};

export default WritingForm;
