import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Loader2 } from 'lucide-react';
import { Radar } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  RadialLinearScale, 
  PointElement, 
  LineElement, 
  Filler, 
  Tooltip, 
  Legend 
} from 'chart.js';
import { ChartOptions } from 'chart.js';
import { fetchEssayHistories } from '../../services/api';

// 註冊 Chart.js 組件
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

// 定義結構元素
const structureElements = [
  { key: 'Hook', name: '開場引言', section: 'Introduction' },
  { key: 'Background Information', name: '背景資訊', section: 'Introduction' },
  { key: 'Thesis Statement', name: '主旨陳述', section: 'Introduction' },
  { key: 'Topic Sentence', name: '主題句', section: 'Body Paragraph' },
  { key: 'Supporting Details', name: '支持論點', section: 'Body Paragraph' },
  { key: 'Explanation and Analysis', name: '解釋分析', section: 'Body Paragraph' },
  { key: 'Transition', name: '過渡句', section: 'Body Paragraph' },
  { key: 'Restate the Thesis', name: '重申主旨', section: 'Conclusion' },
  { key: 'Summary', name: '總結', section: 'Conclusion' },
  { key: 'Final Thought', name: '結語', section: 'Conclusion' }
];

interface VocabularyWord {
  Word: string;
  Suggestion: string;
  WordTranslation: string;
  SuggestionTranslation: string;
}

interface Record {
  id: string;
  title: string;
  result: {
    writing: {
      content: string;
      result: any;
    } | null;
  };
}


interface Essay {
  id: string;
  title: string;
  result: {
    writing: {
      content: string;
      result: {
        Suggestion_lang1: { 'Total Score': string };
        article_FIX: { 'Before Revision': string; 'After Revision': string };
        Vocabulary_Upgrade_lang1: { 
          ImproveVocabulary?: Array<{ 
            Word: string; 
            Suggestion: string;
            WordTranslation: string;
            SuggestionTranslation: string;
          }> | null 
        };
        StructureDivisionAgent_lang1: {
          Introduction: any;
          'Body Paragraph': any;
          Conclusion: any;
        };
      };
    };
  };
}

const ComparisonView: React.FC = () => {
  const [essays, setEssays] = useState<Essay[]>([]);
  const [selectedEssays, setSelectedEssays] = useState<[string, string]>(['', '']);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>('');

  useEffect(() => {
    fetchEssays();
  }, []);

  const fetchEssays = async () => {
    try {
      setIsLoading(true);
      // console.log('開始獲取歷史記錄...');
      
      const response = await fetchEssayHistories();
      // console.log('API 回應原始數據:', response);

      if (!response) {
        throw new Error('未收到數據');
      }

      const formattedData = response
        .filter((record: Record) => record.result.writing)
        .map((record: Record) => ({
          id: record.id,
          title: record.title || '未命名作業',
          result: {
            writing: record.result.writing
          }
        }));

      // console.log('最終格式化數據:', formattedData);
      setEssays(formattedData);
      setError(null);
    } catch (err) {
      console.error('載入歷史記錄失敗:', err);
      setError(err instanceof Error ? err.message : '獲取資料失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 修改 getEssayById 以適應新的數據結構
  const getEssayById = (id: string) => essays.find((essay) => essay.id === id);

  // 修改資料讀取的方式
  const generateRadarData = (essay: Essay | undefined) => {
    if (!essay || !essay.result.writing) return { labels: [], datasets: [] };

    const structureData = essay.result.writing.result.StructureDivisionAgent_lang1;
    const introData = structureData.Introduction || {};
    const bodyData = structureData['Body Paragraph'] || {};
    const conclData = structureData.Conclusion || {};

  // 檢查元素是否存在且有內容
  const isElementPresent = (data: any, key: string): number => {
    if (!data || !data[key] || data[key] === '<Text or indication if missing>') {
      return 0;
    }
    const missingElements = data['Missing Elements'] || [];
    return missingElements.includes(key) ? 0 : 10;
  };

  const data = structureElements.map(element => {
    let score = 0;
    switch (element.section) {
      case 'Introduction':
        score = isElementPresent(introData, element.key);
        break;
      case 'Body Paragraph':
        score = isElementPresent(bodyData, element.key);
        break;
      case 'Conclusion':
        score = isElementPresent(conclData, element.key);
        break;
    }
    return score;
  });

  return {
    labels: structureElements.map(element => element.name),
    datasets: [
      {
        label: essay.title,
        data,
        backgroundColor: 'rgba(99, 102, 241, 0.2)',
        borderColor: 'rgb(99, 102, 241)',
        borderWidth: 2,
        pointBackgroundColor: 'rgb(99, 102, 241)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(99, 102, 241)',
      }
    ]
  };
};

const radarOptions = {
  responsive: true,
  scales: {
    r: {
      beginAtZero: true,
      max: 10,
      ticks: {
        stepSize: 2,
        font: {
          size: 12
        }
      },
      pointLabels: {
        font: {
          size: 12,
          weight: 'bold' as const // 修正這裡，確保 weight 使用正確的型別
        }
      },
      grid: {
        color: 'rgba(0, 0, 0, 0.1)'
      }
    }
  },
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        font: {
          size: 14
        },
        padding: 20
      }
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      padding: 12,
      titleFont: {
        size: 14
      },
      bodyFont: {
        size: 13
      },
      callbacks: {
        label: function(context: any) {
          return `完整度: ${context.raw === 10 ? '完整' : '缺失'}`;
        }
      }
    }
  }
} as const;


const chartOptions: ChartOptions<'radar'> = radarOptions;

if (isLoading) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem'
    }}>
      <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
    </div>
  );
}

if (error) {
  return (
    <div style={{ color: '#dc2626', padding: '1rem' }}>
      {error}
    </div>
  );
}

const essay1 = getEssayById(selectedEssays[0]);
const essay2 = getEssayById(selectedEssays[1]);

return (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
    <div style={{ 
      display: 'grid', 
      gridTemplateColumns: '1fr 1fr', 
      gap: '1rem' 
    }}>
      {[0, 1].map((index) => (
        <Select 
          key={index}
          value={selectedEssays[index]} 
          onValueChange={(value) => {
            const newSelected = [...selectedEssays];
            newSelected[index] = value;
            setSelectedEssays(newSelected as [string, string]);
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder={`選擇作文 ${index + 1}`} />
          </SelectTrigger>
          <SelectContent>
            {essays.map((essay) => (
              <SelectItem 
                key={essay.id} 
                value={essay.id}
                disabled={essay.id === selectedEssays[index === 0 ? 1 : 0]}
              >
                {essay.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      ))}
    </div>

    {essay1 && essay2 && (
      <>
        <Card>
          <CardHeader>
            <CardTitle>分數比較</CardTitle>
          </CardHeader>
          <CardContent style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div style={{ fontSize: '1.125rem', fontWeight: 600 }}>
              作文 1: {parseFloat(essay1.result.writing.result.Suggestion_lang1['Total Score']) / 10}/20
            </div>
            <div style={{ fontSize: '1.125rem', fontWeight: 600 }}>
              作文 2: {parseFloat(essay2.result.writing.result.Suggestion_lang1['Total Score']) / 10}/20
            </div>
          </CardContent>
        </Card>

        {/* 更新文章比較卡片 */}
        <Card>
          <CardHeader>
            <CardTitle>作文內容比較</CardTitle>
          </CardHeader>
          <CardContent style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem'
          }}>
            {[essay1, essay2].map((essay, idx) => (
              <div key={idx}>
                <h3 style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                  作文 {idx + 1}
                </h3>
                <div>
                  <h4 style={{ fontWeight: 600, marginBottom: '0.25rem' }}>原文：</h4>
                  <p style={{ whiteSpace: 'pre-wrap', marginBottom: '1rem' }}>
                    {essay.result.writing.result.article_FIX['Before Revision']}
                  </p>
                  <h4 style={{ fontWeight: 600, marginBottom: '0.25rem' }}>修改後：</h4>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    {essay.result.writing.result.article_FIX['After Revision']}
                  </p>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>

        {/* 更新單字比較卡片 */}
        <Card>
          <CardHeader>
            <CardTitle>詞彙改進比較</CardTitle>
          </CardHeader>
          <CardContent style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem'
          }}>
            {[essay1, essay2].map((essay, idx) => (
              <div key={idx}>
                <h3 style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                  作文 {idx + 1}
                </h3>
                {essay.result.writing.result.Vocabulary_Upgrade_lang1?.ImproveVocabulary?.length ? (
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {essay.result.writing.result.Vocabulary_Upgrade_lang1.ImproveVocabulary.map((word: VocabularyWord, index: number) => (
                      <li key={index} style={{ 
                        marginBottom: '0.5rem',
                        padding: '0.5rem',
                        backgroundColor: index % 2 === 0 ? '#f8fafc' : 'white',
                        borderRadius: '0.25rem'
                      }}>
                        <div style={{ marginBottom: '0.25rem' }}>
                          <span style={{ color: '#dc2626' }}>{word.Word}</span>
                          {word.WordTranslation && (
                            <span style={{ color: '#6b7280' }}> ({word.WordTranslation})</span>
                          )}
                        </div>
                        <div>
                          <span style={{ color: '#16a34a' }}>→ {word.Suggestion}</span>
                          {word.SuggestionTranslation && (
                            <span style={{ color: '#6b7280' }}> ({word.SuggestionTranslation})</span>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p style={{ color: '#6b7280' }}>無需改進的單字</p>
                )}
              </div>
            ))}
          </CardContent>
        </Card>

        {/* 結構分析雷達圖 */}
        <Card>
          <CardHeader>
            <CardTitle>結構分析比較</CardTitle>
          </CardHeader>
          <CardContent style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1.5rem'
          }}>
            {[essay1, essay2].map((essay, idx) => (
              <div key={idx}>
                <h3 style={{ 
                  fontWeight: 'bold', 
                  marginBottom: '1rem',
                  textAlign: 'center'
                }}>
                  作文 {idx + 1} 的結構分析
                </h3>
                <div style={{ position: 'relative' }}>
                  <Radar 
                  data={generateRadarData(essay)} 
                  options={chartOptions}  // 使用正確類型的選項
                />;
                </div>
              </div>
            ))}
            <div style={{
              gridColumn: '1 / -1',
              textAlign: 'center',
              color: '#6b7280',
              fontSize: '0.875rem'
            }}>
              * 分數 10 表示結構完整，0 表示結構缺失
            </div>
          </CardContent>
        </Card>
      </>
    )}
  </div>
);
};

export default ComparisonView;