import React from 'react';
import { 
  Document, 
  Page, 
  Text, 
  View, 
  StyleSheet, 
  Font, 
  Image,
  pdf
} from '@react-pdf/renderer';
import { ReportGeneratorProps, CorrectionResult } from '../types/correction';
import reportCoverImage from './images/report.png';  // 確保這個路徑正確

// 使用 Google Fonts CDN
Font.register({
  family: 'Noto Sans TC',
  src: 'https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff2'
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Noto Sans TC'
  },
  coverPage: {
    position: 'relative',
    height: '100%'
  },
  coverImage: {
    width: '100%',
    height: '100%'
  },
  coverInfo: {
    position: 'absolute',
    bottom: 100,
    left: 50,
    fontSize: 14,
    fontFamily: 'Noto Sans TC'
  },
  infoRow: {
    marginBottom: 15,
    flexDirection: 'row',
    alignItems: 'center'
  },
  infoLabel: {
    width: 80,
    color: '#4a5568'
  },
  infoValue: {
    flex: 1,
    color: '#2d3748'
  },
  contentImage: {
    maxWidth: '100%',
    maxHeight: 300,
    objectFit: 'contain',
    marginBottom: 20
  },
  section: {
    marginBottom: 20,
    marginTop: 20
  },
  sectionTitle: {
    fontSize: 16,
    color: '#2b6cb0',
    marginBottom: 10,
    fontWeight: 'bold',
    fontFamily: 'Noto Sans TC'
  },
  highlightText: {
    backgroundColor: '#fff5f5',
    color: '#e53e3e',
    padding: 4
  },
  correctedText: {
    backgroundColor: '#f0fff4',
    color: '#38a169',
    padding: 4
  },
  stepTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#1a365d'
  },
  observationBox: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  originalText: {
    marginBottom: 10,
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 4
  },
  correctionBox: {
    marginTop: 10,
    padding: 8,
    backgroundColor: '#f0fff4',
    borderRadius: 4
  },
  analysisGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: 8
  },
  analysisItem: {
    flex: 1,
    minWidth: '45%',
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 8
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#4a5568'
  },
  content: {
    fontSize: 11,
    color: '#2d3748',
    fontFamily: 'Noto Sans TC',
    lineHeight: 1.5,
    width: '100%'
  },
  structureBox: {
    marginBottom: 20,
    padding: 12,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  structureSection: {
    marginBottom: 15
  },
  structureHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2b6cb0',
    marginBottom: 8
  },
  structureElement: {
    marginBottom: 12,
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderLeftWidth: 3,
    borderLeftColor: '#4299e1'
  },
  elementTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#4a5568',
    marginBottom: 4
  },
  missingElement: {
    backgroundColor: '#fff5f5',
    borderColor: '#fc8181',
    borderWidth: 1
  },
  missingText: {
    color: '#e53e3e',
    fontSize: 11
  },
  structureSuggestionBox: {
    marginTop: 8,
    padding: 8,
    backgroundColor: '#eff6ff',
    borderRadius: 4,
  },
  vocabularyBox: {
    marginBottom: 20,
    padding: 12,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  vocabularyGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8
  },
  vocabularyCard: {
    flex: 1,
    minWidth: '45%',
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#e2e8f0'
  },
  cefrLevelBox: {
    padding: 4,
    backgroundColor: '#edf2f7',
    borderRadius: 2,
    marginBottom: 4
  },
  wordList: {
    marginTop: 8,
    padding: 6,
    backgroundColor: '#f7fafc',
    borderRadius: 4
  },
  wordItem: {
    marginBottom: 4,
    padding: 4,
    borderBottomWidth: 1,
    borderColor: '#edf2f7'
  },
  upgradeBox: {
    marginBottom: 20,
    padding: 12,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  comparisonGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12
  },
  comparisonColumn: {
    flex: 1,
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 4
  },
  columnHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#2d3748',
    marginBottom: 8,
    textAlign: 'center',
    padding: 4,
    backgroundColor: '#edf2f7',
    borderRadius: 4
  },
  improveBox: {
    marginTop: 12,
    padding: 8,
    backgroundColor: '#ebf8ff',
    borderRadius: 4
  },
  phraseCard: {
    marginBottom: 8,
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#e2e8f0'
  },
  statsBox: {
    marginBottom: 20,
    padding: 12,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  statsGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 12
  },
  statsCard: {
    flex: 1,
    minWidth: '45%',
    padding: 12,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#e2e8f0'
  },
  statsHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2d3748',
    marginBottom: 8,
    textAlign: 'center'
  },
  statsValue: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#4299e1',
    textAlign: 'center',
    marginBottom: 4
  },
  progressBox: {
    marginTop: 12,
    padding: 8,
    backgroundColor: '#ebf8ff',
    borderRadius: 4
  },
  errorText: {
    color: '#e53e3e',
    backgroundColor: '#fff5f5'
  },
  analysisColumn: {
    flex: 1,
    padding: 10,
    marginHorizontal: 5
  },
  structureItem: {
    marginBottom: 15,
    padding: 10,
    backgroundColor: '#f7fafc'
  },
  vocabItem: {
    padding: 8,
    marginBottom: 5,
    backgroundColor: '#fff'
  },
  improvementItem: {
    marginBottom: 15,
    padding: 10
  },
  statsSection: {
    marginBottom: 20,
    marginTop: 20
  },
  statsText: {
    fontSize: 14,
    color: '#2d3748',
    fontFamily: 'Noto Sans TC'
  },
  learningAdvice: {
    marginTop: 10,
    padding: 8,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  adviceTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#2b6cb0',
    marginBottom: 4
  },
  adviceContent: {
    fontSize: 11,
    color: '#2d3748',
    fontFamily: 'Noto Sans TC'
  },
  pointTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#1a365d'
  },
  pointContent: {
    fontSize: 11,
    color: '#2d3748',
    fontFamily: 'Noto Sans TC'
  },
  cefrSection: {
    marginBottom: 30,
    marginTop: 20
  },
  evaluationText: {
    fontSize: 11,
    marginBottom: 12,
    lineHeight: 1.5,
    fontFamily: 'Noto Sans TC'
  },
  structureContainer: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  vocabularyList: {
    marginBottom: 15
  },
  wordText: {
    fontSize: 11,
    color: '#2d3748'
  },
  cefrLevel: {
    fontSize: 11,
    color: '#4a5568'
  },
  textContainer: {
    width: '100%',
    marginTop: 4
  },
  textSegment: {
    fontSize: 11,
    lineHeight: 1.6,
    marginBottom: 4,
    fontFamily: 'Noto Sans TC',
    color: '#2d3748',
    width: '100%',
    display: 'flex'
  },
  comparisonSection: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f8fafc',
    borderRadius: 4
  },
  comparisonText: {
    fontSize: 11,
    marginBottom: 8,
    lineHeight: 1.5,
    fontFamily: 'Noto Sans TC'
  },
  errorHighlight: {
    backgroundColor: '#fff5f5',
    color: '#e53e3e',
  },
  correctionHighlight: {
    backgroundColor: '#f0fff4',
    color: '#38a169',
  },
  wordCountBox: {
    marginTop: 10,
    padding: 8,
    backgroundColor: '#edf2f7',
    borderRadius: 4
  },
  wordCountText: {
    fontSize: 10,
    color: '#4a5568'
  },
  analysisContent: {
    fontSize: 11,
    lineHeight: 1.6,
    marginBottom: 4,
    fontFamily: 'Noto Sans TC',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  analysisBox: {
    marginBottom: 15,
    padding: 12,
    backgroundColor: '#fff',
    borderRadius: 4
  },
  languageSection: {
    marginBottom: 12,
    padding: 10,
    backgroundColor: '#f8fafc',
    borderRadius: 4,
    width: '100%'
  },
  languageLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#4a5568',
    marginBottom: 4
  },
  pageBreak: {
    marginTop: 30,
    marginBottom: 20
  },
  conclusionSection: {
    marginBottom: 20,
    width: '100%'
  },
  conclusionContent: {
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%'
  },
  conclusionText: {
    fontSize: 11,
    lineHeight: 1.6,
    fontFamily: 'Noto Sans TC',
    color: '#2d3748',
    flexWrap: 'wrap',
    width: '100%'
  },
  textSection: {
    marginBottom: 15,
  },
  sectionLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#4a5568',
    marginBottom: 8,
  },
  divider: {
    height: 1,
    backgroundColor: '#e2e8f0',
    marginVertical: 20,
  },
  textBlock: {
    fontSize: 12,
    lineHeight: 1.6,
    fontFamily: 'Helvetica',
    marginBottom: 10,
  },
  textContent: {
    fontSize: 12,
    lineHeight: 1.6,
    fontFamily: 'Helvetica',
  },
  scoreBox: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  evaluationBox: {
    marginTop: 12,
    padding: 12,
    backgroundColor: '#f8fafc',
    borderRadius: 4,
  },
  scoreHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  scoreTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3748',
  },
  reviewBox: {
    marginTop: 8,
  },
  reviewSection: {
    marginBottom: 8,
  },
  reviewLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#4a5568',
    marginBottom: 4,
  },
  errorItem: {
    padding: 8,
    backgroundColor: '#f8fafc',
    borderRadius: 4,
    marginBottom: 8
  },
  structureIndicator: {
    marginBottom: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 12,
  },
  structureColumnHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#1e40af',
    paddingHorizontal: 2,
    marginBottom: 8,
  },
  structureElementTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
    color: '#4b5563',
  },
  elementIndicator: {
    flex: 1,
    alignItems: 'center',
    padding: 2,
    minWidth: 30,
  },
  elementNumber: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: '#fff',
    textAlign: 'center',
    lineHeight: 16,
    fontSize: 8,
    fontWeight: 'bold',
    color: '#3b82f6',
    marginBottom: 2,
  },
  elementName: {
    fontSize: 6,
    textAlign: 'center',
    color: '#4b5563',
    width: '100%',
    wordBreak: 'break-word',
  },
  statusIndicator: {
    width: 14,
    height: 14,
    borderRadius: 7,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 2,
  },
  statusText: {
    fontSize: 8,
    fontWeight: 'bold',
  },
  completionRate: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1e40af',
    marginLeft: 10,
  },
  completionContainer: {
    flex: 1.2,
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderLeftColor: '#e2e8f0',
    marginLeft: 4,
    paddingLeft: 4,
    minWidth: 40,
  },
  sectionContainer: {
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#1e40af',
  },
  elementContainer: {
    marginBottom: 12,
  },
  contentBox: {
    padding: 12,
    borderRadius: 4,
  },
  suggestionBox: {
    marginTop: 8,
    padding: 8,
    backgroundColor: '#eff6ff',
    borderRadius: 4,
  },
  suggestionTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#1e40af',
    marginBottom: 4,
  },
  suggestionContent: {
    fontSize: 10,
    color: '#4b5563',
  },
  headerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circlesContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  }
});

// CEFR等級對照輔助函數
const getCEFREquivalent = (level?: string) => {
  const equivalents: Record<string, string> = {
    'A1': '未達全民英檢初級',
    'A2': '全民英檢初級',
    'B1': '全民英檢中級',
    'B2': '全民英檢中高級',
    'C1': '全民英檢高級',
    'C2': '全民英檢高級'
  };
  return level ? equivalents[level] : '未評估';
};

// 算總分的輔助函數
const calculateTotalScore = (result: any): string => {
  try {
    const totalScore = (
      Number(result.ContentScoreAgen_lang1.ContentScore.split('/')[0]) +
      Number(result.GrammarStructureScoreAgent_lang1.Score.split('/')[0]) +
      Number(result.OrganizationScoreAgent_lang1.Score.split('/')[0]) +
      Number(result.VocabularySpellingScoreAgent_lang1.Score.split('/')[0])
    );
    return (totalScore / 10).toFixed(1);
  } catch (error) {
    console.error('Error calculating total score:', error);
    return '0.0';
  }
};

// 優化單詞計數數
const countWords = (text: string): number => {
  if (!text) return 0;
  return text.trim()
    .split(/\s+/)
    .filter(word => word.length > 0 && !/^\W+$/.test(word))
    .length;
};

// 修改觀察部分渲染函數
const renderObservationSection = (data: ReportGeneratorProps['data']) => {
  const renderText = (text: string, corrections: any[], isOriginal: boolean) => {
    let segments: { text: string; type: 'normal' | 'error' | 'correction' }[] = [];
    let currentIndex = 0;

    const sortedCorrections = corrections.sort((a, b) => {
      const indexA = text.indexOf(a['Original Sentence']);
      const indexB = text.indexOf(b['Original Sentence']);
      return indexA - indexB;
    });

    sortedCorrections.forEach(correction => {
      const errorText = correction['Original Sentence'];
      const correctedText = correction['Corrected Sentence'];
      const errorIndex = text.indexOf(errorText, currentIndex);

      if (errorIndex > currentIndex) {
        segments.push({
          text: text.slice(currentIndex, errorIndex),
          type: 'normal'
        });
      }

      if (isOriginal) {
        segments.push({
          text: errorText,
          type: 'error'
        });
      } else {
        segments.push({
          text: correctedText,
          type: 'correction'
        });
      }

      currentIndex = errorIndex + errorText.length;
    });

    if (currentIndex < text.length) {
      segments.push({
        text: text.slice(currentIndex),
        type: 'normal'
      });
    }

    return (
      <Text style={styles.textBlock}>
        {segments.map((segment, index) => {
          const segmentStyle = segment.type === 'error' 
            ? styles.errorHighlight
            : segment.type === 'correction'
              ? styles.correctionHighlight
              : {};

          return (
            <Text key={index} style={segmentStyle}>
              {segment.text}
            </Text>
          );
        })}
      </Text>
    );
  };

  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>原文與修改</Text>
      <View style={styles.observationBox}>
        {/* 原文 */}
        <View style={styles.textSection}>
          <Text style={styles.sectionLabel}>原文：</Text>
          <Text style={styles.textBlock}>
            {renderText(data.content, data.result.Suggestion_lang1['Errors and Corrections'], true)}
          </Text>
        </View>

        {/* 間隔線 */}
        <View style={styles.divider} />

        {/* 修改後版本 */}
        <View style={styles.textSection}>
          <Text style={styles.sectionLabel}>批改後：</Text>
          <Text style={styles.textBlock}>
            {renderText(data.content, data.result.Suggestion_lang1['Errors and Corrections'], false)}
          </Text>
        </View>

        <View style={styles.wordCountBox}>
          <Text style={styles.wordCountText}>
            字數統計：{countWords(data.content)} 個單詞
          </Text>
        </View>
      </View>
    </View>
  );
};



// 結構分析渲染函數
const renderStructureAnalysis = (data: ReportGeneratorProps['data']) => {
  const structureElements = [
    { key: 'Hook', name: 'Hook', section: 'Introduction', order: 1 },
    { key: 'Background Information', name: 'Background\nInformation', section: 'Introduction', order: 2 },
    { key: 'Thesis Statement', name: 'Thesis\nStatement', section: 'Introduction', order: 3 },
    { key: 'Topic Sentence', name: 'Topic\nSentence', section: 'Body Paragraph', order: 4 },
    { key: 'Supporting Details', name: 'Supporting\nDetails', section: 'Body Paragraph', order: 5 },
    { key: 'Explanation and Analysis', name: 'Explanation\nAnalysis', section: 'Body Paragraph', order: 6 },
    { key: 'Transition', name: 'Transition', section: 'Body Paragraph', order: 7 },
    { key: 'Restate the Thesis', name: 'Restate\nThesis', section: 'Conclusion', order: 8 },
    { key: 'Summary', name: 'Summary', section: 'Conclusion', order: 9 },
    { key: 'Final Thought', name: 'Final\nThought', section: 'Conclusion', order: 10 }
  ];

  const isElementPresent = (section: string, elementKey: string): boolean => {
    const sectionData = data.result.StructureDivisionAgent_lang1[section];
    if (!sectionData || !(elementKey in sectionData)) {
      return false;
    }
    const elementValue = sectionData[elementKey];
    if (!elementValue || elementValue === '<Text or indication if missing>') {
      return false;
    }
    const missingElements = sectionData['Missing Elements'] || [];
    return !missingElements.includes(elementKey);
  };

  // 添加 getTranslatedText 函數
  const getTranslatedText = (section: string, elementKey: string): string => {
    const translatedSection = `${section} (translated)` as keyof typeof data.result.StructureDivisionAgent_lang2;
    const translatedData = data.result.StructureDivisionAgent_lang2?.[translatedSection];
    
    if (!translatedData) return '';
    
    // 使用類型斷言來處理動態性訪問
    return (translatedData as any)[elementKey] || '';
  };

  // 計算完成度
  const calculateCompletionRate = () => {
    let presentCount = 0;
    const totalElements = structureElements.length;
    
    structureElements.forEach(element => {
      if (isElementPresent(element.section, element.key)) {
        presentCount++;
      }
    });
    
    return Math.round((presentCount / totalElements) * 100);
  };

  const completionRate = calculateCompletionRate();

  // 渲染單個結構元素
  const renderStructureElement = (element: typeof structureElements[0], index: number) => {
    const isPresent = isElementPresent(element.section, element.key);
    
    return (
      <View key={index} style={[styles.elementIndicator, { padding: 4 }]}>
        <Text style={styles.elementNumber}>{element.order}</Text>
        <View style={[
          styles.statusIndicator,
          isPresent
            ? { backgroundColor: '#dcfce7' }
            : { backgroundColor: '#fee2e2' }
        ]}>
          <Text style={[
            styles.statusText,
            isPresent
              ? { color: '#059669' }
              : { color: '#dc2626' }
          ]}>
            {isPresent ? '✓' : '✗'}
          </Text>
        </View>
        <Text style={styles.elementName}>{element.name}</Text>
      </View>
    );
  };

  return (
    <View style={styles.structureBox}>
      <Text style={styles.sectionTitle}>結構分析</Text>

      {/* 結構完整度指示器 */}
      <View style={styles.structureIndicator}>
        {/* 標題列 */}
        <View style={{ flexDirection: 'row', backgroundColor: '#f0f9ff', padding: 4 }}>
          {/* 引言部分 */}
          <View style={[styles.headerContainer, { flex: 3 }]}>
            <Text style={styles.structureColumnHeader}>引言 Introduction</Text>
          </View>
          {/* 主體段落部分 */}
          <View style={[styles.headerContainer, { flex: 4 }]}>
            <Text style={styles.structureColumnHeader}>主體段落 Body Paragraph</Text>
          </View>
          {/* 結論部分 */}
          <View style={[styles.headerContainer, { flex: 2 }]}>
            <Text style={styles.structureColumnHeader}>結論 Conclusion</Text>
          </View>
          {/* 完整度部分 */}
          <View style={[styles.headerContainer, { flex: 1.2 }]}>
            <Text style={styles.structureColumnHeader}>完整度</Text>
          </View>
        </View>

        {/* 狀態指示器 */}
        <View style={{ flexDirection: 'row', marginTop: 4 }}>
          {/* Introduction 部分 */}
          <View style={[styles.circlesContainer, { flex: 3 }]}>
            {structureElements
              .filter(el => el.section === 'Introduction')
              .map((element, index) => renderStructureElement(element, index))}
          </View>

          {/* Body Paragraph 部分 */}
          <View style={[styles.circlesContainer, { flex: 4 }]}>
            {structureElements
              .filter(el => el.section === 'Body Paragraph')
              .map((element, index) => renderStructureElement(element, index))}
          </View>

          {/* Conclusion 部分 */}
          <View style={[styles.circlesContainer, { flex: 2 }]}>
            {structureElements
              .filter(el => el.section === 'Conclusion')
              .map((element, index) => renderStructureElement(element, index))}
          </View>

          {/* 完整度百分比 */}
          <View style={styles.completionContainer}>
            <Text style={styles.completionRate}>{completionRate}%</Text>
          </View>
        </View>
      </View>

      {/* 詳細結構分析 */}
      {['Introduction', 'Body Paragraph', 'Conclusion'].map((section, index) => (
        <View key={section} style={styles.sectionContainer}>
          <Text style={styles.sectionHeader}>
            {index + 1}. {section === 'Introduction' ? '引言'
              : section === 'Body Paragraph' ? '主體段落'
              : '結論'} {section}
          </Text>
          {structureElements
            .filter(el => el.section === section)
            .map((element, elementIndex) => {
              const sectionData = data.result.StructureDivisionAgent_lang1[section];
              const isMissing = !isElementPresent(section, element.key);
              const translatedText = getTranslatedText(section, element.key);

              return (
                <View key={elementIndex} style={styles.elementContainer}>
                  <Text style={styles.structureElementTitle}>【{element.name}】</Text>
                  <View style={[
                    styles.contentBox,
                    isMissing ? { backgroundColor: '#fef2f2' } : { backgroundColor: '#f8fafc' }
                  ]}>
                    {isMissing ? (
                      <View>
                        <Text style={{ color: '#dc2626' }}>缺少該結構</Text>
                        {data.result.upgrade_essay_lang1?.EnhancedEssay && (
                          <View style={styles.structureSuggestionBox}>
                            <Text style={styles.suggestionTitle}>根據升級版作為給予結構建議：</Text>
                            <Text style={styles.suggestionContent}>
                              {data.result.upgrade_essay_lang1.EnhancedEssay}
                            </Text>
                          </View>
                        )}
                      </View>
                    ) : (
                      <View>
                        <Text style={styles.content}>{sectionData[element.key]}</Text>
                        {translatedText && (
                          <Text style={[styles.content, { color: '#4b5563' }]}>
                            {translatedText}
                          </Text>
                        )}
                      </View>
                    )}
                  </View>
                </View>
              );
            })}
        </View>
      ))}
    </View>
  );
};

// 字彙分析渲染函數
const renderVocabularyAnalysis = (data: ReportGeneratorProps['data']) => {
  // 分析掌握的詞彙
  const analyzeVocabulary = () => {
    const basicVocab = data.result.Basic_voc || [];
    const cefrLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
    
    // 按CEFR等級分類詞彙
    const vocabByLevel = basicVocab.reduce((acc, word) => {
      const level = word.CEFR || 'Unknown';
      if (!acc[level]) acc[level] = [];
      acc[level].push(word);
      return acc;
    }, {} as Record<string, typeof basicVocab>);

    return {
      totalCount: basicVocab.length,
      byLevel: vocabByLevel,
      levels: cefrLevels.map(level => ({
        level,
        count: vocabByLevel[level]?.length || 0
      }))
    };
  };

  const vocabularyStats = analyzeVocabulary();

  return (
    <View style={styles.vocabularyBox}>
      <Text style={styles.sectionTitle}>字彙分析</Text>

      {/* 字彙統計概覽 */}
      <View style={styles.analysisItem}>
        <Text style={styles.label}>字彙掌握程度</Text>
        <Text style={styles.content}>
          總計已掌握詞彙：{vocabularyStats.totalCount} 個
        </Text>
        <Text style={styles.content}>
          CEFR等級：{data.result.article_cerf_lang1?.VocabularyRange || 'N/A'}
        </Text>
      </View>

      {/* CEFR等級分布 */}
      <View style={styles.vocabularyGrid}>
        {vocabularyStats.levels.map(({ level, count }) => (
          <View key={level} style={styles.vocabularyCard}>
            <View style={styles.cefrLevelBox}>
              <Text style={styles.label}>{level}</Text>
            </View>
            <Text style={styles.content}>{count} 個詞彙</Text>
          </View>
        ))}
      </View>

      {/* 詞彙使用分析 */}
      <View style={styles.analysisItem}>
        <Text style={styles.label}>詞彙使用評估</Text>
        <Text style={styles.content}>
          {data.result.VocabularySpellingScoreAgent_lang1.Review}
        </Text>
        <Text style={styles.content}>
          分數：{data.result.VocabularySpellingScoreAgent_lang1.Score}/5
        </Text>
      </View>

      {/* 需要進的詞彙 */}
      <View style={styles.analysisItem}>
        <Text style={styles.label}>建議改進的詞彙</Text>
        <View style={styles.wordList}>
          {data.result.Vocabulary_Upgrade_lang1?.ImproveVocabulary?.map((word, index) => (
            <View key={index} style={styles.wordItem}>
              <Text style={styles.content}>
                {word.Word} → {word.Suggestion}
              </Text>
              <Text style={[styles.content, { color: '#718096' }]}>
                {word.WordTranslation}
              </Text>
            </View>
          ))}
        </View>
      </View>

      {/* 添加詳細字彙列表 */}
      <View style={styles.vocabularyList}>
        <Text style={styles.label}>已使用字彙列表</Text>
        {data.result.Basic_voc?.map((word, index) => (
          <View key={index} style={styles.wordItem}>
            <Text style={styles.wordText}>{word.word}</Text>
            <Text style={styles.cefrLevel}>{word.CEFR}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

// 升級建議渲染函數
const renderUpgradedEssay = (data: ReportGeneratorProps['data']) => {
  return (
    <View style={styles.upgradeBox}>
      <Text style={styles.sectionTitle}>作文升級建議</Text>

      {/* 原文與升級版照 */}
      <View style={styles.comparisonGrid}>
        <View style={styles.comparisonColumn}>
          <Text style={styles.columnHeader}>原文</Text>
          <Text style={styles.content}>{data.content}</Text>
        </View>
        <View style={styles.comparisonColumn}>
          <Text style={styles.columnHeader}>升級版本</Text>
          <Text style={styles.content}>
            {data.result.upgrade_essay_lang1?.EnhancedEssay || '無升級建議'}
          </Text>
        </View>
      </View>

      {/* 改進建議 */}
      <View style={styles.improveBox}>
        <Text style={styles.label}>改進重點</Text>
        <View style={styles.wordList}>
          {data.result.Suggestion_lang1['Errors and Corrections'].map((error, index) => (
            <View key={index} style={styles.wordItem}>
              <Text style={[styles.content, { color: '#e53e3e' }]}>
                {error['Original Sentence']}
              </Text>
              <Text style={[styles.content, { color: '#38a169' }]}>
                → {error['Corrected Sentence']}
              </Text>
              <Text style={[styles.content, { color: '#718096', fontSize: 10 }]}>
                {error['Reason for Modification']}
              </Text>
            </View>
          ))}
        </View>
      </View>

      {/* 進階片語建議 */}
      <View style={styles.analysisItem}>
        <Text style={styles.label}>建議使用的進階片語</Text>
        {data.result.Vocabulary_Upgrade_lang1?.AdvancedPhrases?.map((phrase, index) => (
          <View key={index} style={styles.phraseCard}>
            <Text style={[styles.content, { fontWeight: 'bold' }]}>
              {phrase.phrase}
            </Text>
            <Text style={styles.content}>
              例句：{phrase.example}
            </Text>
            <Text style={[styles.content, { color: '#718096' }]}>
              {phrase.translation}
            </Text>
          </View>
        ))}
      </View>

      {/* 句型優化建議 */}
      <View style={styles.analysisItem}>
        <Text style={styles.label}>句型優化建議</Text>
        <Text style={styles.content}>
          {data.result.GrammarStructureScoreAgent_lang1.Review}
        </Text>
        <Text style={[styles.content, { color: '#4a5568', marginTop: 4 }]}>
          建議：運用更多複雜句型關係子、分詞句等以提升文章的流暢度和學術性。
        </Text>
      </View>
    </View>
  );
};

// Add calculateVocabStats function before renderStatistics
const calculateVocabStats = (data: ReportGeneratorProps['data']) => {
  const basicVocab = data.result.Basic_voc || [];
  const improveVocab = data.result.Vocabulary_Upgrade_lang1?.ImproveVocabulary || [];
  const betterVocab = data.result.Vocabulary_Upgrade_lang1?.BetterVocabulary || [];

  return {
    mastered: basicVocab.length,
    toLearn: improveVocab.length + betterVocab.length,
    total: basicVocab.length + improveVocab.length + betterVocab.length
  };
};

// Add renderCEFRAnalysis function before ReportDocument
const renderCEFRAnalysis = (data: ReportGeneratorProps['data']) => {
  return (
    <View style={styles.cefrSection}>
      <Text style={styles.sectionTitle}>CEFR 評估分析</Text>
      
      {/* CEFR 等級 */}
      <View style={styles.observationBox}>
        <Text style={styles.label}>CEFR 等級</Text>
        <Text style={styles.evaluationText}>Level: {data.result.article_cerf_lang1?.CEFRLevel}</Text>
      </View>

      {/* 詞彙範疇 */}
      <View style={styles.observationBox}>
        <Text style={styles.label}>詞彙範疇 (Vocabulary Range)</Text>
        <Text style={styles.evaluationText}>
          English: {data.result.article_cerf_lang1?.VocabularyRange}
          Chinese: {data.result.article_cerf_lang2?.VocabularyRange}
        </Text>
        {data.result.article_cerf_lang2 && (
          <Text style={styles.evaluationText}>
            Chinese: {data.result.article_cerf_lang2.VocabularyRange}
          </Text>
        )}
      </View>

      {/* 句法結構 */}
      <View style={styles.observationBox}>
        <Text style={styles.label}>句法結構 (Syntax Structure)</Text>
        <Text style={styles.evaluationText}>
          English: {data.result.article_cerf_lang1?.SyntaxStructure}
        </Text>
        {data.result.article_cerf_lang2 && (
          <Text style={styles.evaluationText}>
            Chinese: {data.result.article_cerf_lang2.SyntaxStructure}
          </Text>
        )}
      </View>

      {/* 內容主題 */}
      <View style={styles.observationBox}>
        <Text style={styles.label}>內容主題 (Content Theme)</Text>
        <Text style={styles.evaluationText}>
          English: {data.result.article_cerf_lang1?.ContentTheme}
        </Text>
        {data.result.article_cerf_lang2 && (
          <Text style={styles.evaluationText}>
            Chinese: {data.result.article_cerf_lang2.ContentTheme}
          </Text>
        )}
      </View>

      {/* 意圖與表達 */}
      <View style={styles.observationBox}>
        <Text style={styles.label}>意圖與表達 (Intent and Expression)</Text>
        <Text style={styles.evaluationText}>
          English: {data.result.article_cerf_lang1?.IntentandExpression}
        </Text>
        {data.result.article_cerf_lang2 && (
          <Text style={styles.evaluationText}>
            Chinese: {data.result.article_cerf_lang2.IntentandExpression}
          </Text>
        )}
      </View>
    </View>
  );
};

// 修改 formatLongText 函數
const formatLongText = (text: string | undefined, maxLength: number = 40): string[] => {
  if (!text) return ['無資料'];
  
  try {
    // 限制總文本長度
    const truncatedText = text.slice(0, 5000); // 設置一個合理的最大長度限制
    const segments: string[] = [];
    let currentSegment = '';
    
    const chars = truncatedText.split('');
    for (let i = 0; i < chars.length; i++) {
      // 跳過特殊字符
      if (chars[i].charCodeAt(0) === 0xFFFD) continue;
      
      currentSegment += chars[i];
      
      if (
        currentSegment.length >= maxLength ||
        '。！？'.includes(chars[i]) ||
        i === chars.length - 1
      ) {
        if (currentSegment.trim().length > 0) {
          segments.push(currentSegment.trim());
        }
        currentSegment = '';
      }
    }
    
    // 限制段落數量
    return segments.slice(0, 100); // 限制最大段落數
  } catch (error) {
    console.error('Error in formatLongText:', error);
    return ['文本處理錯誤'];
  }
};

// 修改 renderLongText 函數
const renderLongText = (text: string | undefined) => {
  try {
    if (typeof text !== 'string') {
      return <Text style={styles.textSegment}>無資料</Text>;
    }

    // 限制文本長度
    const limitedText = text.slice(0, 1000);
    const segments = formatLongText(limitedText);

    if (!Array.isArray(segments) || segments.length === 0) {
      return <Text style={styles.textSegment}>無資料</Text>;
    }

    return (
      <View style={styles.textContainer}>
        {segments.slice(0, 50).map((segment: string, index: number) => {
          if (!segment || typeof segment !== 'string') return null;
          return (
            <Text key={index} style={styles.textSegment}>
              {segment.slice(0, 100)} {/* 限制每個段落的長度 */}
            </Text>
          );
        })}
      </View>
    );
  } catch (error) {
    console.error('Error in renderLongText:', error);
    return <Text style={styles.textSegment}>文本顯示錯誤</Text>;
  }
};

const renderScore = (data: ReportGeneratorProps['data']) => {
  const formatScore = (score: string) => (parseFloat(score) / 10).toFixed(1);
  const calculateTotalScore = () => {
    const contentScore = parseFloat(data.result.ContentScoreAgen_lang1.ContentScore);
    const organizationScore = parseFloat(data.result.OrganizationScoreAgent_lang1.Score);
    const vocabularyScore = parseFloat(data.result.VocabularySpellingScoreAgent_lang1.Score);
    const grammarScore = parseFloat(data.result.GrammarStructureScoreAgent_lang1.Score);
    
    const totalScore = (contentScore + organizationScore + vocabularyScore + grammarScore) / 10;
    return (totalScore).toFixed(1);
  };

  const scoreCategories = [
    {
      title: '內容評分',
      score: data.result.ContentScoreAgen_lang1.ContentScore,
      review: data.result.ContentScoreAgen_lang1.Review,
      translatedReview: data.result.ContentScoreAgen_lang2.Review_translated
    },
    {
      title: '文法結構評分',
      score: data.result.GrammarStructureScoreAgent_lang1.Score,
      review: data.result.GrammarStructureScoreAgent_lang1.Review,
      translatedReview: data.result.GrammarStructureScoreAgent_lang2?.Review || ''
    },
    {
      title: '組織構評分',
      score: data.result.OrganizationScoreAgent_lang1.Score,
      review: data.result.OrganizationScoreAgent_lang1.Review,
      translatedReview: data.result.OrganizationScoreAgent_lang2?.Review || ''
    },
    {
      title: '詞彙拼寫評分',
      score: data.result.VocabularySpellingScoreAgent_lang1.Score,
      review: data.result.VocabularySpellingScoreAgent_lang1.Review,
      translatedReview: data.result.VocabularySpellingScoreAgent_lang2?.Review || ''
    }
  ];

  // 將評分內容分組，但不使用個 Page 組件
  return (
    <View style={styles.scoreBox}>
      {/* 第一部分：總分和內容評分 */}
      <View style={[styles.section, { marginBottom: 30 }]}>
        {/* 總分區塊 */}
        <View style={[styles.observationBox, { marginBottom: 20 }]}>
          <Text style={styles.sectionTitle}>總分</Text>
          <Text style={[styles.statsValue, { fontSize: 24, color: '#2b6cb0' }]}>
            {calculateTotalScore()}/20
          </Text>
          
          {/* 總體評價 */}
          <View style={styles.evaluationBox}>
            <Text style={[styles.content, { marginBottom: 8 }]}>
              {data.result.Suggestion_lang1['Overall Evaluation'] || ''}
            </Text>
            <Text style={[styles.content, { color: '#4a5568' }]}>
              {renderLongText(data.result.article_Accuracy_lang2?.['Summary (translated)'])}
            </Text>
          </View>
        </View>

        {/* 內容評分 */}
        <View style={[styles.observationBox, { marginBottom: 15 }]}>
          <View style={styles.scoreHeader}>
            <Text style={styles.scoreTitle}>{scoreCategories[0].title}</Text>
            <Text style={[styles.statsValue, { color: '#2b6cb0' }]}>
              {formatScore(scoreCategories[0].score)}/5
            </Text>
          </View>
          <View style={styles.reviewBox}>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>英文評語</Text>
              <Text style={styles.content}>{scoreCategories[0].review}</Text>
            </View>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>中文評語</Text>
              {renderLongText(scoreCategories[0].translatedReview)}
            </View>
          </View>
        </View>
      </View>

      {/* 分頁符 */}
      <Text break />

      {/* 第二部分：文法和組織構評分 */}
      <View style={[styles.section, { marginBottom: 30 }]}>
        {/* 文法結構評分 */}
        <View style={[styles.observationBox, { marginBottom: 15 }]}>
          <View style={styles.scoreHeader}>
            <Text style={styles.scoreTitle}>{scoreCategories[1].title}</Text>
            <Text style={[styles.statsValue, { color: '#2b6cb0' }]}>
              {formatScore(scoreCategories[1].score)}/5
            </Text>
          </View>
          <View style={styles.reviewBox}>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>英文評語</Text>
              <Text style={styles.content}>{scoreCategories[1].review}</Text>
            </View>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>中文評語</Text>
              {renderLongText(scoreCategories[1].translatedReview)}
            </View>
          </View>
        </View>

        {/* 組織結構評分 */}
        <View style={[styles.observationBox, { marginBottom: 15 }]}>
          <View style={styles.scoreHeader}>
            <Text style={styles.scoreTitle}>{scoreCategories[2].title}</Text>
            <Text style={[styles.statsValue, { color: '#2b6cb0' }]}>
              {formatScore(scoreCategories[2].score)}/5
            </Text>
          </View>
          <View style={styles.reviewBox}>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>中文評語</Text>
              <Text style={styles.content}>{scoreCategories[2].review}</Text>
            </View>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>中文評語</Text>
              {renderLongText(scoreCategories[2].translatedReview)}
            </View>
          </View>
        </View>
      </View>

      {/* 分頁符 */}
      <Text break />

      {/* 第三部分：詞彙拼寫評分 */}
      <View style={[styles.section, { marginBottom: 30 }]}>
        <View style={[styles.observationBox, { marginBottom: 15 }]}>
          <View style={styles.scoreHeader}>
            <Text style={styles.scoreTitle}>{scoreCategories[3].title}</Text>
            <Text style={[styles.statsValue, { color: '#2b6cb0' }]}>
              {formatScore(scoreCategories[3].score)}/5
            </Text>
          </View>
          <View style={styles.reviewBox}>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>英文評語</Text>
              <Text style={styles.content}>{scoreCategories[3].review}</Text>
            </View>
            <View style={styles.reviewSection}>
              <Text style={styles.reviewLabel}>中文評語</Text>
              {renderLongText(scoreCategories[3].translatedReview)}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

// 修改 renderCEFRResult 中的語言與文法和邏輯謬誤部分
const renderCEFRResult = (data: ReportGeneratorProps['data']) => {
  // 獲取最高風格分析
  const getHighestStyle = (styles?: Array<{ Style: string; Probability: number }>) => {
    if (!styles || !Array.isArray(styles) || styles.length === 0) return null;
    return styles.reduce((prev, current) => 
      (current.Probability > (prev?.Probability || 0)) ? current : prev
    , styles[0]);
  };

  const highestStyle = getHighestStyle(data.result.article_Style_lang1?.Styles);
  const highestTranslatedStyle = getHighestStyle(data.result.article_Style_lang2?.Styles);

  // 定義精確度分析項目
  const accuracyItems = [
    {
      title: '語言與文法',
      english: data.result.article_Accuracy_lang1?.['Language and Grammar'],
      chinese: data.result.article_Accuracy_lang2?.['Language and Grammar (translated)']
    },
    {
      title: '邏輯謬誤',
      english: data.result.article_Accuracy_lang1?.['Logical Flaws'],
      chinese: data.result.article_Accuracy_lang2?.['Logical Flaws (translated)']
    }
  ];

  return (
    <View style={styles.cefrSection}>
      
      {/* 語言與文法 */}
      <View style={styles.observationBox}>
        <Text style={styles.sectionTitle}>語言與文法</Text>
        <View style={styles.languageSection}>
          <Text style={styles.languageLabel}>English</Text>
          <Text style={styles.analysisContent}>
            {data.result.article_Accuracy_lang1?.['Language and Grammar'] || '無資料'}
          </Text>
        </View>
        <View style={styles.languageSection}>
          <Text style={styles.languageLabel}>中文</Text>
          {renderLongText(data.result.article_Accuracy_lang2?.['Language and Grammar (translated)'])}
        </View>
      </View>

      {/* 邏輯謬誤 */}
      <View style={styles.observationBox}>
        <Text style={styles.sectionTitle}>邏輯謬誤</Text>
        <View style={styles.languageSection}>
          <Text style={styles.languageLabel}>English</Text>
          <Text style={styles.analysisContent}>
            {data.result.article_Accuracy_lang1?.['Logical Flaws'] || '無資料'}
          </Text>
        </View>
        <View style={styles.languageSection}>
          <Text style={styles.languageLabel}>中文</Text>
          {renderLongText(data.result.article_Accuracy_lang2?.['Logical Flaws (translated)'])}
        </View>
      </View>

      {/* 文章風格分析 */}
      <View style={styles.observationBox}>
        <Text style={styles.sectionTitle}>文章風格分析</Text>
        <View style={styles.analysisGrid}>
          <View style={styles.analysisItem}>
            <Text style={styles.label}>English:</Text>
            <Text style={styles.content}>
              文章風格: {highestStyle?.Style || '無資料'}
            </Text>
            <Text style={styles.content}>
              符合度: {highestStyle?.Probability || '無資料'}
            </Text>
          </View>
          <View style={styles.analysisItem}>
            <Text style={styles.label}>中文:</Text>
            <Text style={styles.content}>
              文章風格: {highestTranslatedStyle?.Style || '無資料'}
            </Text>
            <Text style={styles.content}>
              符合度: {highestTranslatedStyle?.Probability || '無資料'}
            </Text>
          </View>
        </View>
      </View>

      {/* 文章分析結論 - 使用分頁樣式 */}
      <View style={styles.pageBreak}>
        <Text style={styles.sectionTitle}>文章分析結論</Text>
        <View style={styles.observationBox}>
          <View style={styles.languageSection}>
            <Text style={styles.languageLabel}>English</Text>
            <Text style={styles.analysisContent}>
              {data.result.article_Accuracy_lang1?.Summary || '無資料'}
            </Text>
            <Text style={styles.evaluationText}>
              {data.result.article_cerf_lang1?.Conclusion}
            </Text>
          </View>
          <View style={styles.languageSection}>
            <Text style={styles.languageLabel}>中文</Text>
            <Text style={styles.evaluationText}>
              {renderLongText(data.result.article_Accuracy_lang2?.['Summary (translated)'])}
            </Text>
            <Text style={styles.evaluationText}>
              {renderLongText(data.result.article_cerf_lang2?.Conclusion)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};


const renderErrorAnalysis = (data: ReportGeneratorProps['data']) => {
  // 檢查錯誤是否為拼寫錯誤
  const isSpellingError = (error: string) => {
    return data.result.Suggestion_lang1['Misspelled Words']?.includes(
      error.split(' ')[0] // 簡單檢查第一個單詞
    );
  };

  type ErrorType = {
    'Original Sentence': string;
    'Corrected Sentence': string;
    'Reason for Modification': string;
  };

  // 將錯誤按類型分類
  const grammarErrors = data.result.Suggestion_lang1['Errors and Corrections']?.filter(
    (error: ErrorType) => !isSpellingError(error['Original Sentence'])
  ) || [];
  
  const spellingErrors = data.result.Suggestion_lang1['Errors and Corrections']?.filter(
    (error: ErrorType) => isSpellingError(error['Original Sentence'])
  ) || [];

  const findTranslatedError = (originalError: ErrorType) => {
    return data.result.Suggestion_lang2?.['Errors and Corrections (translated)']?.find(
      (e: ErrorType) => e['Original Sentence'] === originalError['Original Sentence']
    );
  };

  return (
    <View style={styles.analysisBox}>
      <Text style={styles.sectionTitle}>錯誤分析</Text>

      {/* 文法錯誤部分 */}
      <View style={styles.observationBox}>
        <Text style={[styles.label, { marginBottom: 10 }]}>文法錯誤</Text>
        {grammarErrors.length > 0 ? (
          grammarErrors.map((error, index) => (
            <View key={index} style={[styles.errorItem, { marginBottom: 15 }]}>
              <View style={{ marginBottom: 5 }}>
                <Text style={[styles.content, { color: '#e53e3e', marginBottom: 4 }]}>
                  {error['Original Sentence']}
                </Text>
                <Text style={[styles.content, { color: '#38a169' }]}>
                  {error['Corrected Sentence']}
                </Text>
              </View>
              <Text style={[styles.content, { fontSize: 10 }]}>
                {error['Reason for Modification']}
              </Text>
              {findTranslatedError(error)?.['Reason for Modification'] && (
                <Text style={[styles.content, { fontSize: 10, color: '#4a5568' }]}>
                  {findTranslatedError(error)?.['Reason for Modification']}
                </Text>
              )}
            </View>
          ))
        ) : (
          <Text style={[styles.content, { color: '#718096' }]}>
            沒有發現文法錯誤
          </Text>
        )}
      </View>

      {/* 拼寫錯誤部分 */}
      <View style={[styles.observationBox, { marginTop: 15 }]}>
        <Text style={[styles.label, { marginBottom: 10 }]}>拼寫錯誤</Text>
        {spellingErrors.length > 0 ? (
          spellingErrors.map((error, index) => (
            <View key={index} style={[styles.errorItem, { marginBottom: 15 }]}>
              <View style={{ marginBottom: 5 }}>
                <Text style={[styles.content, { color: '#e53e3e', marginBottom: 4 }]}>
                  {error['Original Sentence']}
                </Text>
                <Text style={[styles.content, { color: '#38a169' }]}>
                  {error['Corrected Sentence']}
                </Text>
              </View>
              <Text style={[styles.content, { fontSize: 10 }]}>
                {error['Reason for Modification']}
              </Text>
              {findTranslatedError(error)?.['Reason for Modification'] && (
                <Text style={[styles.content, { fontSize: 10, color: '#4a5568' }]}>
                  {findTranslatedError(error)?.['Reason for Modification']}
                </Text>
              )}
            </View>
          ))
        ) : (
          <Text style={[styles.content, { color: '#718096' }]}>
            沒有發現拼寫錯誤
          </Text>
        )}
      </View>
    </View>
  );
};

// 修改統計部分
const renderStatistics = (data: ReportGeneratorProps['data']) => {
  const vocabStats = calculateVocabStats(data);
  
  return (
    <View style={styles.statsBox}>
      <Text style={styles.sectionTitle}>數據統計摘要</Text>

      {/* 批改統計 */}
      <View style={styles.statsGrid}>
        <View style={styles.statsCard}>
          <Text style={styles.statsHeader}>本次作文字數</Text>
          <Text style={styles.statsValue}>{data.content.length}</Text>
        </View>
        <View style={styles.statsCard}>
          <Text style={styles.statsHeader}>CEFR 級</Text>
          <Text style={styles.statsValue}>
            {data.result.article_cerf_lang1?.CEFRLevel || 'N/A'}
          </Text>
        </View>
      </View>

      {/* 字彙統計 */}
      <View style={[styles.statsGrid, { marginTop: 12 }]}>
        <View style={styles.statsCard}>
          <Text style={styles.statsHeader}>已掌握詞彙</Text>
          <Text style={styles.statsValue}>{vocabStats.mastered}</Text>
          <Text style={[styles.content, { textAlign: 'center' }]}>個單字</Text>
        </View>
        <View style={styles.statsCard}>
          <Text style={styles.statsHeader}>建議學習詞彙</Text>
          <Text style={styles.statsValue}>{vocabStats.toLearn}</Text>
          <Text style={[styles.content, { textAlign: 'center' }]}>個單字</Text>
        </View>
      </View>

      {/* 進步分析 */}
      <View style={styles.progressBox}>
        <Text style={styles.label}>進步重點</Text>
        <View style={styles.analysisGrid}>
          <View style={styles.analysisItem}>
            <Text style={styles.label}>文法結構</Text>
            <Text style={styles.content}>
              {data.result.GrammarStructureScoreAgent_lang1.Score}/5
            </Text>
          </View>
          <View style={styles.analysisItem}>
            <Text style={styles.label}>詞彙運用</Text>
            <Text style={styles.content}>
              {data.result.VocabularySpellingScoreAgent_lang1.Score}/5
            </Text>
          </View>
          <View style={styles.analysisItem}>
            <Text style={styles.label}>內容展</Text>
            <Text style={styles.content}>
              {data.result.ContentScoreAgen_lang1.ContentScore}/5
            </Text>
          </View>
          <View style={styles.analysisItem}>
            <Text style={styles.label}>組織結構</Text>
            <Text style={styles.content}>
              {data.result.OrganizationScoreAgent_lang1.Score}/5
            </Text>
          </View>
        </View>
      </View>

      {/* 建議 */}
      <View style={[styles.analysisItem, { marginTop: 12 }]}>
        <Text style={styles.label}>學習建議</Text>
        <Text style={styles.content}>
          1. 持續累積詞彙量，特別是 {data.result.article_cerf_lang1?.CEFRLevel} 等級的詞彙
        </Text>
        <Text style={styles.content}>
          2. 加強文法結構的準確性，特別是 {data.result.GrammarStructureScoreAgent_lang1.Review}
        </Text>
        <Text style={styles.content}>
          3. 注意作文組織構的完整性，並確保各段落的連貫性
        </Text>
      </View>
    </View>
  );
};

// 添加一個驗證函數
const validateData = (data: ReportGeneratorProps['data']): boolean => {
  try {
    if (!data || !data.result) return false;
    
    // 檢查必要的數據結
    const requiredFields = [
      'ContentScoreAgen_lang1',
      'GrammarStructureScoreAgent_lang1',
      'OrganizationScoreAgent_lang1',
      'VocabularySpellingScoreAgent_lang1',
      'article_cerf_lang1',
      'Suggestion_lang1'
    ] as const;

    return requiredFields.every((field) => 
      !!(data.result as any)[field]
    );

  } catch (error) {
    console.error('Data validation error:', error);
    return false;
  }
};

// 修改 ReportDocument 組件
const ReportDocument: React.FC<ReportGeneratorProps> = ({ data }) => {
  // 添加錯誤邊界
  try {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>作文批改報告</Text>
            <Text style={styles.content}>學生：{data.studentName}</Text>
            <Text style={styles.content}>教師：{data.teacherName}</Text>
            <Text style={styles.content}>日期：{new Date().toLocaleDateString()}</Text>
          </View>
          {renderObservationSection(data)}
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>評分</Text>
            {renderScore(data)}
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>CEFR 分析</Text>
            {renderCEFRAnalysis(data)}
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>精確度分析</Text>
            {renderCEFRResult(data)}
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>錯誤分析</Text>
            {renderErrorAnalysis(data)}
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>結構分析</Text>
            {renderStructureAnalysis(data)}
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>字彙分析</Text>
            {renderVocabularyAnalysis(data)}
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>改進建議</Text>
            {renderUpgradedEssay(data)}
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>統計摘要</Text>
            {renderStatistics(data)}
          </View>
        </Page>
      </Document>
    );
  } catch (error) {
    console.error('Error in ReportDocument:', error);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>錯誤報告</Text>
            <Text style={styles.content}>生成報告時發生錯誤</Text>
            <Text style={styles.content}>{error instanceof Error ? error.message : '未知錯誤'}</Text>
          </View>
        </Page>
      </Document>
    );
  }
};

// 修改 generateReport 函數
export const generateReport = async (data: ReportGeneratorProps['data']) => {
  try {
    // 基本數據證
    if (!data || !data.result) {
      throw new Error('無效的數據');
    }

    // 創建安全的數據副本
    const safeData = {
      ...data,
      content: data.content?.slice(0, 10000) || '',
      studentName: data.studentName?.slice(0, 100) || '未知學生',
      teacherName: data.teacherName?.slice(0, 100) || '未知教師',
      result: {
        ...data.result,
        Suggestion_lang1: {
          ...data.result.Suggestion_lang1,
          'Overall Evaluation': data.result.Suggestion_lang1?.['Overall Evaluation']?.slice(0, 1000) || ''
        }
      }
    };

    // 生成 PDF
    const blob = await pdf(<ReportDocument data={safeData} />).toBlob();

    if (!blob) {
      throw new Error('PDF 生成失敗');
    }

    // 使用 URL.createObjectURL 而不是 data URL
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `批改報告_${safeData.studentName}_${new Date().toISOString().split('T')[0]}.pdf`;
    
    // 使用 setTimeout 延遲下載
    setTimeout(() => {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);

  } catch (error) {
    console.error('PDF 生成錯誤:', error);
    throw error;
  }
};

export default ReportDocument;