import React from 'react';
import { cn } from '../../lib/utils';

interface RadioGroupProps {
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
  required?: boolean;
  children: React.ReactNode;
}

interface RadioGroupItemProps {
  value: string;
  id: string;
  className?: string;
  children?: React.ReactNode;
}

const RadioGroupContext = React.createContext<{
  value: string;
  onValueChange: (value: string) => void;
  required?: boolean;
} | null>(null);

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ value, onValueChange, className, required, children }, ref) => {
    return (
      <RadioGroupContext.Provider value={{ value, onValueChange, required }}>
        <div ref={ref} className={cn('space-y-2', className)} role="radiogroup">
          {children}
        </div>
      </RadioGroupContext.Provider>
    );
  }
);
RadioGroup.displayName = 'RadioGroup';

export const RadioGroupItem = React.forwardRef<HTMLInputElement, RadioGroupItemProps>(
  ({ value, id, className, children }, ref) => {
    const context = React.useContext(RadioGroupContext);
    if (!context) {
      throw new Error('RadioGroupItem must be used within a RadioGroup');
    }

    const { value: groupValue, onValueChange, required } = context;

    return (
      <label
        htmlFor={id}
        className={cn(
          'flex items-center space-x-2 cursor-pointer',
          className
        )}
      >
        <input
          type="radio"
          ref={ref}
          id={id}
          value={value}
          checked={value === groupValue}
          onChange={() => onValueChange(value)}
          required={required}
          className="h-4 w-4 border border-gray-300 text-blue-600 focus:ring-2 focus:ring-blue-500"
        />
        {children}
      </label>
    );
  }
);
RadioGroupItem.displayName = 'RadioGroupItem'; 