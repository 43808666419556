import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';
import { Alert } from '../ui/alert';

interface TranslationData {
  chinese: string;
  english: string;
  isModified?: boolean;
}

interface ExamInfo {
  examType: string;
  year: number | null;
  round: number | null;
  mockType?: string;
}

interface TranslationFormProps {
  examInfo: ExamInfo;
  isPastExam: boolean;
  onNext: (translations: TranslationData[]) => void;
  onBack: () => void;
  savedData?: TranslationData[] | null;  // 添加 savedData prop
}

const TranslationForm: React.FC<TranslationFormProps> = ({
  examInfo,
  isPastExam,
  onNext,
  onBack,
  savedData
}) => {
  const [translations, setTranslations] = useState<TranslationData[]>(() => {
    // 如果有 savedData，使用 savedData 初始化
    if (savedData && savedData.length > 0) {
      // console.log('Initializing with savedData:', savedData);
      return savedData.map(t => ({
        chinese: t.chinese || '',
        english: t.english || '',
        isModified: false
      }));
    }
    // 如果沒有 savedData，則使用預設的空白題目
    return [
      { chinese: '', english: '', isModified: false },
      { chinese: '', english: '', isModified: false }
    ];
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [originalTranslations, setOriginalTranslations] = useState<TranslationData[]>([]);

  useEffect(() => {
    // console.log('TranslationForm useEffect - savedData:', savedData);
    // console.log('TranslationForm useEffect - isPastExam:', isPastExam);
    
    if (savedData && savedData.length > 0) {
      // 如果有 savedData，直接使用它
      setTranslations(savedData.map(t => ({
        chinese: t.chinese || '',
        english: t.english || '',
        isModified: false
      })));
      setOriginalTranslations(savedData);
    } else if (isPastExam && examInfo.examType && examInfo.year && examInfo.round) {
      // 如果是考古題且沒有 savedData，則獲取題目
      fetchTranslations();
    }
  }, [savedData, isPastExam, examInfo.examType, examInfo.year, examInfo.round]);

  const fetchTranslations = async () => {
    setIsLoading(true);
    setError('');
    try {
      let examTypeParam = examInfo.examType;
      
      // 只有在是模考時才特別處理 examType
      if (examInfo.examType === 'mock' && examInfo.mockType) {
        examTypeParam = examInfo.mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考';
      }
      
      // console.log('Fetching translations with params:', {
      //   examType: examTypeParam,
      //   year: examInfo.year,
      //   round: examInfo.round
      // });

      const response = await fetch(
        `/api/test/translations/${encodeURIComponent(examTypeParam)}/${examInfo.year}/${examInfo.round}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch translations');
      }

      const data = await response.json();
      if (data.success && data.translations.length > 0) {
        const formattedTranslations = data.translations.map((t: any) => ({
          chinese: t.question || t.chinese || '',
          english: t.answer || t.english || '',
          isModified: false
        }));
        // console.log('Fetched translations:', formattedTranslations);
        setTranslations(formattedTranslations);
        setOriginalTranslations(formattedTranslations);
      }
    } catch (error) {
      console.error('Failed to fetch translations:', error);
      setError('獲取翻譯題目失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTranslationChange = (index: number, field: 'chinese' | 'english', value: string) => {
    const newTranslations = [...translations];
    newTranslations[index] = {
      ...newTranslations[index],
      [field]: value,
      isModified: value !== originalTranslations[index]?.[field]
    };
    setTranslations(newTranslations);
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-40">載入中...</div>;
  }

  // console.log('TranslationForm render - current translations:', translations);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">翻譯題目</h2>
      
      {error && (
        <Alert variant="destructive">
          {error}
        </Alert>
      )}

      {translations.map((translation, index) => (
        <Card key={index}>
          <CardContent className="space-y-4 pt-6">
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <Label htmlFor={`chinese-${index}`}>中文 {index + 1}</Label>
                {translation.isModified && (
                  <span className="text-xs text-blue-500">已修改</span>
                )}
              </div>
              <Textarea
                id={`chinese-${index}`}
                value={translation.chinese}
                onChange={(e) => handleTranslationChange(index, 'chinese', e.target.value)}
                placeholder="請輸入中文句子"
                className="min-h-[100px]"
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <Label htmlFor={`english-${index}`}>英文 {index + 1}</Label>
                {isPastExam && originalTranslations[index] && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      if (originalTranslations[index]) {
                        handleTranslationChange(index, 'chinese', originalTranslations[index].chinese);
                        handleTranslationChange(index, 'english', originalTranslations[index].english);
                      }
                    }}
                  >
                    恢復原題
                  </Button>
                )}
              </div>
              <Textarea
                id={`english-${index}`}
                value={translation.english}
                onChange={(e) => handleTranslationChange(index, 'english', e.target.value)}
                placeholder="請輸入英文參考答案"
                className="min-h-[100px]"
              />
            </div>
          </CardContent>
        </Card>
      ))}
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>返回</Button>
        <Button onClick={() => onNext(translations)}>下一步</Button>
      </div>
    </div>
  );
};

export default TranslationForm;