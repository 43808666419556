import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import Header from '../../components/Header';
import TranslationResultTabs from './tabs/TranslationResultTabs';
import WritingResultTabs from './tabs/WritingResultTabs';
import ErrorDisplay from '../common/ErrorDisplay';
import LoadingSpinner from '../common/LoadingSpinner';
import { CombinedSubmissionData, TranslationResult } from '../types/correction';
import { generateReport } from './ReportGenerator';
import { Button } from '../ui/button';
import { Download } from 'lucide-react';
import { message } from 'antd';
import type { CorrectionResult as CorrectionResultType } from '../types/correction';

interface CorrectionResultProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface ApiResponse {
  success: boolean;
  submission: {
    id: string;
    title: string;
    createdAt: string;
    type: "translation-writing" | "combined";
    lang: string;
    studentName: string;
    className: string;
    teacherName: string;
    result: {
      writing: {
        content: string;
        result: any;
      } | null;
      translation: any | null;
    };
  };
}

const CorrectionResult: React.FC<CorrectionResultProps> = ({
  setIsAuthenticated,
  username
}) => {
  // console.log('CorrectionResult data:', { setIsAuthenticated, username });
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [submissionData, setSubmissionData] = useState<CombinedSubmissionData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [generatingReport, setGeneratingReport] = useState(false);

  useEffect(() => {
    const loadCorrectionData = async () => {
      if (!id) return;

      const token = localStorage.getItem('token');
      const userRole = localStorage.getItem('userRole');
      
      if (!token) {
        setIsAuthenticated(false);
        navigate('/', { replace: true });
        return;
      }

      try {
        setIsLoading(true);
        // 根據用戶角色使用不同的 API endpoint
        const endpoint = userRole === 'teacher' 
          ? `/api/teacher/submission/${id}`  // 教師專用的 API
          : `/api/essay/submission/${id}`;   // 學生使用的原有 API
        
        const response = await fetch(endpoint, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 401) {
          setIsAuthenticated(false);
          navigate('/', { replace: true });
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch submission data');
        }

        const responseData: ApiResponse = await response.json();
        // console.log('API Response:', responseData);

        if (responseData.success && responseData.submission) {
          const submissionItem = responseData.submission;
          // console.log('Submission Item:', submissionItem);
          
          // 檢查所需的關鍵字段
          if (!submissionItem.id || !submissionItem.type || !submissionItem.result) {
            console.error('Missing required fields:', {
              hasId: !!submissionItem.id,
              hasType: !!submissionItem.type,
              hasResult: !!submissionItem.result
            });
            throw new Error('數據缺少必要字段');
          }

          const processedData: CombinedSubmissionData = {
            id: submissionItem.id,
            title: submissionItem.title || '',
            createdAt: submissionItem.createdAt || '',
            type: submissionItem.type,
            lang: submissionItem.lang || '',
            studentName: submissionItem.studentName || '未提供',
            className: submissionItem.className || '未提供',
            teacherName: submissionItem.teacherName || '未提供',
            result: {
              writing: submissionItem.result.writing || null,
              translation: submissionItem.result.translation || null
            }
          };

          // console.log('Processed Data:', processedData);
          setSubmissionData(processedData);
          
        } else {
          console.error('Invalid response format:', responseData);
          throw new Error('無效的數據格式');
        }
      } catch (err) {
        console.error('Error in loadCorrectionData:', err);
        setError(err instanceof Error ? err.message : '加載批改結果失敗');
      } finally {
        setIsLoading(false);
      }
    };

    loadCorrectionData();
  }, [id, setIsAuthenticated, navigate]);

  const handleDownloadReport = async () => {
    if (!submissionData?.result.writing?.result) {
      message.error('無批改結果');
      return;
    }

    const writingData = submissionData.result.writing;

    try {
      setGeneratingReport(true);
      const contentImages = writingData?.contentImages?.map(img => img.data) || [];
      
      // 檢查必要的字段是否存在
      if (!writingData.result.article_FIX || !writingData.result.article_cerf_lang1) {
        message.error('批改結果格式不完整');
        return;
      }

      // 確保所有必要的字段都存在
      const result: CorrectionResultType = {
        ...writingData.result,
        article_cerf_lang1: {
          CEFRLevel: writingData.result.article_cerf_lang1?.CEFRLevel || '',
          VocabularyRange: writingData.result.article_cerf_lang1?.VocabularyRange || '',
          SyntaxStructure: writingData.result.article_cerf_lang1?.SyntaxStructure || '',
          ContentTheme: writingData.result.article_cerf_lang1?.ContentTheme || '',
          IntentandExpression: writingData.result.article_cerf_lang1?.IntentandExpression || '',
          Conclusion: writingData.result.article_cerf_lang1?.Conclusion || ''
        },
        article_cerf_lang2: writingData.result.article_cerf_lang2 ? {
          CEFRLevel: writingData.result.article_cerf_lang2.CEFRLevel || '',
          VocabularyRange: writingData.result.article_cerf_lang2.VocabularyRange || '',
          SyntaxStructure: writingData.result.article_cerf_lang2.SyntaxStructure || '',
          ContentTheme: writingData.result.article_cerf_lang2.ContentTheme || '',
          IntentandExpression: writingData.result.article_cerf_lang2.IntentandExpression || '',
          Conclusion: writingData.result.article_cerf_lang2.Conclusion || ''
        } : undefined,
        article_Accuracy_lang1: {
          'Language and Grammar': writingData.result.article_Accuracy_lang1?.['Language and Grammar'] || '',
          'Logical Flaws': writingData.result.article_Accuracy_lang1?.['Logical Flaws'] || '',
          'Summary': writingData.result.article_Accuracy_lang1?.Summary || ''
        },
        Suggestion_lang1: {
          'Overall Evaluation': writingData.result.Suggestion_lang1?.['Overall Evaluation'] || '',
          'Errors and Corrections': writingData.result.Suggestion_lang1?.['Errors and Corrections'] || [],
          'Misspelled Words': writingData.result.Suggestion_lang1?.['Misspelled Words'] || []
        },
        article_FIX: writingData.result.article_FIX,
        Basic_voc: writingData.result.Basic_voc || [],
        Vocabulary_Upgrade_lang1: {
          ImproveVocabulary: writingData.result.Vocabulary_Upgrade_lang1?.ImproveVocabulary || [],
          BetterVocabulary: writingData.result.Vocabulary_Upgrade_lang1?.BetterVocabulary || [],
          AdvancedPhrases: (writingData.result.Vocabulary_Upgrade_lang1?.AdvancedPhrases || []).map(phrase => ({
            phrase: phrase.OriginalExpression || '',
            example: phrase.PhraseExample || '',
            translation: phrase.PhraseTranslation || ''
          }))
        },
        StructureDivisionAgent_lang1: {
          Introduction: writingData.result.StructureDivisionAgent_lang1?.Introduction || {
            Hook: '',
            'Background Information': '',
            'Thesis Statement': '',
            'Missing Elements': []
          },
          'Body Paragraph': writingData.result.StructureDivisionAgent_lang1?.['Body Paragraph'] || {
            'Topic Sentence': '',
            'Supporting Details': '',
            'Explanation and Analysis': '',
            'Transition': '',
            'Missing Elements': []
          },
          Conclusion: writingData.result.StructureDivisionAgent_lang1?.Conclusion || {
            'Restate the Thesis': '',
            'Summary': '',
            'Final Thought': '',
            'Missing Elements': []
          }
        },
        upgrade_essay_lang1: {
          EnhancedEssay: writingData.result.upgrade_essay_lang1?.EnhancedEssay || ''
        },
        ContentScoreAgen_lang1: {
          ContentScore: writingData.result.ContentScoreAgen_lang1?.ContentScore || '',
          Review: writingData.result.ContentScoreAgen_lang1?.Review || ''
        },
        ContentScoreAgen_lang2: {
          ContentScore_trans: writingData.result.ContentScoreAgen_lang2?.ContentScore_trans || '',
          Review_translated: writingData.result.ContentScoreAgen_lang2?.Review_translated || ''
        }
      };
      
      await generateReport({
        studentName: submissionData.studentName,
        teacherName: submissionData.teacherName,
        content: writingData?.content || '',
        contentImages,
        result
      });
      
      message.success('報告生成成功');
    } catch (error) {
      console.error('Error generating report:', error);
      message.error('生成報告失敗：' + (error instanceof Error ? error.message : '未知錯誤'));
    } finally {
      setGeneratingReport(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="container mx-auto p-4">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="container mx-auto p-4">
          <ErrorDisplay message={error} />
          <div className="mt-4 text-sm text-gray-600">
            請刷新頁面重試，如果問題持續存在，請聯繫系統管理員。
          </div>
        </div>
      </div>
    );
  }

  if (!submissionData) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="container mx-auto p-4">
          <ErrorDisplay message="無法加載批改結果" />
        </div>
      </div>
    );
  }

  // 根據 submissionData 中的內容決定要顯示哪些標籤頁
  const hasWriting = !!submissionData.result.writing;
  const hasTranslation = !!submissionData.result.translation;
  const initialTab = hasTranslation ? "translation" : "writing";
  
  return (
    <div className="min-h-screen bg-gray-50">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <div className="container mx-auto p-4 space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">{submissionData.title}</h2>
          <Button 
            onClick={handleDownloadReport}
            disabled={generatingReport}
            className="mb-4"
          >
            <Download className="mr-2 h-4 w-4" />
            {generatingReport ? '生成報告中...' : '下載報告'}
          </Button>
        </div>
        {hasWriting || hasTranslation ? (
          <Tabs defaultValue={initialTab} className="space-y-6">
            <TabsList className="bg-white p-1">
              {hasTranslation && <TabsTrigger value="translation">翻譯批改</TabsTrigger>}
              {hasWriting && <TabsTrigger value="writing">作文批改</TabsTrigger>}
            </TabsList>

            {hasTranslation && (
              <TabsContent value="translation">
                <TranslationResultTabs data={submissionData.result.translation!} />
              </TabsContent>
            )}

            {hasWriting && (
              <TabsContent value="writing">
                <WritingResultTabs data={submissionData.result.writing!} />
              </TabsContent>
            )}
          </Tabs>
        ) : (
          <div className="text-center text-gray-500">
            無可用的批改結果
          </div>
        )}
      </div>
    </div>
  );
};

export default CorrectionResult;