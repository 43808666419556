import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Textarea } from '../ui/textarea';
import { Button } from '../ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Alert, AlertDescription } from '../ui/alert';
import { Camera, Loader2, ChevronLeft, ChevronRight, X } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '../ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../ui/popover';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../ui/tabs';
import {
  SelectExamType,
  SelectYear,
  SelectRound
} from '../test-creation/SelectRound';
import SelectMockType from '../test-creation/SelectMockType';

// 基本圖片介面
interface FormImage {
  file: File;
  preview: string;
  ocrText: string;
  base64?: string;
}

// 翻譯題目介面
interface TranslationQuestion {
  questionId: string;
  question: string;
  standardAnswer: string;
  studentAnswer: string;
  images: FormImage[];
  currentImageIndex: number;
}

// 作文表單介面
interface WritingForm {
  description: string;
  imageDescription: string;
  content: string;
  descriptionImages: FormImage[];  // 作文描述的圖片
  contentImages: FormImage[];      // 學生作文答案的圖片
  currentDescriptionImageIndex: number;  // 作文描述的當前圖片索引
  currentContentImageIndex: number;      // 學生作文答案的當前圖片索引
  referenceImage: {
    file: File | null;
    preview: string;
  };
}

// 完整表單狀態介面
interface FormState {
  studentId: string;
  customStudent: string;
  useCustomStudent: boolean;
  title: string;
  correctionType: 'writing' | 'translation-writing';
  lang: string;
  writing: WritingForm;
  translations: TranslationQuestion[];
}

// 學生介面
interface Student {
  _id: string;
  username: string;
  realName: string;
  className?: string;
}

// 修改批改類型的類型定義
type LanguageType = '繁體中文' | '简体中文' | '日本語' | '한국어' | 'Tiếng Việt';
type CorrectionType = 'writing' | 'translation-writing';

// 添加的 interface
interface PastExamData {
  examType: string;
  mockType?: string;
  year: number | null;
  round: number | null;
}

const ManualCorrection: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [openCombobox, setOpenCombobox] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [students, setStudents] = useState<Student[]>([]);

  // 初始表單狀態
  const [form, setForm] = useState<FormState>({
    studentId: '',
    customStudent: '',
    useCustomStudent: false,
    title: '',
    correctionType: 'writing',
    lang: '繁體中文',
    writing: {
      description: '',
      imageDescription: '',
      content: '',
      descriptionImages: [],     // 初始化作文描述圖片數組
      contentImages: [],         // 初始化學生作文答案圖片數組
      currentDescriptionImageIndex: 0,
      currentContentImageIndex: 0,
      referenceImage: {
        file: null,
        preview: ''
      }
    },
    translations: [
      {
        questionId: '1',
        question: '',
        standardAnswer: '',
        studentAnswer: '',
        images: [],
        currentImageIndex: 0
      },
      {
        questionId: '2',
        question: '',
        standardAnswer: '',
        studentAnswer: '',
        images: [],
        currentImageIndex: 0
      }
    ]
  });

  // 在 ManualCorrection 組件中添加新的狀態
  const [showPastExamSelector, setShowPastExamSelector] = useState(false);
  const [pastExamData, setPastExamData] = useState<PastExamData>({
    examType: '',
    year: null,
    round: null
  });
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [availableRounds, setAvailableRounds] = useState<number[]>([]);
  const [currentPastExamStep, setCurrentPastExamStep] = useState<'examType' | 'mockType' | 'year' | 'round'>('examType');

  useEffect(() => {
    if (pastExamData.examType && currentPastExamStep === 'year') {
      fetchYears();
    }
  }, [pastExamData.examType, currentPastExamStep]);

  useEffect(() => {
    if (pastExamData.year && currentPastExamStep === 'round') {
      fetchRounds();
    }
  }, [pastExamData.year, currentPastExamStep]);

  // 修改获取年份的函数
  const fetchYears = async () => {
    try {
      let examTypeParam = pastExamData.examType;
      if (pastExamData.examType === 'mock' && pastExamData.mockType) {
        examTypeParam = pastExamData.mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考';
      }

      const response = await fetch(`/api/test/years/${encodeURIComponent(examTypeParam)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch years');

      const data = await response.json();
      if (data.success) {
        setAvailableYears(data.years);
      }
    } catch (error) {
      console.error('Error fetching years:', error);
      setError(error instanceof Error ? error.message : '獲取年份失敗');
    }
  };

  // 修改获取卷数的函数
  const fetchRounds = async () => {
    try {
      let examTypeParam = pastExamData.examType;
      if (pastExamData.examType === 'mock' && pastExamData.mockType) {
        examTypeParam = pastExamData.mockType === 'north' ? '臺北區學測模考' : '臺中區學測模考';
      }

      const response = await fetch(
        `/api/test/rounds/${encodeURIComponent(examTypeParam)}/${pastExamData.year}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch rounds');

      const data = await response.json();
      if (data.success) {
        setAvailableRounds(data.rounds);
      }
    } catch (error) {
      console.error('Error fetching rounds:', error);
      setError(error instanceof Error ? error.message : '獲取卷數失敗');
    }
  };

  const searchStudents = async (query: string) => {
    if (!query.trim()) {
      setStudents([]);
      return;
    }

    try {
      const response = await fetch(`/api/teacher/search-students?query=${encodeURIComponent(query)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('搜尋學生失敗');
      }
      
      const data = await response.json();
      if (data.success) {
        setStudents(data.students);
        // console.log('搜尋到的學生:', data.students);
      } else {
        throw new Error(data.message || '搜尋學生失敗');
      }
    } catch (error) {
      console.error('搜尋學生錯誤:', error);
      setError(error instanceof Error ? error.message : '搜尋學生敗');
      setStudents([]);
    }
  };

  // OCR 處理函數
  const processImageWithOCR = async (file: File): Promise<string> => {
    const base64Image = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1];
        resolve(base64String || '');
      };
      reader.readAsDataURL(file);
    });

    const response = await fetch('/api/ocr', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ image: base64Image }),
    });

    const data = await response.json();
    if (!response.ok || !data.success) {
      throw new Error(data.message || '圖片處理失敗');
    }

    return data.text;
  };

  // 添加清理圖片描述的函數
  const cleanImageDescription = (content: string): string => {
    if (content.includes("圖片描述：")) {
      content = content.split("圖片描述")[1];
    }
    content = content.replace(/\n/g, "").replace(/=/g, "");
    return content.replace(/[^\u4e00-\u9fa5，。]/g, "");
  };

  // 修改圖片上傳處理函數
  const handleWritingImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'description' | 'reference' | 'content') => {
    setIsLoading(true);
    setError(null);

    try {
      const file = e.target.files?.[0];
      if (!file) return;

      if (file.size > 5 * 1024 * 1024) {
        setError('圖片大小不能超過 5MB');
        e.target.value = '';
        return;
      }

      if (file.type === 'image/webp') {
        setError('不支援 WebP 格式圖片');
        e.target.value = '';
        return;
      }

      // 讀取圖片檔案並轉換為 Base64
      const base64 = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result?.toString() || '';
          resolve(base64String);
        };
        reader.readAsDataURL(file);
      });

      const preview = URL.createObjectURL(file);

      if (type === 'description') {
        try {
          const ocrText = await processImageWithOCR(file);
          setForm(prev => ({
            ...prev,
            writing: {
              ...prev.writing,
              description: prev.writing.description + (prev.writing.description ? '\n\n' : '') + ocrText,
              descriptionImages: [
                ...prev.writing.descriptionImages,
                { 
                  file, 
                  preview, 
                  ocrText,
                  base64  // 存入圖片的 Base64 資料
                }
              ],
              currentDescriptionImageIndex: prev.writing.descriptionImages.length
            }
          }));
          setSuccess('圖片上傳成功並完成文字辨識');
        } catch (error) {
          URL.revokeObjectURL(preview);
          throw error;
        }
      } else if (type === 'content') {
        try {
          const ocrText = await processImageWithOCR(file);
          setForm(prev => ({
            ...prev,
            writing: {
              ...prev.writing,
              content: prev.writing.content + (prev.writing.content ? '\n\n' : '') + ocrText,
              contentImages: [
                ...prev.writing.contentImages,
                { 
                  file, 
                  preview, 
                  ocrText,
                  base64  // 存入圖片的 Base64 資料
                }
              ],
              currentContentImageIndex: prev.writing.contentImages.length
            }
          }));
          setSuccess('圖片上傳成功並完成文字辨識');
        } catch (error) {
          URL.revokeObjectURL(preview);
          throw error;
        }
      } else if (type === 'reference') {
        try {
          const ocrText = await processImageWithOCR(file);
          setForm(prev => ({
            ...prev,
            writing: {
              ...prev.writing,
              imageDescription: cleanImageDescription(ocrText),
              referenceImage: {
                file,
                preview,
                base64  // 存入圖片的 Base64 資料
              }
            }
          }));
          setSuccess('參考圖片上傳成功');
        } catch (error) {
          URL.revokeObjectURL(preview);
          throw error;
        }
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '圖片上傳失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 修改翻譯圖片上傳處理
  const handleTranslationImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: 'question' | 'standardAnswer' | 'studentAnswer'
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const file = e.target.files?.[0];
      if (!file) return;

      // 讀取圖片檔案並轉換為 Base64
      const base64 = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result?.toString() || '';
          resolve(base64String);
        };
        reader.readAsDataURL(file);
      });

      const preview = URL.createObjectURL(file);
      const ocrText = await processImageWithOCR(file);

      setForm(prev => {
        const newTranslations = [...prev.translations];
        newTranslations[index] = {
          ...newTranslations[index],
          [field]: ocrText,
          images: [
            ...(newTranslations[index].images || []),
            { 
              file, 
              preview, 
              ocrText,
              base64  // 存入圖片的 Base64 資料
            }
          ]
        };
        return { ...prev, translations: newTranslations };
      });
      setSuccess('圖片上傳成功並完成文字辨識');
    } catch (error) {
      setError(error instanceof Error ? error.message : '圖片上傳失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 圖片導航理
  const handleImageNavigation = (
    direction: 'prev' | 'next',
    type: 'writing' | 'translation' | 'content',
    translationIndex?: number
  ) => {
    setForm(prev => {
      if (type === 'writing') {
        const newIndex = direction === 'next'
          ? Math.min(prev.writing.currentDescriptionImageIndex + 1, prev.writing.descriptionImages.length - 1)
          : Math.max(0, prev.writing.currentDescriptionImageIndex - 1);
        
        return {
          ...prev,
          writing: {
            ...prev.writing,
            currentDescriptionImageIndex: newIndex
          }
        };
      } else if (type === 'content') {
        const newIndex = direction === 'next'
          ? Math.min(prev.writing.currentContentImageIndex + 1, prev.writing.contentImages.length - 1)
          : Math.max(0, prev.writing.currentContentImageIndex - 1);
        
        return {
          ...prev,
          writing: {
            ...prev.writing,
            currentContentImageIndex: newIndex
          }
        };
      } else if (type === 'translation' && translationIndex !== undefined) {
        const translation = prev.translations[translationIndex];
        const imagesLength = translation.images?.length || 0;
        const newIndex = direction === 'next'
          ? Math.min(translation.currentImageIndex + 1, imagesLength - 1)
          : Math.max(0, translation.currentImageIndex - 1);

        const newTranslations = [...prev.translations];
        newTranslations[translationIndex] = {
          ...translation,
          currentImageIndex: newIndex
        };

        return {
          ...prev,
          translations: newTranslations
        };
      }
      return prev;
    });
  };

  // 清除圖片
  const handleClearImage = (
    type: 'writing' | 'translation' | 'content',
    index?: number,
    imageIndex?: number
  ) => {
    setForm(prev => {
      if (type === 'writing' && imageIndex !== undefined) {
        const newImages = [...prev.writing.descriptionImages];
        newImages.splice(imageIndex, 1);
        return {
          ...prev,
          writing: {
            ...prev.writing,
            descriptionImages: newImages,
            currentDescriptionImageIndex: Math.max(0, prev.writing.currentDescriptionImageIndex - 1)
          }
        };
      } else if (type === 'translation' && index !== undefined && imageIndex !== undefined) {
        const newTranslations = [...prev.translations];
        const newImages = [...newTranslations[index].images];
        newImages.splice(imageIndex, 1);
        newTranslations[index] = {
          ...newTranslations[index],
          images: newImages,
          currentImageIndex: Math.max(0, newTranslations[index].currentImageIndex - 1)
        };
        return {
          ...prev,
          translations: newTranslations
        };
      }
      return prev;
    });
  };

  // 表單提交處理
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      // 處理圖片數據的通用函數
      const processImage = async (image: FormImage) => ({
        data: image.base64 || await new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result?.toString() || '';
            resolve(base64String);
          };
          reader.readAsDataURL(image.file);
        }),
        contentType: image.file.type,
        ocrText: image.ocrText,
        timestamp: new Date()
      });

      // 處理參考圖片
      let referenceImageData = null;
      if (form.writing.referenceImage.file) {
        const file = form.writing.referenceImage.file;
        // 確保 file 不是 null
        if (file instanceof File) {  // 添加類型檢查
          const base64 = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result?.toString() || '';
              resolve(base64String);
            };
            reader.onerror = () => reject(new Error('Failed to read file'));
            reader.readAsDataURL(file);  // 使用已檢查的 file
          });

          referenceImageData = {
            data: base64,
            contentType: file.type,
            timestamp: new Date()
          };
        }
      }

      // 處理所有圖片
      const processedDescriptionImages = await Promise.all(
        form.writing.descriptionImages.map(processImage)
      );

      const processedContentImages = await Promise.all(
        form.writing.contentImages.map(processImage)
      );

      // 準備提交數據
      const submissionData = {
        studentId: form.useCustomStudent ? null : form.studentId,
        customStudent: form.useCustomStudent ? form.customStudent : null,
        translations: form.translations.map(t => ({
          ...t,
          images: t.images.map(img => ({
            data: img.base64,
            contentType: img.file.type,
            ocrText: img.ocrText
          }))
        })),
        writing: {
          content: form.writing.content,
          description: form.writing.description,
          imageDescription: form.writing.imageDescription,
          // 分別處理不同類型的圖片
          descriptionImages: processedDescriptionImages,
          contentImages: processedContentImages,
          referenceImage: referenceImageData
        },
        lang: form.lang,
        type: form.correctionType,
        title: form.title
      };

      // console.log('Submitting data:', {
      //   hasDescriptionImages: processedDescriptionImages.length,
      //   hasContentImages: processedContentImages.length,
      //   hasReferenceImage: !!referenceImageData,
      //   contentImagesData: processedContentImages.map(img => ({
      //     hasData: !!img.data,
      //     hasOcrText: !!img.ocrText
      //   }))
      // });

      // console.log('Submitting images:', {
      //   descriptionImages: form.writing.descriptionImages,
      //   contentImages: form.writing.contentImages,
      //   referenceImage: form.writing.referenceImage
      // });

      const response = await fetch('/api/teacher/manual-correction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(submissionData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '提交失敗');
      }

      const data = await response.json();
      if (data.success) {
        setSuccess('批改提交成功');
        navigate(`/correction-result/teacher/${data.submissionId}`);
      } else {
        throw new Error(data.message || '提交失敗');
      }
    } catch (error) {
      console.error('Submit error:', error);
      setError(error instanceof Error ? error.message : '提交失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 清理函數 - 在件卸載時調用
  useEffect(() => {
    return () => {
      // 清理所有描述圖片的預覽 URL
      form.writing.descriptionImages.forEach(image => {
        if (image.preview) {
          URL.revokeObjectURL(image.preview);
        }
      });
      // 清理所有內容圖片的預覽 URL
      form.writing.contentImages.forEach(image => {
        if (image.preview) {
          URL.revokeObjectURL(image.preview);
        }
      });
      // 清理參考圖片的預覽 URL
      if (form.writing.referenceImage.preview) {
        URL.revokeObjectURL(form.writing.referenceImage.preview);
      }
      // 清理翻譯圖片的預覽 URL
      form.translations.forEach(translation => {
        translation.images.forEach(image => {
          if (image.preview) {
            URL.revokeObjectURL(image.preview);
          }
        });
      });
    };
  }, [form]);

  // 修改获取考古题数据的函数
  const fetchPastExamData = async (examType: string, year: number, round: number) => {
    try {
      setIsLoading(true);
      
      // console.log('Fetching exam data with:', {
      //   examType,
      //   year,
      //   round
      // });

      let examTypeParam = examType;
      if (examType === 'mock' && pastExamData.mockType) {
        examTypeParam = pastExamData.mockType === 'north' ? '北區學測模考' : '臺中區學測模考';
      }

      const response = await fetch(
        `/api/test/exam/${encodeURIComponent(examTypeParam)}/${year}/${round}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (!response.ok) {
        throw new Error(`API 錯誤: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Received exam data:', data);

      if (data.success) {
        // 更新表單數據
        setForm(prev => ({
          ...prev,
          writing: {
            ...prev.writing,
            description: data.writing?.description || '',
            imageDescription: data.writing?.imageDescription || '',
            content: '',
            descriptionImages: [],
            currentDescriptionImageIndex: 0,
            referenceImage: {
              file: null,
              preview: ''
            }
          }
        }));

        // 如果有翻譯題目，一併更新
        if (data.translations) {
          setForm(prev => ({
            ...prev,
            translations: data.translations.map((t: any) => ({
              questionId: t._id,
              question: t.question,
              standardAnswer: t.answer,
              studentAnswer: '',
              images: [],
              currentImageIndex: 0
            }))
          }));
        }

        setShowPastExamSelector(false);
        setSuccess('考古題導入成功');
      } else {
        throw new Error(data.message || '獲取考古題失敗');
      }
    } catch (error) {
      console.error('Error fetching past exam:', error);
      setError(error instanceof Error ? error.message : '獲取考古題失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 修改圖片上傳處理函數
  const handleImageUpload = async (file: File) => {
    const preview = URL.createObjectURL(file);
    const base64 = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString() || '';
        resolve(base64String);
      };
      reader.readAsDataURL(file);
    });

    // OCR 處理
    const ocrResponse = await fetch('/api/ocr', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ image: base64.split(',')[1] })
    });

    const ocrData = await ocrResponse.json();

    return {
      file,
      preview,
      base64,
      ocrText: ocrData.text
    };
  };

  // 修改參考圖片上傳處理函數
  const handleReferenceImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    setError(null);

    try {
      const file = e.target.files?.[0];
      if (!file) return;

      if (file.size > 5 * 1024 * 1024) {
        throw new Error('圖片大小不能超過 5MB');
      }

      if (file.type === 'image/webp') {
        throw new Error('不支援 WebP 格式圖片');
      }

      const preview = URL.createObjectURL(file);

      try {
        // 確保在發送請求前有描述文字
        if (!form.writing.description) {
          throw new Error('請先填寫題目描述再上傳圖片');
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('description', form.writing.description);
        
        const response = await fetch('/api/image2txt/process-image', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formData
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || '處理圖片失敗');
        }

        const data = await response.json();
        
        if (!data.success) {
          throw new Error(data.message || '處理圖片失敗');
        }

        const cleanedDescription = cleanImageDescription(data.imageDescription);

        // 如果之前有預覽圖片，先清理掉
        if (form.writing.referenceImage.preview) {
          URL.revokeObjectURL(form.writing.referenceImage.preview);
        }

        setForm(prev => ({
          ...prev,
          writing: {
            ...prev.writing,
            referenceImage: {
              file,
              preview
            },
            imageDescription: cleanedDescription
          }
        }));
        setSuccess('圖片上傳成功並生成描述');
      } catch (error) {
        URL.revokeObjectURL(preview);
        throw error;
      }
    } catch (error) {
      console.error('Reference image upload error:', error);
      setError(error instanceof Error ? error.message : '圖片上傳失敗');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-[calc(100vh-4rem)]">
      <form onSubmit={handleSubmit} className="flex-1 container mx-auto py-6">
        <Card>
          <CardHeader>
            <CardTitle>手動批改</CardTitle>
            <CardDescription>
              請選擇學生並輸作文內容進行批改
            </CardDescription>
          </CardHeader>

          <CardContent className="space-y-4">
            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            {success && (
              <Alert>
                <AlertDescription>{success}</AlertDescription>
              </Alert>
            )}

            <div className="space-y-4">
              {/* 學生選擇方式 */}
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Label>選擇學生來源</Label>
                  <Select
                    value={form.useCustomStudent ? "custom" : "existing"}
                    onValueChange={(value) => {
                      setForm(prev => ({
                        ...prev,
                        useCustomStudent: value === "custom",
                        studentId: '',
                        customStudent: ''
                      }));
                      setSelectedStudent(null);
                    }}
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="選擇來源" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="existing">現有學生</SelectItem>
                      <SelectItem value="custom">自訂學生</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {form.useCustomStudent ? (
                  <div>
                    <Label>自訂學生名稱 *</Label>
                    <Input
                      value={form.customStudent}
                      onChange={(e) => setForm(prev => ({ ...prev, customStudent: e.target.value }))}
                      placeholder="請輸入學生名稱"
                      required
                    />
                  </div>
                ) : (
                  <div>
                    <Label>選擇學生 *</Label>
                    <Popover open={openCombobox} onOpenChange={setOpenCombobox}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={openCombobox}
                          className="w-full justify-between"
                        >
                          {selectedStudent ? 
                            `${selectedStudent.realName}${selectedStudent.className ? ` (${selectedStudent.className})` : ''}` 
                            : "搜尋學生..."}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-full p-0">
                        <Command>
                          <CommandInput
                            placeholder="搜尋學生..."
                            value={searchValue}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSearchValue(value);
                              searchStudents(value);
                            }}
                          />
                          <CommandEmpty>找不到學生</CommandEmpty>
                          <CommandGroup>
                            {students.map((student) => (
                              <CommandItem
                                key={student._id}
                                value={student._id.toString()}
                                onSelect={() => {
                                  setSelectedStudent(student);
                                  setForm(prev => ({ 
                                    ...prev, 
                                    studentId: student._id.toString(),
                                    useCustomStudent: false 
                                  }));
                                  setOpenCombobox(false);
                                  setSearchValue('');
                                }}
                                className="cursor-pointer hover:bg-gray-100"
                              >
                                {student.realName} {student.className ? `(${student.className})` : ''}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                )}
              </div>

              {/* 標題輸入 */}
              <div>
                <Label>標題 *</Label>
                <Input
                  value={form.title}
                  onChange={(e) => setForm(prev => ({ ...prev, title: e.target.value }))}
                  placeholder="請輸入作文標題"
                  required
                />
              </div>

              {/* 語言和批改類型選擇 */}
              <div className="grid grid-cols-2 gap-4">
                {/* 語言選擇 */}
                <div>
                  <Label>語言 *</Label>
                  <Select
                    value={form.lang}
                    onValueChange={(value: LanguageType) => setForm(prev => ({ ...prev, lang: value }))}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="選擇語言" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="繁體中文">繁體中文</SelectItem>
                      <SelectItem value="简体中文">简体中文</SelectItem>
                      <SelectItem value="日本語">日本語</SelectItem>
                      <SelectItem value="한국어">한국어</SelectItem>
                      <SelectItem value="Tiếng Việt">Tiếng Việt</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {/* 批改類型選擇 */}
                <div>
                  <Label>選擇批改類型 *</Label>
                  <Select
                    value={form.correctionType}
                    onValueChange={(value: CorrectionType) => 
                      setForm(prev => ({ ...prev, correctionType: value }))
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="選擇批改類型" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="writing">作文</SelectItem>
                      <SelectItem value="translation-writing">翻譯作文</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              {/* 添加考古題導入按鈕 */}
              <div className="flex justify-end mb-4">
                <Button
                  variant="outline"
                  onClick={() => setShowPastExamSelector(prev => !prev)}
                >
                  {showPastExamSelector ? '取消導入考古題' : '從考古題導入'}
                </Button>
              </div>

              {/* 考古題選擇器 */}
              {showPastExamSelector && (
                <Card className="mb-6">
                  <CardContent className="pt-6">
                    {currentPastExamStep === 'examType' && (
                      <SelectExamType
                        onSelect={(type) => {
                          setPastExamData(prev => ({ ...prev, examType: type }));
                          if (type === 'mock') {
                            setCurrentPastExamStep('mockType');
                          } else {
                            setCurrentPastExamStep('year');
                          }
                        }}
                        onBack={() => setShowPastExamSelector(false)}
                      />
                    )}

                    {currentPastExamStep === 'mockType' && (
                      <SelectMockType
                        onSelect={(type: string) => {
                          setPastExamData(prev => ({ ...prev, mockType: type }));
                          setCurrentPastExamStep('year');
                        }}
                        onBack={() => setCurrentPastExamStep('examType')}
                      />
                    )}

                    {currentPastExamStep === 'year' && (
                      <SelectYear
                        availableYears={availableYears}
                        onSelect={(year) => {
                          setPastExamData(prev => ({ ...prev, year }));
                          setCurrentPastExamStep('round');
                        }}
                        onBack={() => setCurrentPastExamStep(pastExamData.examType === 'mock' ? 'mockType' : 'examType')}
                      />
                    )}

                    {currentPastExamStep === 'round' && (
                      <SelectRound
                        availableRounds={availableRounds}
                        onSelect={(round) => {
                          // 直接使用當前值和新的 round 值調用 fetchPastExamData
                          const examType = pastExamData.examType;
                          const year = pastExamData.year;
                          
                          // 先更新狀態
                          setPastExamData(prev => ({
                            ...prev,
                            round
                          }));

                          // 使用當前值直接調用
                          if (examType && year && round) {
                            fetchPastExamData(examType, year, round);
                          }
                        }}
                        onBack={() => setCurrentPastExamStep('year')}
                      />
                    )}
                  </CardContent>
                </Card>
              )}

              {/* Tabs 部分 */}
              <Tabs defaultValue="writing" className="w-full">
                <TabsList>
                  <TabsTrigger value="writing">作文</TabsTrigger>
                  {form.correctionType === 'translation-writing' && (
                    <TabsTrigger value="translation">翻譯</TabsTrigger>
                  )}
                </TabsList>

                {/* 作文 Tab 內容 */}
                <TabsContent value="writing">
                  <div className="space-y-4">
                    {/* 修改作文描述部分為左右對照 */}
                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <Label>作文描述</Label>
                        <div className="relative">
                          <Button
                            type="button"
                            variant="outline"
                            size="icon"
                            onClick={() => {
                              const input = document.getElementById('description-image-upload');
                              input?.click();
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <Loader2 className="h-4 w-4 animate-spin" />
                            ) : (
                              <Camera className="h-4 w-4" />
                            )}
                          </Button>
                          <input
                            id="description-image-upload"
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={(e) => handleWritingImageUpload(e, 'description')}
                          />
                        </div>
                      </div>

                      <div className={form.writing.descriptionImages.length > 0 ? "grid grid-cols-2 gap-4" : ""}>
                        <div className={form.writing.descriptionImages.length > 0 ? "" : "w-full"}>
                          <Textarea
                            value={form.writing.description}
                            onChange={(e) => setForm(prev => ({
                              ...prev,
                              writing: { ...prev.writing, description: e.target.value }
                            }))}
                            placeholder="請輸入作文描述"
                            className="min-h-[400px] w-full"
                          />
                        </div>

                        {form.writing.descriptionImages.length > 0 && (
                          <div className="relative">
                            <div className="space-y-4">
                              <div className="relative">
                                {form.writing.descriptionImages.map((image, index) => (
                                  <div
                                    key={index}
                                    className={`transition-opacity duration-300 ${
                                      index === form.writing.currentDescriptionImageIndex ? "opacity-100" : "opacity-0 hidden"
                                    }`}
                                  >
                                    <Card>
                                      <CardContent className="p-4">
                                        <img
                                          src={image.preview}
                                          alt={`上傳的圖片 ${index + 1}`}
                                          className="w-full h-auto"
                                        />
                                        <p className="text-sm text-gray-500 mt-2">
                                          第 {index + 1} 頁，共 {form.writing.descriptionImages.length} 頁
                                        </p>
                                      </CardContent>
                                    </Card>
                                  </div>
                                ))}

                                {form.writing.descriptionImages.length > 1 && (
                                  <>
                                    <Button
                                      variant="outline"
                                      size="icon"
                                      className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleImageNavigation('prev', 'writing');
                                      }}
                                      disabled={form.writing.currentDescriptionImageIndex === 0}
                                    >
                                      <ChevronLeft className="h-4 w-4" />
                                    </Button>
                                    <Button
                                      variant="outline"
                                      size="icon"
                                      className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleImageNavigation('next', 'writing');
                                      }}
                                      disabled={form.writing.currentDescriptionImageIndex === form.writing.descriptionImages.length - 1}
                                    >
                                      <ChevronRight className="h-4 w-4" />
                                    </Button>
                                  </>
                                )}
                              </div>

                              <div className="flex justify-center space-x-2">
                                {form.writing.descriptionImages.map((_, index) => (
                                  <Button
                                    key={index}
                                    variant="outline"
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setForm(prev => ({
                                        ...prev,
                                        writing: {
                                          ...prev.writing,
                                          currentDescriptionImageIndex: index
                                        }
                                      }));
                                    }}
                                    className={`w-8 h-8 ${
                                      form.writing.currentDescriptionImageIndex === index ? "bg-blue-100" : ""
                                    }`}
                                  >
                                    {index + 1}
                                  </Button>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* 參考圖片部分 */}
                    <div className="space-y-2">
                      <Label>參考圖片</Label>
                      <div className="flex items-center gap-2 mb-2">
                        <Input
                          id="reference-image-upload"
                          type="file"
                          accept="image/*"
                          onChange={handleReferenceImageUpload}
                          className="max-w-[300px]"
                        />
                      </div>

                      {form.writing.referenceImage.preview && (
                        <div className="mt-4 space-y-4">
                          <Card>
                            <CardContent className="p-4">
                              <div className="relative">
                                <img
                                  src={form.writing.referenceImage.preview}
                                  alt="參考圖片"
                                  className="max-w-2xl h-auto rounded-md"
                                />
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  className="absolute top-2 right-2"
                                  onClick={() => setForm(prev => ({
                                    ...prev,
                                    writing: {
                                      ...prev.writing,
                                      referenceImage: { file: null, preview: '' },
                                      imageDescription: ''
                                    }
                                  }))}
                                >
                                  <X className="h-4 w-4" />
                                </Button>
                              </div>
                              <div className="space-y-2 mt-4">
                                <Label>圖片描述</Label>
                                <Textarea
                                  value={form.writing.imageDescription}
                                  onChange={(e) => setForm(prev => ({
                                    ...prev,
                                    writing: { 
                                      ...prev.writing, 
                                      imageDescription: e.target.value 
                                    }
                                  }))}
                                  placeholder="系統生成的圖片描述"
                                  className="min-h-[150px]"
                                />
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      )}
                    </div>

                    {/* 學生作文答案部分 */}
                    <div className="space-y-2">
                      <div className="flex justify-between items-center mb-2">
                        <Label>學生作文答案</Label>
                        <div className="relative">
                          <Button
                            type="button"
                            variant="outline"
                            size="icon"
                            onClick={() => {
                              const input = document.getElementById('content-image-upload');
                              input?.click();
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <Loader2 className="h-4 w-4 animate-spin" />
                            ) : (
                              <Camera className="h-4 w-4" />
                            )}
                          </Button>
                          <input
                            id="content-image-upload"
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={(e) => handleWritingImageUpload(e, 'content')}
                          />
                        </div>
                      </div>

                      <div className={form.writing.contentImages.length > 0 ? "grid grid-cols-2 gap-4" : ""}>
                        <div className={form.writing.contentImages.length > 0 ? "" : "w-full"}>
                          <Textarea
                            value={form.writing.content}
                            onChange={(e) => setForm(prev => ({
                              ...prev,
                              writing: {
                                ...prev.writing,
                                content: e.target.value
                              }
                            }))}
                            placeholder="請輸入學生作文答案"
                            className="min-h-[400px] w-full"
                            required
                          />
                        </div>

                        {form.writing.contentImages.length > 0 && (
                          <div className="relative">
                            <div className="space-y-4">
                              <div className="relative">
                                {form.writing.contentImages.map((image, index) => (
                                  <div
                                    key={index}
                                    className={`transition-opacity duration-300 ${
                                      index === form.writing.currentContentImageIndex ? "opacity-100" : "opacity-0 hidden"
                                    }`}
                                  >
                                    <Card>
                                      <CardContent className="p-4">
                                        <img
                                          src={image.preview}
                                          alt={`上傳的圖片 ${index + 1}`}
                                          className="w-full h-auto"
                                        />
                                        <p className="text-sm text-gray-500 mt-2">
                                          第 {index + 1} 頁，共 {form.writing.contentImages.length} 頁
                                        </p>
                                      </CardContent>
                                    </Card>
                                  </div>
                                ))}

                                {form.writing.contentImages.length > 1 && (
                                  <>
                                    <Button
                                      variant="outline"
                                      size="icon"
                                      className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleImageNavigation('prev', 'content');
                                      }}
                                      disabled={form.writing.currentContentImageIndex === 0}
                                    >
                                      <ChevronLeft className="h-4 w-4" />
                                    </Button>
                                    <Button
                                      variant="outline"
                                      size="icon"
                                      className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleImageNavigation('next', 'content');
                                      }}
                                      disabled={form.writing.currentContentImageIndex === form.writing.contentImages.length - 1}
                                    >
                                      <ChevronRight className="h-4 w-4" />
                                    </Button>
                                  </>
                                )}
                              </div>

                              <div className="flex justify-center space-x-2">
                                {form.writing.contentImages.map((_, index) => (
                                  <Button
                                    key={index}
                                    variant="outline"
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setForm(prev => ({
                                        ...prev,
                                        writing: {
                                          ...prev.writing,
                                          currentContentImageIndex: index
                                        }
                                      }));
                                    }}
                                    className={`w-8 h-8 ${
                                      form.writing.currentContentImageIndex === index ? "bg-blue-100" : ""
                                    }`}
                                  >
                                    {index + 1}
                                  </Button>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabsContent>

                {/* 翻譯 Tab 內容 */}
                {form.correctionType === 'translation-writing' && (
                  <TabsContent value="translation">
                    {form.translations.map((translation, index) => (
                      <Card key={translation.questionId} className="mb-4">
                        <CardHeader>
                          <CardTitle>翻譯題目 {index + 1}</CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                          {/* 題目部分 */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <Label>題目</Label>
                              <div className="relative">
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                  ) : (
                                    <Camera className="h-4 w-4" />
                                  )}
                                </Button>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleTranslationImageUpload(e, index, 'question')}
                                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                />
                              </div>
                            </div>
                            <Textarea
                              value={translation.question}
                              onChange={(e) => {
                                const newTranslations = [...form.translations];
                                newTranslations[index] = {
                                  ...newTranslations[index],
                                  question: e.target.value
                                };
                                setForm(prev => ({ ...prev, translations: newTranslations }));
                              }}
                              placeholder="請輸入翻譯題目"
                              className="min-h-[100px]"
                              required={form.correctionType !== 'writing'}
                            />
                          </div>
                            {/* 標準答案部分 */}
                            <div>
                            <div className="flex justify-between items-center mb-2">
                              <Label>標準答案</Label>
                              <div className="relative">
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                  ) : (
                                    <Camera className="h-4 w-4" />
                                  )}
                                </Button>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleTranslationImageUpload(e, index, 'standardAnswer')}
                                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                />
                              </div>
                            </div>
                            <Textarea
                              value={translation.standardAnswer}
                              onChange={(e) => {
                                const newTranslations = [...form.translations];
                                newTranslations[index] = {
                                  ...newTranslations[index],
                                  standardAnswer: e.target.value
                                };
                                setForm(prev => ({ ...prev, translations: newTranslations }));
                              }}
                              placeholder="請輸入標準答案"
                              className="min-h-[100px]"
                              required={form.correctionType !== 'writing'}
                            />
                          </div>

                          {/* 學生答案部分 */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <Label>學生答案</Label>
                              <div className="relative">
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                  ) : (
                                    <Camera className="h-4 w-4" />
                                  )}
                                </Button>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleTranslationImageUpload(e, index, 'studentAnswer')}
                                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                />
                              </div>
                            </div>
                            <Textarea
                              value={translation.studentAnswer}
                              onChange={(e) => {
                                const newTranslations = [...form.translations];
                                newTranslations[index] = {
                                  ...newTranslations[index],
                                  studentAnswer: e.target.value
                                };
                                setForm(prev => ({ ...prev, translations: newTranslations }));
                              }}
                              placeholder="請輸入學生答案"
                              className="min-h-[100px]"
                              required={form.correctionType !== 'writing'}
                            />
                          </div>

                          {/* 翻譯圖片預覽 */}
                          {translation.images.length > 0 && (
                            <div className="relative min-h-[300px]">
                              {translation.images.map((image, imageIndex) => (
                                <div
                                  key={imageIndex}
                                  className={`absolute w-full transition-opacity duration-300 ${
                                    imageIndex === translation.currentImageIndex ? 'opacity-100' : 'opacity-0 pointer-events-none'
                                  }`}
                                >
                                  <Card>
                                    <CardContent className="p-4">
                                      <div className="relative">
                                        <img
                                          src={image.preview}
                                          alt={`翻譯圖片 ${imageIndex + 1}`}
                                          className="w-full h-auto rounded-lg"
                                        />
                                        <Button
                                          type="button"
                                          variant="outline"
                                          size="icon"
                                          className="absolute top-2 right-2"
                                          onClick={() => handleClearImage('translation', index, imageIndex)}
                                        >
                                          <X className="h-4 w-4" />
                                        </Button>
                                      </div>
                                    </CardContent>
                                  </Card>
                                </div>
                              ))}

                              {/* 翻譯圖片導航按鈕 */}
                              {translation.images.length > 1 && (
                                <>
                                  <Button
                                    type="button"
                                    variant="outline"
                                    size="icon"
                                    className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                    onClick={() => handleImageNavigation('prev', 'translation', index)}
                                    disabled={translation.currentImageIndex === 0}
                                  >
                                    <ChevronLeft className="h-4 w-4" />
                                  </Button>
                                  <Button
                                    type="button"
                                    variant="outline"
                                    size="icon"
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                    onClick={() => handleImageNavigation('next', 'translation', index)}
                                    disabled={translation.currentImageIndex === translation.images.length - 1}
                                  >
                                    <ChevronRight className="h-4 w-4" />
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    ))}
                  </TabsContent>
                )}
              </Tabs>

              {/* 提交按鈕 */}
              <Button type="submit" disabled={isLoading} className="w-full">
                {isLoading ? (
                  <div className="flex items-center">
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    處理中...
                  </div>
                ) : '提交批改'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

export default ManualCorrection;