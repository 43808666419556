import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { User, Settings, LogOut } from 'lucide-react';

interface HeaderProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

const Header: React.FC<HeaderProps> = ({ setIsAuthenticated, username }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userRole, setUserRole] = useState<string>('');
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [schoolLogo, setSchoolLogo] = useState<string | null>(null);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    if (role) {
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    const fetchSchoolLogo = async () => {
      const userSchool = localStorage.getItem('userSchool');
      if (!userSchool) return;

      try {
        const response = await fetch(`/api/schools/logo/${encodeURIComponent(userSchool)}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setSchoolLogo(imageUrl);
        } else {
          console.error('Failed to fetch school logo:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching school logo:', error);
      }
    };

    fetchSchoolLogo();

    return () => {
      if (schoolLogo) {
        URL.revokeObjectURL(schoolLogo);
      }
    };
  }, []);

  const noHeaderPaths = ['/', '/login', '/register'];
  if (noHeaderPaths.includes(location.pathname)) {
    return null;
  }

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 300);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userSchool');
    setIsAuthenticated(false);
    navigate('/');
  };

  const getHomeRoute = () => {
    switch (userRole) {
      case 'admin':
        return '/admin-dashboard';
      case 'teacher':
        return '/teacher-dashboard';
      case 'sales':
        return '/sales-dashboard';
      case 'student_b':
        return '/student-dashboard';
      case 'student_c':
        return '/essay-submission';
      default:
        return '/';
    }
  };

  const handleLogoClick = () => {
    const userRole = localStorage.getItem('userRole');
    if (userRole === 'student_c') {
      navigate('/student-interface');
    } else if (userRole === 'student_b') {
      navigate('/student-dashboard');
    } else if (userRole === 'teacher') {
      navigate('/teacher-dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <header className="sticky top-0 z-50 w-full bg-white border-b shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <div 
            className="flex items-center space-x-3 cursor-pointer" 
            onClick={handleLogoClick}
          >
            <img
              src="/logo112.svg"
              alt="KC Online Logo"
              className="h-7 w-auto"
            />
            {schoolLogo && (
              <img
                src={schoolLogo}
                alt="School Logo"
                className="h-7 w-auto ml-2"
                onError={(e) => {
                  console.error('School logo failed to load');
                  const target = e.target as HTMLImageElement;
                  target.style.display = 'none';
                  URL.revokeObjectURL(schoolLogo);
                }}
              />
            )}
          </div>

          <div className="flex items-center space-x-6">
            {/* Navigation Links */}
            <nav className="flex items-center space-x-6">
              {(userRole === 'student_b' || userRole === 'student_c') && (
                <button
                  onClick={() => navigate('/correction-history')}
                  className="text-gray-600 hover:text-gray-900"
                >
                  批改紀錄
                </button>
              )}
              {userRole === 'student_c' && (
                <button
                  onClick={() => navigate('/subscription')}
                  className="text-gray-600 hover:text-gray-900"
                >
                  訂閱方案
                </button>
              )}
            </nav>
            
            {/* User Menu */}
            <div 
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="flex items-center gap-2 px-4 py-2 rounded-md hover:bg-gray-50 select-none cursor-pointer">
                    <User className="h-4 w-4" />
                    <span className="whitespace-nowrap">{username}</span>
                  </div>
                </DropdownMenuTrigger>
                
                <DropdownMenuContent 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <DropdownMenuItem
                    onClick={() => navigate('/profile-settings')}
                    className="flex items-center gap-2 px-4 py-3"
                  >
                    <Settings className="h-4 w-4" />
                    <span>個人資料設定</span>
                  </DropdownMenuItem>
                  
                  <DropdownMenuItem
                    onClick={handleLogout}
                    className="flex items-center gap-2 px-4 py-3 text-red-600 hover:bg-red-50"
                  >
                    <LogOut className="h-4 w-4" />
                    <span>登出</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;